import {Injectable} from '@angular/core';
import {ActionType} from '../../gw-pipe-lib/enum/action-type.enum';
import {Subject} from 'rxjs';
import {GwEvent} from '../interface/gw-event';

@Injectable({
    providedIn: 'root'
})
export class EventBusService {

    protected readonly listeners: { [name: string]: Array<(event: GwEvent<any>) => void> } = {};
    protected readonly eventsSubject = new Subject<GwEvent<any>>();
    protected readonly lastEvents = new Map<string, GwEvent<any>>();

    /**
     * ctor
     */
    public constructor() {
        this.eventsSubject.subscribe((gwEvent: GwEvent<any>) => {
            this.lastEvents.set(gwEvent.name, gwEvent);
            if (this.listeners[gwEvent.name]) {
                const listeners = this.listeners[gwEvent.name];
                for (const listener of listeners) {
                    listener(gwEvent);
                }
            }
        });
    }

    /**
     * adds listener to event by event name
     *
     * @param name            event name
     * @param listener        listener function
     * @param forceOverwrite  should overwrite if exists on already
     * @param stateless
     */
    public on<T>(name: ActionType, listener: (event: GwEvent<T>) => void, forceOverwrite = false, stateless: boolean = false): void {
        if (!this.listeners[name]) {
            this.listeners[name] = [];
        }

        if (forceOverwrite) {
            this.listeners[name] = [listener];
        } else {
            this.listeners[name].push(listener);
        }

        if (stateless === false && this.lastEvents.has(name)) {
            const lastEvent = this.lastEvents.get(name);
            listener(lastEvent);
        }
    }

    /**
     * sends event to listeners
     *
     * @param sender   broadcasting object
     * @param name     event name
     * @param payload  event payload
     */
    public broadcast<T>(sender: any, name: ActionType, payload: T = null): boolean {
        const event = <GwEvent<T>>{name, sender, payload};
        this.eventsSubject.next(event);
        return true;
    }
}
