import {Injectable} from '@angular/core';
import {AppConfigService} from 'projects/gw-web-components/src/app/gw-configuration-lib/service/app-config.service';
import {ComparatorType} from '../enum/comparator-type.enum';
import {SortingState} from '../type/sorting-state.type';
import {ActionType} from '../../gw-pipe-lib/enum/action-type.enum';
import {ViewType} from '../../gw-pipe-lib/enum/view-type.enum';
import {SortingType} from '../enum/sorting-type.enum';
import {FilterOptionInterface} from '../interface/filter-option-interface';
import {ResultState} from '../interface/result-state';
import {SerializedResultState} from '../interface/serialized-result-state';
import {FilterFieldConfiguration} from '../type/filter-field-configuration';
import {EventBusService} from './event-bus.service';
import {FilterService} from './filter.service';
import {HashService} from './hash.service';
import {LocalStorageService} from './local-storage.service';
import {LocationHashService} from './location-hash.service';
import {ChangeEventContext} from "../../gw-pipe-lib/types";

@Injectable({
    providedIn: 'root'
})
export class ResultStateService {

    static readonly GLOBAL_RESULT_STATE = 'global_result_state';

    private initialized = false;

    private _serializedResultStateByLocalStorage: { [key: string]: any } = {
        filterOptions: [],
        sorting: {
            fieldName: '',
            sortingType: SortingType.ASC
        }
    };

    private _serializedResultStateByLocationHash: { [key: string]: any } = {
        filterOptions: [],
        sorting: {
            fieldName: '',
            sortingType: SortingType.ASC
        }
    };

    constructor(
        private eventBus: EventBusService,
        private filterService: FilterService,
        private appConfigService: AppConfigService,
        private locationHashService: LocationHashService,
        private localStorageService: LocalStorageService
    ) {
        this.initialize({});
    }

    private _globalResultState: ResultState;

    /**
     * getter for global result state
     */
    private get globalResultState(): ResultState {
        return this._globalResultState;
    }

    /**
     * setter for global result state
     * @param newGlobalResultState
     */
    private set globalResultState(newGlobalResultState: ResultState) {
        if (!newGlobalResultState) {
            return;
        }

        const actionType = !this._globalResultState ? ActionType.RESULT_STATE_READY : ActionType.RESULT_STATE_CHANGED;
        this._globalResultState = newGlobalResultState;
        const serializedResultState = ResultStateService.serializeResultState(newGlobalResultState);
        this.localStorageService.set(ResultStateService.GLOBAL_RESULT_STATE, serializedResultState);
        this.locationHashService.setSerializedResultState(serializedResultState);

        this.eventBus.broadcast(this, actionType, newGlobalResultState);
    }

    /**
     * called by first initialized global ResultComponent
     * @param initialResultState
     */
    public initialize(initialResultState: SerializedResultState): void {
        if (this.initialized) {
            // Execute only first time
            return;
        }
        this.initialized = true;

        this.eventBus.on<FilterFieldConfiguration>(ActionType.FILTER_CONFIGURATION_READY, event => {
            const mergedSerializedResultState = this.buildMergedSerializedResultState(initialResultState);
            this.globalResultState = this.buildGlobalResultState(mergedSerializedResultState, event.payload);
        });

        this.eventBus.on<ChangeEventContext>(
            ActionType.FILTERS_CHANGE,
            event => this.globalResultState = this._onFilterOptionsChanged(event.payload.remove, event.payload.add)
        );
        this.eventBus.on<SortingState>(ActionType.RESULT_SORT, event => this.globalResultState = this.onSortingChanged(event.payload));
        this.eventBus.on<number>(ActionType.RESULT_LIMIT, event => this.globalResultState = this.onLimitChanged(event.payload));
        this.eventBus.on<number>(ActionType.RESULT_OFFSET, event => this.globalResultState = this.onOffsetChanged(event.payload));
        this.eventBus.on<ViewType>(ActionType.VIEWTYPE_LIST, () => this.globalResultState = this.onViewTypeSwitched(ViewType.LIST));
        this.eventBus.on<ViewType>(ActionType.VIEWTYPE_BOX, () => this.globalResultState = this.onViewTypeSwitched(ViewType.BOX));
        this.eventBus.on<Map<string, string>>(ActionType.HASH_CHANGED, event => this.globalResultState = this.onLocationHashChanged(event.payload));
    }

    private onLocationHashChanged(hashArguments: Map<string, string>): ResultState {
        const mergedSerializedResultState = this.buildMergedSerializedResultState({});
        return this.buildGlobalResultState(mergedSerializedResultState, this.filterService.filterFields);
    }

    /**
     *
     * @param initialResultState
     */
    private buildMergedSerializedResultState(initialResultState: SerializedResultState): SerializedResultState {
        if (this.locationHashService.hasSerializedResultState()) {
            return Object.assign(
                this.locationHashService.getDeserializedResultState(),
                initialResultState,
                {
                    createdAt: Date.now()
                }
            );
        }

        return Object.assign({
                filterOptions: this.appConfigService.get<{
                    fieldName: string,
                    comparatorType: ComparatorType,
                    value: any
                }[]>('predefinedFilters', []),
                sorting: this.appConfigService.get<{
                    fieldName: string,
                    sortingType: SortingType
                }>('predefinedSorting', {
                    fieldName: 'price_gross', // @todo replace with sorting fieldName value from appConfig
                    sortingType: SortingType.ASC
                }),
                limit: this.appConfigService.get<number>('perPage', 10), // @todo replace with limit value from appConfig
                offset: 0,
                viewType: ViewType.BOX // @todo replace with viewType value from appConfig
            },
            initialResultState, {
                createdAt: Date.now()
            });
    }

    /**
     *
     * @param mergedSerializedResultState
     * @param filterFieldConfiguration
     */
    private buildGlobalResultState(mergedSerializedResultState: SerializedResultState, filterFieldConfiguration: FilterFieldConfiguration): ResultState {
        if (mergedSerializedResultState.hasOwnProperty('filterOptions') === false) {
            mergedSerializedResultState.filterOptions = [];
        }

        const filterOptions = mergedSerializedResultState.filterOptions
            .filter(serializedFilterOption => serializedFilterOption.value !== 'null')
            .map<[string, FilterOptionInterface]>(serializedFilterOption => {
                if (filterFieldConfiguration.has(serializedFilterOption.fieldName) === false) {
                    return [null, null];
                }
                const filterField = filterFieldConfiguration.get(serializedFilterOption.fieldName),
                    filterOption = filterField.findOption(serializedFilterOption.comparatorType, serializedFilterOption.value);

                return (!!filterOption) ? [HashService.md5ForFilterOption(filterOption), filterOption] : [null, null];
            })
            .filter(([key, filterOption]: [string | null, FilterOptionInterface | null]) => (key !== null && filterOption !== null));

        let newGlobalResultState: ResultState;

        newGlobalResultState = {
            filterOptions: new Map<string, FilterOptionInterface>(filterOptions),
            sorting: {
                field: filterFieldConfiguration.has(mergedSerializedResultState.sorting.fieldName) ? filterFieldConfiguration.get(mergedSerializedResultState.sorting.fieldName) : null,
                order: mergedSerializedResultState.sorting.sortingType.toUpperCase() as SortingType
            },
            limit: mergedSerializedResultState.limit,
            offset: mergedSerializedResultState.offset,
            viewType: mergedSerializedResultState.viewType,
            createdAt: Date.now()
        };

        return newGlobalResultState;
    }

    private _onFilterOptionsChanged(remove: FilterOptionInterface[], add: FilterOptionInterface[]): ResultState {
        const removingFilterOptions = remove.filter(filterOption => this.globalResultState.filterOptions.has(filterOption.hash)),
            newFilterOptions = add.filter(filterOption => this.globalResultState.filterOptions.has(filterOption.hash) === false);

        if (removingFilterOptions.length < 1 && newFilterOptions.length < 1) {
            // no changes after filtering existing
            return;
        }

        const oldGlobalResultState = this.globalResultState,
            newGlobalResultState = ResultStateService.cloneResultState(oldGlobalResultState);

        newFilterOptions.forEach(filterOption => {
            newGlobalResultState.filterOptions.set(filterOption.hash, filterOption);
        });

        removingFilterOptions.forEach(filterOption => {
            newGlobalResultState.filterOptions.delete(filterOption.hash);
        });

        newGlobalResultState.offset = 0;

        return newGlobalResultState;
    }

    /**
     *
     * @param oldGlobalResultState
     */
    private static cloneResultState(oldGlobalResultState: ResultState): ResultState {
        return {
            filterOptions: new Map<string, FilterOptionInterface>(Array.from(oldGlobalResultState.filterOptions)),
            sorting: {
                field: oldGlobalResultState.sorting.field,
                order: oldGlobalResultState.sorting.order
            },
            limit: oldGlobalResultState.limit,
            offset: oldGlobalResultState.offset,
            viewType: oldGlobalResultState.viewType,
            createdAt: Date.now()
        };
    }

    /**
     *
     * @param viewType
     */
    private onViewTypeSwitched(viewType: ViewType): ResultState {
        if (this.globalResultState.viewType !== viewType) {

            const oldGlobalResultState = this.globalResultState,
                newGlobalResultState = ResultStateService.cloneResultState(oldGlobalResultState);

            newGlobalResultState.viewType = viewType;

            return newGlobalResultState;
        }
    }

    /**
     *
     * @param sorting
     */
    private onSortingChanged(sorting: SortingState): ResultState {
        const oldSorting = this.globalResultState.sorting;
        if (
            oldSorting.field !== sorting.field ||
            oldSorting.order !== sorting.order
        ) {
            const oldGlobalResultState = this.globalResultState,
                newGlobalResultState = ResultStateService.cloneResultState(oldGlobalResultState);

            newGlobalResultState.sorting = {
                field: sorting.field,
                order: sorting.order
            };

            newGlobalResultState.offset = 0;
            return newGlobalResultState;
        }
    }

    /**
     *
     * @param newLimit
     */
    private onLimitChanged(newLimit: number): ResultState {
        if (this.globalResultState.limit !== newLimit) {
            const oldGlobalResultState = this.globalResultState,
                newGlobalResultState = ResultStateService.cloneResultState(oldGlobalResultState);

            newGlobalResultState.limit = newLimit;

            return newGlobalResultState;
        }
    }

    /**
     *
     * @param newOffset
     */
    private onOffsetChanged(newOffset: number): ResultState {
        if (this.globalResultState.offset !== newOffset) {
            const oldGlobalResultState = this.globalResultState,
                newGlobalResultState = ResultStateService.cloneResultState(oldGlobalResultState);

            newGlobalResultState.offset = newOffset;

            return newGlobalResultState;
        }
    }

    /**
     *
     * @param newGlobalResultState
     */
    private static serializeResultState(newGlobalResultState: ResultState): SerializedResultState {
        return {
            filterOptions: Array.from(newGlobalResultState.filterOptions).map<{
                fieldName: string,
                comparatorType: ComparatorType,
                value: any
            }>(([key, filterOption]) => {
                return {
                    fieldName: filterOption.fieldName,
                    comparatorType: filterOption.type,
                    value: filterOption.value
                };
            }),
            sorting: {
                fieldName: newGlobalResultState.sorting.field.key,
                sortingType: newGlobalResultState.sorting.order
            },
            limit: newGlobalResultState.limit,
            offset: newGlobalResultState.offset,
            viewType: newGlobalResultState.viewType
        };
    }
}
