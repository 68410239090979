import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ActionType} from 'projects/gw-web-components/src/app/gw-pipe-lib/enum/action-type.enum';
import {ResultState} from 'projects/gw-web-components/src/app/gw-search-lib/interface/result-state';
import {EventBusService} from 'projects/gw-web-components/src/app/gw-search-lib/service/event-bus.service';
import {AppConfigService} from '../../gw-configuration-lib/service/app-config.service';
import {LocalStorageService} from '../../gw-search-lib/service/local-storage.service';
import {TranslationService} from '../../gw-translation-lib/service/translation.service';

@Component({
    selector: 'gw-per-page-selector',
    templateUrl: './per-page-selector.component.html',
    styleUrls: ['./per-page-selector.component.scss']
})
export class PerPageSelectorComponent implements OnInit {

    static readonly PER_PAGE = 'per_page';

    public options: number[];

    public selectedOption: FormControl = new FormControl();

    public placeholder = this.translationService.translate('result.per-page-selector.placeholder');

    @Input('gw-class')
    public cssClasses: string;

    constructor(
        protected appConfigService: AppConfigService,
        protected storageService: LocalStorageService,
        protected eventBus: EventBusService,
        protected translationService: TranslationService
    ) {
    }

    /**
     *
     * @param newLimit
     */
    private onLimitChanged(newLimit: number) {
        if (this.selectedOption.value !== newLimit) {
            this.selectedOption.patchValue(newLimit);
        }
    }

    /**
     *
     * @param resultState
     */
    private onResultStateReady(resultState: ResultState) {
        const selectedOption = this.options.reduce((prev, option) => {
            return (option <= resultState.limit) ? option : prev;
        });


        this.selectedOption.valueChanges.subscribe((value: number) => {
            if (resultState.limit !== value) {
                this.eventBus.broadcast(this, ActionType.RESULT_LIMIT, value);
            }
        });

        this.onLimitChanged(selectedOption);
    }

    ngOnInit() {
        this.options = this.appConfigService.get('result.perPageSelector.availableQuantities', [3, 6, 9, 12, 15, 750]);

        this.eventBus.on<ResultState>(ActionType.RESULT_STATE_READY, event => this.onResultStateReady(event.payload));
        this.eventBus.on<number>(ActionType.RESULT_LIMIT, event => (event.sender !== this) ? this.onLimitChanged(event.payload) : null);

        //this.storageService.addListener(PerPageSelectorComponent.PER_PAGE).subscribe(newValue => {
        //  if(this.selectedOption.value !== newValue) {
        //    this.selectedOption.patchValue(newValue);
        //    this.searchService.size = newValue;
        //  }
        //});
        //
        //this.selectedOption.valueChanges.subscribe((value: number) => {
        //  if(typeof value === 'number')) {
        //    if (this.isValueDifferentAsInStore(value)) {
        //      this.storageService.set(PerPageSelectorComponent.PER_PAGE, parseInt('' + value));
        //    }
        //    if (this.searchService.size !== value) {
        //      this.searchService.size = value;
        //    }
        //  }
        //});
        //
        //this.options = this.appConfigService.get('result.perPageSelector.availableQuantities', [3,6,9,12,15,750]);
        //let perPageValue = first(this.options);
        //
        //if(this.storageService.has(PerPageSelectorComponent.PER_PAGE)) {
        //  perPageValue = this.storageService.get(PerPageSelectorComponent.PER_PAGE);
        //} else {
        //  let index = this.appConfigService.get('result.perPageSelector.defaultValueIndex', 0);
        //  if(index >= this.options.length) {
        //    index = 0;
        //  }
        //  perPageValue = this.options[index];
        //}
        //if(perPageValue === 0) {
        //  perPageValue = this.options[1];
        //}
        //
        //this.selectedOption.patchValue(perPageValue);
    }

    protected isValueDifferentAsInStore(value: any): boolean {
        return (
            this.storageService.has(PerPageSelectorComponent.PER_PAGE) === false ||
            this.storageService.get(PerPageSelectorComponent.PER_PAGE) !== value
        );
    }
}
