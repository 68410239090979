import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ComparatorType} from '../../gw-search-lib/enum/comparator-type.enum';
import {StoredResultSettings} from '../../gw-search-lib/type/stored-result-settings';
import {SortingType} from '../../gw-search-lib/enum/sorting-type.enum';
import {FilterFieldInterface} from '../../gw-search-lib/interface/filter-field-interface';
import {FilterOptionInterface} from '../../gw-search-lib/interface/filter-option-interface';
import {FilterOptionModel} from '../../gw-search-lib/model/filter-option.model';
import {FilterService} from '../../gw-search-lib/service/filter.service';
import {IResultFilterSetting} from "../../gw-pipe-lib/component/result-filter-setting/result-filter-setting-interface";

@Component({
  //selector: 'gw-last-seen-settings',
  templateUrl: './last-seen-settings.component.html',
  styleUrls: ['./last-seen-settings.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class LastSeenSettingsComponent implements OnInit {

  @Input('gw-name')
  name: string;

  @Input('gw-id')
  id: string;

  protected _settings: StoredResultSettings;

  @Input('gw-settings')
  set settings(settings: string) {
    this._settings = JSON.parse(settings);
  };

  protected _filterSettings: IResultFilterSetting[] = [];

  public set filterSettings(filterSettings: IResultFilterSetting[]) {
    this._filterSettings = filterSettings;
    this._settings.filters = filterSettings
      .filter(filterSetting => {
        return !!filterSetting.filterValue && !!filterSetting.filterField;
      })
      .map(filterSetting => {
        return {
          field: filterSetting.filterValue.fieldName,
          comparator: filterSetting.filterValue.type.toUpperCase() as ComparatorType,
          value: filterSetting.filterValue.value
        };
      });

    this.settingsAsJson = JSON.stringify(this._settings);
  }

  public get filterSettings(): IResultFilterSetting[] {
    return this._filterSettings;
  }

  public set sortingSetting(sortingSetting: { field: string, order: SortingType }) {
    this._settings.sorting = sortingSetting;
    this.settingsAsJson = JSON.stringify(this._settings);
  };

  public get sortingSetting(): { field: string, order: SortingType } {
    return this._settings.sorting || { field: 'price_gross', order: SortingType.ASC };
  };

  public set scrollTypeSetting(scrollTypeSetting: 'perpage' | 'infinite') {
    this._settings.scrollType = scrollTypeSetting;
    this.settingsAsJson = JSON.stringify(this._settings);
  }

  public get scrollTypeSetting(): 'perpage' | 'infinite' {
    return this._settings.scrollType || 'perpage';
  }

  public set perPageSetting(perPage: number) {
    this._settings.perPage = perPage;
    this.settingsAsJson = JSON.stringify(this._settings);
  }

  public get perPageSetting(): number {
    return this._settings.perPage || 4;
  }

  public settingsAsJson: string = '';

  public availableFilterFields: FilterFieldInterface[] = [];

  public areForAllFilterSettingsDefined: boolean = true;

  constructor(
    protected filterService: FilterService
  ) { }

  ngOnInit() {
    this.filterService.filterConfiguration$.subscribe(filterFields => {
      this.availableFilterFields = Array.from(filterFields.values());
      if(this._settings) {
        // set filters
        this._settings.filters = this._settings.filters || [];
        const filterOptions = this._settings
            .filters
            .map(filter => this.filterService.getOptionByFieldName(filter.field, filter.value, filter.comparator));

        this.onFilterConfigurationLoaded(filterOptions);
      }
    });
  }

  /**
   *
   * @param removingFilterSetting
   */
  public onClickRemove(removingFilterSetting: IResultFilterSetting) {
    const index = this.filterSettings.indexOf(removingFilterSetting);
    if(index > -1) {
      this.filterSettings.splice(index, 1);
      this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
    }
  }

  /**
   *
   */
  public onClickAdd() {
    this.filterSettings = [...this.filterSettings, {filterField: null, filterValue: null, comparator: null}];
    this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
  }

  /**
   *
   * @param filterOptions
   */
  protected onFilterConfigurationLoaded(filterOptions: FilterOptionInterface[]) {
    filterOptions.forEach(filterValue => {
      filterValue.select = true;
      this.filterSettings = [
        ...this.filterSettings,
        {
          filterField: (<FilterOptionModel>filterValue).field,
          filterValue: filterValue,
          comparator: filterValue.type
        }
      ];
    });
    this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
  }

  /**
   *
   */
  protected checkForAllFilterSettingsDefined(): boolean {
    for(const filterSetting of this.filterSettings) {
      if(!filterSetting.filterField || !filterSetting.filterValue) {
        return false;
      }
    }
    this.filterSettings = [...this.filterSettings];
    return true;
  }

  public onFilterSettingChanged() {
    this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
  }
}
