import {Inject, Injectable} from '@angular/core';
import {DefaultUrlSerializer} from '@angular/router';
import trimStart from 'lodash-es/trimStart';
import {EventBusService} from 'projects/gw-web-components/src/app/gw-search-lib/service/event-bus.service';
import {ViewType} from '../../gw-pipe-lib/enum/view-type.enum';
import {ComparatorType} from '../enum/comparator-type.enum';
import {SortingType} from '../enum/sorting-type.enum';
import {SerializedResultState} from '../interface/serialized-result-state';
import {StoredGlobalFilters} from '../type/stored-global-filters';
import {BehaviorSubject} from 'rxjs';
import {APP_PREFIX} from '../../gw-configuration-lib/reference/app-prefix';
import {WINDOW} from '../../gw-configuration-lib/reference/window-ref';
import {ActionType} from '../../gw-pipe-lib/enum/action-type.enum';

@Injectable({
    providedIn: 'root'
})
export class LocationHashService {

    static readonly FORCE_REPARSE = true;

    public settingsByUri$: BehaviorSubject<Map<string, any>> = new BehaviorSubject<Map<string, any>>(null);

    private _hashArguments: Map<string, any>;
    private serializer: DefaultUrlSerializer;
    private _listenerDisabled: boolean = false;

    constructor(
        @Inject(WINDOW) protected windowRef: Window,
        @Inject(APP_PREFIX) protected appPrefix: string,
        protected eventBus: EventBusService
    ) {
        this.windowRef.addEventListener('hashchange', () => this._listenerDisabled ? null : this.onHashChanged());
        this.serializer = new DefaultUrlSerializer();
    }

    /**
     *
     */
    protected get hash(): string {
        return this.windowRef.location.hash;
    }

    /**
     *
     * @param hash
     */
    protected set hash(hash: string) {
        this.windowRef.location.hash = hash;
    }

    /**
     *
     * @param key
     * @param value
     */
    public setHash(key: string, value: string): void {
        const hashArguments = this.getHashArguments();
        const hasHashArguments = hashArguments.size > 0;

        hashArguments.set(key, value);

        const queryPos = this.windowRef.location.hash.search('\\?');
        const hashString =
            this.windowRef.location.hash.substr(0, queryPos + 1)
            + ((queryPos < 0) ? '#?' : '')
            + this.buildUriHash(this.getHashArguments());

        if (hashString !== this.windowRef.location.hash) {
            this._listenerDisabled = true;
            this.windowRef.history[hasHashArguments ? 'pushState' : 'replaceState'](
                {},
                this.windowRef.document.title,
                window.location.href.substring(0, window.location.href.length-window.location.hash.length) + hashString
            );

            this._listenerDisabled = false;
            this.settingsByUri$.next(this.getHashArguments());
        }
    }

    public getHash(): string {
        return this.hash;
    }

    /**
     * @api
     * parses hash but did not convert value type
     * @param hashString
     */
    public parseHash(hashString: string): Map<string, any> {
        const appParamPattern = new RegExp(`^${this.appPrefix}-`, 'i'),
            hashItems = trimStart(hashString.substr(hashString.search(/\?/) + 1), '#/').split(/&/g);

        return new Map<string, string>(hashItems.map<[string, string]>((hashItem: string) => {
            const [key, value] = hashItem.split('=');
            return [key, value];
        }).filter(([key, value]: [string, string]) => {
            return appParamPattern.test(key);
        }).map<[string, string]>(([key, value]: [string, string]) => {
            return [key.substr(3), decodeURIComponent(value)];
        }));
    }

    /**
     * @api
     * @param hashArguments
     */
    public buildUriHash(hashArguments: Map<string, string>): string {
        return Array.from(hashArguments).map(([key, value]) => {
            let argValue = '',
                argName = '';

            switch (key) {
                case 'search':
                    argValue = value;
                    argName = `${this.appPrefix}-search`;
                    break;
                case 'details':
                    argValue = value;
                    argName = `${this.appPrefix}-details`;
                    break;
                default:
                    argValue = value;
                    argName = key;
            }
            argValue = encodeURIComponent(argValue);
            return `${argName}=${argValue}`;
        }).join('&');
    }

    /**
     * @api
     * search for properties of interface SerializedResultState exists in location hash
     */
    public hasSerializedResultState(): boolean {
        return this.existsInUri('search');
    }

    /**
     * @api
     * build deserialized result state by hash
     */
    public getDeserializedResultState(): { [key: string]: any } {
        const hashArguments = this.getHashArguments();
        return (hashArguments.has('search')) ? this.parseSerializedResultState(hashArguments.get('search')) : null;
    }

    protected onHashChanged() {
        const hashArguments = this.getHashArguments(LocationHashService.FORCE_REPARSE);
        this.eventBus.broadcast<Map<string, string>>(this, ActionType.HASH_CHANGED, hashArguments);
    }

    protected uriEncodeStoredGlobalFilters(storedGlobalFilters: StoredGlobalFilters): string {
        let result: string[] = [];

        if (storedGlobalFilters.filters.length > 0) {
            result.push('filters:' + storedGlobalFilters
                .filters
                .map(option => `${option.field}.${option.comparator.toLowerCase()}.${option.value}`)
                .sort()
                .join(','));
        }

        result.push(
            `sorting:${storedGlobalFilters.sorting.field}.${storedGlobalFilters.sorting.order.toLowerCase()}`
        );

        // @TODO add startPos and size to StoredGlobalFilters
        //  + `;pos:${storedGlobalFilters.startPos}` + `;limit:${storedGlobalFilters.size}`

        return result.join(';');
    }

    /**
     * @deprecated
     * @param uriString
     */
    protected uriDecodeStoredGlobalFilters(uriString: string): StoredGlobalFilters {
        const regex = /(filters|sorting|pos|limit):([^;]+)/gm,
            regexIsNumber = /^[0-9]+$/,
            result: StoredGlobalFilters = {
                filters: [],
                sorting: null,
                startPos: 0,
                size: 0
            };

        let match: RegExpExecArray, key, value;

        while ((match = regex.exec(uriString)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            key = match[1];
            value = match[2];
            if (match.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            switch (key) {
                case 'filters':
                    result.filters = (<string>value).split(',').map(filter => {
                        const [field, comparator, val] = filter.split('.');
                        return {
                            field: field,
                            comparator: <ComparatorType>comparator.toUpperCase(),
                            value: regexIsNumber.test(val) ? parseInt(val) : val
                        };
                    });
                    break;
                case 'sorting':
                    const [field, order] = (<string>value).split('.');
                    result.sorting = {
                        field: field,
                        order: <SortingType>order.toUpperCase()
                    };
                    break;
                case 'pos':
                    result.startPos = parseInt(value);
                    break;
                case 'limit':
                    result.size = parseInt(value);
            }

        }
        return result;
    }

    /**
     * @param propertyName
     */
    private existsInUri(propertyName: string): boolean {
        const pattern = new RegExp(`${this.appPrefix}-${propertyName}`, 'i');
        return pattern.test(this.getHash());
    }

    /**
     * @api
     * creates map of hash arguments if not exists and returns
     * without conversation
     */
    public getHashArguments(forceReparse: boolean = false): Map<string, string> {
        if (!this._hashArguments || forceReparse) {
            const hashString = this.getHash();
            this._hashArguments = this.parseHash(hashString);
        }

        return this._hashArguments;
    }

    private parseSerializedResultState(search: string): { [key: string]: any } {
        const serializedResultState: { [key: string]: any } = {};

        search
            .split(';')
            .forEach((resultStateKeyValuePairs: string) => {
                const [resultStateKey, resultStateValues] = resultStateKeyValuePairs.split(':');

                switch (resultStateKey.toLowerCase()) {
                    case 'filters':
                        serializedResultState.filterOptions = this.parseSerializedResultStateFilters(resultStateValues);
                        break;
                    case 'sorting':
                        serializedResultState.sorting = this.parseSerializedResultStateSorting(resultStateValues);
                        break;
                    case 'offset':
                        serializedResultState.offset = parseInt(resultStateValues) || 0;
                        break;
                    case 'limit':
                        serializedResultState.limit = parseInt(resultStateValues) || 10;
                        break;
                    case 'viewtype':
                        serializedResultState.viewType = (resultStateValues.toLowerCase() || ViewType.BOX) as ViewType;
                        break;
                }
            });
        return serializedResultState;
    }

    /**
     *
     * @param resultStateValues
     */
    private parseSerializedResultStateFilters(resultStateValues: string): { fieldName: string, comparatorType: ComparatorType, value: string | number }[] {
        return resultStateValues.split(',').map((resultStateValue: string) => {
            const [fieldName, comparatorType, value] = resultStateValue.split('.');
            return {
                fieldName: fieldName,
                comparatorType: comparatorType.toUpperCase() as ComparatorType,
                value: /^[0-9]+$/.test(value) ? parseInt(value) : decodeURIComponent(value)
            };
        });
    }

    /**
     *
     * @param resultStateValues
     */
    private parseSerializedResultStateSorting(resultStateValues: string): { fieldName: string, sortingType: SortingType } {
        const [fieldName, sortingType] = resultStateValues.split('.');
        return {
            fieldName: fieldName,
            sortingType: sortingType.toUpperCase() as SortingType
        };
    }

    /**
     *
     * @param serializedResultState
     */
    public setSerializedResultState(serializedResultState: SerializedResultState): void {
        let hashString: string[] = [];
        if (serializedResultState.filterOptions.length > 0) {
            hashString.push('filters:' + serializedResultState.filterOptions.map<string>(filterOption => {
                return `${filterOption.fieldName}.${filterOption.comparatorType.toLowerCase()}.${filterOption.value}`;
            }).join(','));
        }
        if (
            typeof serializedResultState.sorting.fieldName === 'string' &&
            serializedResultState.sorting.fieldName.length > 0
        ) {
            hashString.push(`sorting:${serializedResultState.sorting.fieldName}.${serializedResultState.sorting.sortingType.toLowerCase()}`);
        }
        if (typeof serializedResultState.limit === 'number' && serializedResultState.limit > 0) {
            hashString.push(`limit:${serializedResultState.limit}`);
        }
        if (typeof serializedResultState.offset === 'number' && serializedResultState.offset > -1) {
            hashString.push(`offset:${serializedResultState.offset}`);
        }
        if (typeof serializedResultState.viewType === 'string' && [ViewType.BOX, ViewType.LIST].includes(serializedResultState.viewType)) {
            hashString.push(`viewtype:${serializedResultState.viewType}`);
        }
        this.setHash('search', hashString.join(';'));
    }
}
