import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ResultStateService } from './service/result-state.service';
import { ViewTypeService } from './service/view-type.service';
import { CachingInterceptor } from './interceptor/http-caching.interceptor';
import { LocationHashService } from './service/location-hash.service';
import { LastSeenService } from './service/last-seen.service';
import { GwConfigurationLibModule } from '../gw-configuration-lib/gw-configuration-lib.module';
import { FilterService } from './service/filter.service';
import { HashService } from './service/hash.service';
import { AppConfigService } from '../gw-configuration-lib/service/app-config.service';
import { EventBusService } from './service/event-bus.service';
import { LocationStrategy, HashLocationStrategy, Location } from '@angular/common';
import { HttpCache } from './cache/http.cache';

@NgModule({
  imports: [
    HttpClientModule,
    RouterModule,
    GwConfigurationLibModule
  ],
  providers: [
    AppConfigService,
    EventBusService,
    FilterService,
    HashService,
    LastSeenService,
    LocationHashService,
    ViewTypeService,
    ResultStateService,
    Location,
    HttpCache,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class GwSearchLibModule {
}
