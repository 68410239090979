import { Inject, Injectable } from '@angular/core';
import { APP_PREFIX } from '../reference/app-prefix';
import { WINDOW, WindowRef } from '../reference/window-ref';
import get from 'lodash-es/get';
import has from 'lodash-es/has';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor(
    @Inject(APP_PREFIX) protected readonly appPrefix,
    @Inject(WINDOW) protected windowRef: WindowRef
  ) {
  }

  /**
   * returns value of app config path
   *
   * @param path         path to value in app config
   * @param defaultValue value returned, if path is undefined
   */
  public get<T>(path: string, defaultValue: T|null): T {
    const absolutePath = `${this.appPrefix}.${path}`;
    return (has(this.windowRef, absolutePath)) ? get(this.windowRef, absolutePath) : defaultValue;
  }

}
