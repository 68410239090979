import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatCheckbox} from '@angular/material/checkbox';
import {ComparatorType} from '../../gw-search-lib/enum/comparator-type.enum';
import {FilterFieldInterface} from '../../gw-search-lib/interface/filter-field-interface';
import {FilterOptionInterface} from '../../gw-search-lib/interface/filter-option-interface';
import {FilterOptionModel} from '../../gw-search-lib/model/filter-option.model';
import {FilterService} from '../../gw-search-lib/service/filter.service';
import {IResultFilterSetting} from "../../gw-pipe-lib/component/result-filter-setting/result-filter-setting-interface";
import {StoredRandomSettings} from "../../gw-search-lib/type/stored-random-settings";

@Component({
    //selector: 'gw-random-settings',
    templateUrl: './random-settings.component.html',
    styleUrls: ['./random-settings.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class RandomSettingsComponent implements OnInit {

    @Input('gw-name')
    public name: string;

    @Input('gw-id')
    public id: string;

    private _settings: StoredRandomSettings = { filters: [] };

    @Input('gw-settings') set settings(settings: string) {
        this._settings = Object.assign(this._settings, JSON.parse(settings));
    };

    private _filterSettings: IResultFilterSetting[] = [];

    public set filterSettings(filterSettings: IResultFilterSetting[]) {

        this._filterSettings = filterSettings;
        this._settings.filters = filterSettings
            .filter(filterSetting => !!filterSetting.filterValue && !!filterSetting.filterField && !!filterSetting.comparator)
            .map(filterSetting => {
                return {
                    field: filterSetting.filterValue.fieldName,
                    comparator: filterSetting.filterValue.type.toUpperCase() as ComparatorType,
                    value: filterSetting.filterValue.value
                }
            });

        this.settingsAsJson = JSON.stringify(this._settings);
    }

    public get filterSettings(): IResultFilterSetting[] {
        return this._filterSettings;
    }

    public settingsAsJson: string = '';

    public availableFilterFields: FilterFieldInterface[] = [];

    public areForAllFilterSettingsDefined: boolean = true;

    @ViewChild('observeGlobalFiltersElement') public observeGlobalFiltersElement: MatCheckbox;
    public observeGlobalFiltersCtl: FormControl = new FormControl(false);

    constructor(
        private filterService: FilterService
    ) {
    }

    ngOnInit() {
        this.filterService.filterConfiguration$.subscribe(filterFields => {
            this.availableFilterFields = Array.from(filterFields.values());

            if (this._settings) {
                // set filters
                this._settings.filters = this._settings.filters || [];

                const filterValues = this._settings.filters.map(filter => this.filterService.getOptionByFieldName(filter.field, filter.value, filter.comparator));
                this.onFilterConfigurationLoaded(filterValues);
            }
        });
    }

    /**
     *
     * @param removingFilterSetting
     */
    public onClickRemove(removingFilterSetting: IResultFilterSetting) {
        const index = this.filterSettings.indexOf(removingFilterSetting);

        if (index > -1) {
            this.filterSettings.splice(index, 1);
            this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
        }
    }

    /**
     *
     */
    public onClickAdd() {
        this.filterSettings = [...this.filterSettings, {filterField: null, filterValue: null, comparator: ComparatorType.EQ}];
        this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
    }

    /**
     *
     * @param filterValues
     */
    private onFilterConfigurationLoaded(filterValues: FilterOptionInterface[]) {
        filterValues.forEach(filterValue => {
            filterValue.select = true;
            this.filterSettings = [...this.filterSettings, {
                filterField: (<FilterOptionModel>filterValue).field,
                filterValue: filterValue,
                comparator: (<FilterOptionModel>filterValue).type
            }];
        });
        this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
    }

    /**
     *
     */
    private checkForAllFilterSettingsDefined(): boolean {
        for (const filterSetting of this.filterSettings) {
            if (!filterSetting.filterField || !filterSetting.filterValue || !filterSetting.comparator) {
                return false;
            }
        }
        this.filterSettings = [...this.filterSettings];

        return true;
    }

    public onFilterSettingChanged(filterSetting: IResultFilterSetting) {
        this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
    }
}
