import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActionType } from 'projects/gw-web-components/src/app/gw-pipe-lib/enum/action-type.enum';
import { SortingType } from 'projects/gw-web-components/src/app/gw-search-lib/enum/sorting-type.enum';
import { FilterFieldInterface } from 'projects/gw-web-components/src/app/gw-search-lib/interface/filter-field-interface';
import { ResultState } from 'projects/gw-web-components/src/app/gw-search-lib/interface/result-state';
import { EventBusService } from 'projects/gw-web-components/src/app/gw-search-lib/service/event-bus.service';
import { SortingState } from 'projects/gw-web-components/src/app/gw-search-lib/type/sorting-state.type';
import { SortingValue } from '../../../gw-search-lib/interface/sorting-value';
import { FilterService } from '../../../gw-search-lib/service/filter.service';
import { TranslationService } from '../../../gw-translation-lib/service/translation.service';

@Component({
  selector:    'gw-sorting-selector',
  templateUrl: './sorting-selector.component.html',
  styleUrls:   ['./sorting-selector.component.scss']
})
export class SortingSelectorComponent implements OnInit {

  @Input('gw-class')
  public cssClasses: string;

  @Input('gw-sortings')
  public _sortingsAsString: string;

  public selectedOption: FormControl = new FormControl();

  public placeholder = this.translationService.translate('result.sorting-selector.placeholder');

  public get triggerLabel(): string {
    return this.selectedOption.value ? this.selectedOption.value.filterField.label_translated + ' (' + this.selectedOption.value.orderLabel + ')' : this.placeholder;
  }

  public options: {
    field: string,
    order: SortingType,
    orderLabel: string,
    filterField: FilterFieldInterface
  }[] = [];

  constructor(
    protected eventBus: EventBusService,
    protected filterService: FilterService,
    protected translationService: TranslationService
  ) {}

  public ngOnInit() {

    let sortingFields: string[] = [];
    try {
      sortingFields = JSON.parse('["' + this._sortingsAsString.split(',').join('","') + '"]');
    } catch (e) {
      throw new Error('sortings must be written in the following form: ["<fieldname1>","<fieldname2>",…,"<fieldnameN>"]');
    }

    this.eventBus.on<ResultState>(ActionType.RESULT_STATE_READY, event => this.onResultStateReady(event.payload, sortingFields));
    this.eventBus.on<SortingState>(ActionType.RESULT_SORT, event => (event.sender !== this) ? this.onSortingChanged(event.payload) : null);

  }

  /**
   *
   * @param sortingState
   */
  private onSortingChanged(sortingState: SortingState) {

    const newSelectedOption = this.options.find(option => {
      return (
        option.filterField === sortingState.field &&
        option.order === sortingState.order
      );
    });
    if (newSelectedOption) {
      this.selectedOption.patchValue(newSelectedOption);
    }
  }

  private onSortingSelected(newSorting: SortingValue) {
    this.eventBus.broadcast<SortingState>(this, ActionType.RESULT_SORT, {
      field: newSorting.filterField,
      order: newSorting.order
    })
  }

  /**
   *
   * @param sortingFieldNames
   */
  private initOptions(sortingFieldNames: string[]): void {
    const options = [];
    sortingFieldNames.forEach(fieldName => {
      const filterField = this.filterService.getFilterFieldByName(fieldName);
      filterField.label_translated = this.translationService.translate(filterField.label);
      const labelAsc = this.translationService.translate(`sorting.selector.option.order.${SortingType.ASC.toLowerCase()}`);
      const labelDesc = this.translationService.translate(`sorting.selector.option.order.${SortingType.DESC.toLowerCase()}`);
      options.push({
        field:           filterField.key,
        order:           SortingType.ASC,
        orderLabel:      labelAsc,
        filterField:     filterField
      });
      options.push({
        field:           filterField.key,
        order:           SortingType.DESC,
        orderLabel:      labelDesc,
        filterField:     filterField
      });
    });
    this.options = options;
  }

  private onResultStateReady(resultState: ResultState, sortingFieldNames: string[]) {
    this.initOptions(sortingFieldNames);
    const selectedOption = this.options.find(option => {
      return option.filterField === resultState.sorting.field && option.order === resultState.sorting.order;
    });
    this.selectedOption.patchValue(selectedOption);

    this.selectedOption.valueChanges.subscribe(newSorting => this.onSortingSelected(newSorting));
  }

}
