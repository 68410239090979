import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {EventBusService} from 'projects/gw-web-components/src/app/gw-search-lib/service/event-bus.service';
import {TranslationService} from 'projects/gw-web-components/src/app/gw-translation-lib/service/translation.service';
import {SortingType} from '../../../gw-search-lib/enum/sorting-type.enum';
import {FilterFieldInterface} from '../../../gw-search-lib/interface/filter-field-interface';
import {FilterService} from '../../../gw-search-lib/service/filter.service';
import {filter, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
    selector: 'gw-result-sorting-setting',
    templateUrl: './result-sorting-setting.component.html',
    styleUrls: ['./result-sorting-setting.component.scss']
})
export class ResultSortingSettingComponent implements OnInit, OnDestroy {

    private _availableFilterFields: FilterFieldInterface[];

    @Input('filters')
    public set availableFilterFields(availableFilterFields: FilterFieldInterface[]) {
        this._availableFilterFields = availableFilterFields
            .filter(field => field.inputType === 'range')
            .filter(field => ['price_gross', 'mileage', 'admission_year'].includes(field.key))
            .sort((fieldA, fieldB) => {
                const labelA = (fieldA.label || '').toUpperCase();
                const labelB = (fieldB.label || '').toUpperCase();

                if (labelA === labelB) {
                    return 0;
                }

                return labelA < labelB ? -1 : 1;
            });
    }
    public get availableFilterFields(): FilterFieldInterface[] {
        return this._availableFilterFields;
    }

    @Input('sorting')
    public sortingSetting: { field: string, order: SortingType };

    @Output()
    public sortingChange: EventEmitter<{ field: string, order: SortingType }> = new EventEmitter<{ field: string, order: SortingType }>();

    public selectedSortingFilterCtl: FormControl = new FormControl(null, {validators: [Validators.required]});

    public selectedSortingTypeCtl: FormControl = new FormControl(null, {validators: [Validators.required]});

    public sortingTypes: SortingType[] = [SortingType.ASC, SortingType.DESC];

    private _translatedLabels: Map<string, string> = new Map<string, string>();

    private _destroy$ = new Subject<void>();

    public placeholderSorting: string = this.translationService.translate('resultsorting.trigger.placeholder.sorting');

    public placeholderOrder: string = this.translationService.translate('resultsorting.trigger.placeholder.order');

    constructor(
        protected filterService: FilterService,
        protected eventBus: EventBusService,
        protected translationService: TranslationService
    ) {
    }

    ngOnInit() {
        this._translatedLabels = new Map<string, string>(this.sortingTypes.map<[string, string]>(sortingType => [
            sortingType,
            this.translationService.translate(`sorting.selector.option.order.${sortingType.toLowerCase()}`)
        ]));

        if (this.sortingSetting && this.sortingSetting.field) {
            this.filterService.filterConfiguration$.pipe(
                takeUntil(this._destroy$),
                filter(filters => filters.size > 0)
            ).subscribe(() => this.onResultStateReady());
        }
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public translatedLabel(option: SortingType): string {
        return this._translatedLabels.get(option);
    }

    private onResultStateReady() {
        const filterField = this.filterService.getFilterFieldByName(this.sortingSetting.field);

        this.selectedSortingFilterCtl.patchValue(filterField);
        this.selectedSortingTypeCtl.patchValue(this.sortingSetting.order.toUpperCase());

        this.selectedSortingFilterCtl.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => this.emitChange());
        this.selectedSortingTypeCtl.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => this.emitChange());
    }

    private emitChange() {
        this.sortingChange.emit({
            field: this.selectedSortingFilterCtl.value.key,
            order: this.selectedSortingTypeCtl.value?.toString().toUpperCase() ?? SortingType.ASC
        });
    }
}
