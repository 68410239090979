import {Component, HostBinding, Inject, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import first from 'lodash-es/first';
import {ActionType} from 'projects/gw-web-components/src/app/gw-pipe-lib/enum/action-type.enum';
import {ResultState} from 'projects/gw-web-components/src/app/gw-search-lib/interface/result-state';
import {CarModel} from 'projects/gw-web-components/src/app/gw-search-lib/model/car-model';
import {EventBusService} from 'projects/gw-web-components/src/app/gw-search-lib/service/event-bus.service';
import {Observable} from 'rxjs';
import {WINDOW} from '../../gw-configuration-lib/reference/window-ref';
import {ComparatorType} from '../../gw-search-lib/enum/comparator-type.enum';
import {SortingType} from '../../gw-search-lib/enum/sorting-type.enum';
import {CarInterface} from '../../gw-search-lib/interface/car-interface';
import {FilterOptionInterface} from '../../gw-search-lib/interface/filter-option-interface';
import {FilterValueResponse} from '../../gw-search-lib/interface/filter-value-response';
import {SortingValue} from '../../gw-search-lib/interface/sorting-value';
import {FilterFieldModel} from '../../gw-search-lib/model/filter-field.model';
import {FilterService} from '../../gw-search-lib/service/filter.service';
import {LastSeenId, LastSeenService} from '../../gw-search-lib/service/last-seen.service';
import {SearchService} from '../../gw-search-lib/service/search.service';
import {ToolBoxService} from '../../gw-search-lib/service/tool-box.service';
import {tap} from "rxjs/operators";

@Component({
    //selector: 'gw-last-seen',
    templateUrl: './last-seen.component.html',
    styleUrls: ['./last-seen.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LastSeenComponent implements OnInit {

    protected _limit: number = 3;

    @HostBinding('class.gw-cmp-ready')
    protected isReadyToShow: boolean = false;

    @Input('gw-filters')
    public inputFilterOptionsJsonString: string;

    @Input('gw-page-size')
    public set limit(limit: string) {
        this._limit = parseInt(limit);
    }

    @Input('gw-class')
    public classes: string;

    public observableCars: Observable<CarInterface[]>;
    public template: Element[] = [this.window.document.createElement('div')];

    @ViewChild('tmpl')
    protected tmpl: TemplateRef<any>;

    protected from: number = 0;
    protected sorting: SortingValue;
    protected inputFilterOptions: FilterOptionInterface[];
    protected lastSeenFilterOptions: FilterOptionInterface[];
    protected _templates: Map<string, Element[]>;

    public get hostClasses(): string {
        const classBindings: {key: string}[] = this.vcRef["_hostTNode"]["classBindings_"] || [];
        return classBindings.filter(n => n !== null).map(n => n.key).join(' ');
    }

    constructor(
        @Inject(WINDOW) protected window: Window,
        protected searchService: SearchService,
        protected eventBus: EventBusService,
        protected filterService: FilterService,
        protected lastSeenService: LastSeenService,
        protected toolboxService: ToolBoxService,
        protected vcRef: ViewContainerRef
    ) {
    }

    public ngOnInit() {
        this._templates = this.toolboxService.queryAllTemplates(first(this.vcRef["_hostTNode"].projection));
        if (this.lastSeenService.hasLastSeen() === false) {
            this.template = this._templates.get('empty');
            this.isReadyToShow = true;
        } else {
            this.eventBus.on<ResultState>(
                ActionType.RESULT_STATE_READY,
                () => this.onResultStateReady()
            );
        }
    }

    /**
     * transforms input filter option description to filter options
     * @param filterInput
     */
    protected transformLocalFilterOptions(filterInput: string = '[]'): FilterOptionInterface[] {
        const filtersByInput: { field: string, value: string }[] = JSON.parse(filterInput);
        return filtersByInput.map(filterByInput => this.filterService.getOptionByFieldName(filterByInput.field, filterByInput.value));
    }

    /**
     *
     * @param lastSeenIds
     */
    protected transformLastSeen(lastSeenIds: LastSeenId[]): FilterOptionInterface[] {

        const options = lastSeenIds.map<FilterValueResponse>(lastSeenId => {
            return <FilterValueResponse>{
                count: 1,
                value: lastSeenId.id,
                label: 'temp_' + lastSeenId.id,
                field: 'id',
                type: ComparatorType.EQ
            };
        });

        const filterField = new FilterFieldModel({
            options: options,
            key: 'id',
            input_type: 'select',
            label: '',
            label_translated: '',
            option_value_type: ComparatorType.EQ
        });

        return filterField.options;

    }

    /**
     *
     */
    protected getLastSeen(): void {

        const filterOptionKeyValuePairs = [
                ...this.lastSeenFilterOptions,
                ...this.inputFilterOptions
            ].map<[string, FilterOptionInterface]>(filterOption => [filterOption.hash, filterOption]);
        const filterOptionsMap = new Map<string, FilterOptionInterface>(filterOptionKeyValuePairs);

        this.observableCars = this.searchService.search(CarModel, {
            filterOptions: filterOptionsMap,
            sorting: {
                field: 'id',
                order: SortingType.ASC
            },
            offset: this.from,
            limit: this._limit,
            isGlobal: false
        }).pipe(
            tap(() => this.template = this._templates.get('hits')),
            tap(() => this.isReadyToShow = true)
        );
    }

    private onResultStateReady(): void {
        this.transformInputs();
        this.getLastSeen();
    }

    private transformInputs(): void {
        // sorting
        this.sorting = {
            field: 'id',
            order: SortingType.ASC
        };

        this.inputFilterOptions = this.transformLocalFilterOptions(this.inputFilterOptionsJsonString);
        this.lastSeenFilterOptions = this.transformLastSeen(this.lastSeenService.getLastSeen(this._limit));
    }
}
