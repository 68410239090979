export enum ActionType {

  HASH_CHANGED               = 'hash:changed',
  LAST_SEEN_ADD              = 'lastseen:add',
  LAST_SEEN_CHANGED          = 'lastseen:changed',

  FILTER_SELECTION_CHANGED   = 'filterselection:changed',

  RESULT_STATE_READY         = 'resultstate:ready',
  RESULT_STATE_CHANGED       = 'resultstate:changed',

  FILTER_CONFIGURATION_READY = 'filterconfiguration:ready',

  FILTER_SEGMENT_CHANGE      = 'filtersegment:change',

  FILTER_ADD                 = 'filter:add',
  FILTER_REMOVE              = 'filter:remove',

  FILTERS_RECOUNT            = 'filters:recount',
  FILTERS_CHANGE             = 'filters:change',
  FILTERS_RESET              = 'filters:reset',

  RESULT_REQUEST             = 'result:request',
  RESULT_NEW                 = 'result:new',
  RESULT_SORT                = 'result:sort',
  RESULT_LIMIT               = 'result:limit',
  RESULT_OFFSET              = 'result:offset',

  VIEWPAGING_LAST            = 'viewpaging:last',
  VIEWPAGING_NEXT            = 'viewpaging:next',

  VIEWTYPE_LIST              = 'viewtype:list',
  VIEWTYPE_BOX               = 'viewtype:box',

  IMAGE_STAGE_SELECT         = 'imagestage:select'
}
