import {Injectable, Compiler, ComponentFactory, NgModule} from '@angular/core';
import {HashService} from 'projects/gw-web-components/src/app/gw-search-lib/service/hash.service';
import {Component} from '@angular/core';
import {Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Type} from '@angular/core';
import {DynamicComponent} from "../gw-search-lib/interface/dynamic-component";
import {GwPipeLibModule} from "../gw-pipe-lib/gw-pipe-lib.module";

@Injectable({
    providedIn: 'root'
})
export class TypeBuildService {

    protected _factoryCache: Map<string, ComponentFactory<DynamicComponent>> = new Map();

    constructor(
        protected compiler: Compiler
    ) {
    }

    public createComponentFactory(cmpDef: Component): Promise<ComponentFactory<DynamicComponent>> {
        const templateHash = HashService.md5(cmpDef.template);

        if (this._factoryCache.has(templateHash)) {
            const factory = this._factoryCache.get(templateHash);

            return new Promise((resolve) => resolve(factory));
        }

        cmpDef.template = cmpDef.template
            .replace(/\*ngif=/gm, '*ngIf=')
            .replace(/ngclass/gm, 'ngClass')
            .replace(/\*ngfor=/gm, '*ngFor=')
            .replace(/\*ngswitchcase=/gm, '*ngSwitchCase=')
            .replace(/\*ngswitchdefault =/gm, '*ngSwitchDefault=');

        // unknown template ... let's create a Type for it
        const componentClass = this.createNewComponent(cmpDef);
        const moduleClass = this.createComponentModule(componentClass);

        return new Promise((resolve) => {
            this.compiler
                .compileModuleAndAllComponentsAsync(moduleClass)
                .then((moduleWithFactories) => {
                    const componentFactory = moduleWithFactories.componentFactories.find(factory => factory.componentType === componentClass);
                    this._factoryCache.set(templateHash, componentFactory);
                    resolve(componentFactory);
                });
        });
    }

    protected createNewComponent(cmpDef: Component): Type<DynamicComponent> {
        const Cmp = class implements DynamicComponent {
            public entity: any;
        };
        Input('entity')(Cmp.prototype, 'entity');

        return Component(cmpDef)(Cmp);
    }

    protected createComponentModule(componentType: Type<DynamicComponent>) {

        return NgModule({
            imports: [
                CommonModule,
                GwPipeLibModule
            ],
            declarations: [
                componentType
            ],
        })(class {
        });
    }

}
