import { InjectionToken, ValueProvider } from '@angular/core';
import { environment } from '../../../environments/environment';

export const APP_PREFIX = new InjectionToken('appPrefix');

const appPrefixProvider: ValueProvider = {
  provide: APP_PREFIX,
  useValue: environment.appPrefix
};

export const APP_PREFIX_PROVIDER = [
  appPrefixProvider
];
