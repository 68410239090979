import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { APP_PREFIX_PROVIDER } from './reference/app-prefix';
import { AppConfigService } from './service/app-config.service';
import { WINDOW_PROVIDERS } from './reference/window-ref';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    WINDOW_PROVIDERS,
    APP_PREFIX_PROVIDER,
    AppConfigService
  ]
})
export class GwConfigurationLibModule { }
