import { Injectable, Inject } from '@angular/core';
import { APP_PREFIX } from '../../gw-configuration-lib/reference/app-prefix';
import { HashService } from './hash.service';
import { WINDOW } from '../../gw-configuration-lib/reference/window-ref';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  protected listeners: Map<string, Subject<any>> = new Map<string, Subject<any>>();

  public store: Storage;

  constructor(
    @Inject(APP_PREFIX) protected prefix: string,
    @Inject(WINDOW) protected window: Window
  ) {
    this.store = this.window.localStorage;
    this.window.addEventListener('storage', (ev) => this.storageEventListener(ev));
  }

  public addListener(key: any): Observable<any> {
    const storeKey = this.buildKey(key);
    if (this.listeners.has(storeKey) === false) {
      this.listeners.set(storeKey, new Subject<any>());
    }
    return this.listeners.get(storeKey).asObservable();
  }

  /**
   *
   * @param key
   * @param directAccess
   */
  public get<T>(key: any, directAccess: boolean = false): T {
    const storeKey = directAccess ? key : this.buildKey(key),
          serializedData = this.store.getItem(storeKey);

    if(!serializedData) {
      return null;
    }

    try {
      return JSON.parse(serializedData).content;
    } catch (e) {
      throw new Error(`Unable to unserialize data from store for key ${key}`);
    }
  }

  /**
   *
   * @param key
   * @param value
   */
  public set<T>(key: any, value: T): void {
    const storeKey = this.buildKey(key),
          valueStr = this.serialize(value);

    this.store.setItem(storeKey, valueStr);
  }

  /**
   * is key in local storage
   * @param key
   */
  public has(key: any): boolean {
    const storeKey = this.buildKey(key),
          len = this.store.length;

    for(let i = 0; i < len; i++) {
      if(this.store.key(i) === storeKey) {
        return true;
      }
    }

    return false;
  }

  /**
   * builds key
   *
   * @param key
   */
  protected buildKey(key: any): string {
    const hash = HashService.md5(this.serialize(key));
    return `${this.prefix}-${hash}`;
  }

  protected serialize(key: any): string {
    try {
      return `{"content": ${JSON.stringify(key)}}`;
    } catch (e) {
      throw new Error('Key to save to store is not serializable: ');
    }
  }

  protected storageEventListener(event: StorageEvent) {
    const regExp = new RegExp(`^${this.prefix}-`);

    if (event.storageArea === this.store && event.newValue !== event.oldValue && regExp.test(event.key)) {
      if (this.listeners.has(event.key)) {
        const value = this.get(event.key, true);
        this.listeners.get(event.key).next(value);
      }

    }
  }
}
