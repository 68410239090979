import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '../../gw-configuration-lib/reference/window-ref';
import {GwTemplate} from '../../interface/gw-template';
import {OptionValueType} from '../enum/field-value-type.enum';
import mapValues from 'lodash-es/mapValues';
import {formatNumber} from '@angular/common';
import {ViewType} from "../../gw-pipe-lib/enum/view-type.enum";
import {Helpers} from "../../gw-pipe-lib/helpers";

@Injectable({
    providedIn: 'root'
})
export class ToolBoxService {

    constructor(
        @Inject(WINDOW) protected windowRef: Window
    ) {
    }

    public static stableSort<T>(arr: T[], compare: (a: T, b: T) => number) {
        return arr.map((item: T, index: number) => ({item, index}))
            .sort((a, b) => compare(a.item, b.item) || a.index - b.index)
            .map(({item}) => item);
    }

    public queryAllTemplates(elements: Element[]): Map<string, Element[]> {
        const wrapperElement: HTMLDivElement = this.windowRef.document.createElement('div');
        wrapperElement.append(...elements);

        const templates = new Map<string, Element[]>();
        wrapperElement
            .querySelectorAll('[gw-template]')
            .forEach(template => {
                const templateName = template.getAttribute('gw-template');
                const elements = Array.from(template.children);
                templates.set(templateName.startsWith('itemAs') ? templateName.substr(6).toLowerCase() : templateName, elements)
            });

        return templates;
    }

    public querySelectorTemplate<T extends GwTemplate>(nodes: Node[], selectors: { [name: string]: string } = null): T {
        const wrapperNode = this.windowRef.document.createElement('div');
        nodes.forEach(node => wrapperNode.appendChild(node));

        if (selectors === null) {
            const tmpl = <T>{};
            tmpl.item = wrapperNode as Element;

            return tmpl;
        }

        return mapValues(selectors, selector => {
            return wrapperNode.querySelector(`[gw-template="${selector}"]`);
        }) as T;
    }

    /**
     *
     * @param value
     * @param type
     */
    public static transformInput(value: string, type: OptionValueType): any {
        let result: any = value;
        switch (type) {
            case OptionValueType.BOOLEAN:
                result = this.parseBoolean(value);
                break;
            case OptionValueType.INTEGER:
            case OptionValueType.SHORT:
            case OptionValueType.DATE:
                result = parseInt(value);
                break;
            case OptionValueType.FLOAT:
                result = parseFloat(value);
                break;
        }

        return result;
    }

    /**
     * parse value to integer representation of boolean true or false
     * @param value
     */
    protected static parseBoolean(value: string): boolean {
        let trueBooleanValues: string[];
        trueBooleanValues = ['1', 'TRUE', 'true'];

        return (trueBooleanValues.indexOf(value) >= 0);
    }

    public static formatHorsePower = Helpers.formatHorsePower;

    public static buildViewTypeItemKey(viewType: ViewType): string {
        return "itemAs" + viewType.substr(0, 1).toUpperCase() + viewType.substr(1);
    }
}
