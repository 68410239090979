import {NgModule} from '@angular/core';
import {CommonModule, DatePipe, DecimalPipe} from '@angular/common';
import {ViewportService} from './service/viewport.service';
import {HorsepowerPipe} from './pipe/horsepower.pipe';
import {HrefDirective} from './directive/href.directive';
import {ResultScrollingSettingComponent} from './component/result-scrolling-setting/result-scrolling-setting.component';
import {ActionDirective} from './directive/action.directive';
import {CheckboxComponent} from './component/checkbox/checkbox.component';
import {CountComponent} from './component/count/count.component';
import {SelectComponent} from './component/select/select.component';
import {TranslateComponent} from './component/translate/translate.component';
import {PerPageSelectorComponent} from '../component/per-page-selector/per-page-selector.component';
import {GwTranslationLibModule} from '../gw-translation-lib/gw-translation-lib.module';
import {SortingSelectorComponent} from './component/sorting-selector/sorting-selector.component';
import {PaginatorComponent} from './component/paginator/paginator.component';
import {ImageComponent} from './component/image/image.component';
import {WaehrungPipe} from './pipe/waehrung.pipe';
import {PaginatePipe} from './pipe/paginate.pipe';
import {TranslatePipe} from './pipe/translate.pipe';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ImageStageComponent} from './component/image-stage/image-stage.component';
import {ImageListComponent} from './component/image-list/image-list.component';
import {ResultFilterSettingComponent} from './component/result-filter-setting/result-filter-setting.component';
import {ResultSortingSettingComponent} from './component/result-sorting-setting/result-sorting-setting.component';
import {ResultTypeSettingComponent} from './component/result-type-setting/result-type-setting.component';
import {ResultPaginationPerPageSettingComponent} from './component/result-pagination-per-page-setting/result-pagination-per-page-setting.component';
import {SrcDirective} from "./directive/src.directive";
import {MatInputModule} from "@angular/material/input";
import {SliderComponent} from "./component/slider/slider.component";
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import {PageSelectionPipe} from "./component/paginator/page-selection.pipe";

@NgModule({
    imports: [
        CommonModule,
        MatCheckboxModule,
        MatSelectModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        GwTranslationLibModule,
        MatInputModule,
        NgxSliderModule,
        FormsModule
    ],
    exports: [
        WaehrungPipe,
        PaginatePipe,
        TranslatePipe,
        HorsepowerPipe,
        DatePipe,
        DecimalPipe,
        PageSelectionPipe,
        ActionDirective,
        ImageComponent,
        ImageListComponent,
        ImageStageComponent,
        PaginatorComponent,
        SortingSelectorComponent,
        PerPageSelectorComponent,
        TranslateComponent,
        CountComponent,
        CheckboxComponent,
        SelectComponent,
        SliderComponent,
        ResultFilterSettingComponent,
        ResultSortingSettingComponent,
        ResultTypeSettingComponent,
        ResultPaginationPerPageSettingComponent,
        ResultScrollingSettingComponent,
        MatCheckboxModule,
        MatSelectModule,
        ReactiveFormsModule,
        HrefDirective,
        SrcDirective
    ],
    providers: [
        ViewportService
    ],
    declarations: [
        WaehrungPipe,
        PaginatePipe,
        TranslatePipe,
        HorsepowerPipe,
        PageSelectionPipe,
        ActionDirective,
        ImageComponent,
        PaginatorComponent,
        SortingSelectorComponent,
        PerPageSelectorComponent,
        TranslateComponent,
        CountComponent,
        CheckboxComponent,
        SelectComponent,
        ImageStageComponent,
        ImageListComponent,
        ResultFilterSettingComponent,
        ResultSortingSettingComponent,
        ResultTypeSettingComponent,
        ResultPaginationPerPageSettingComponent,
        ResultScrollingSettingComponent,
        SliderComponent,
        HrefDirective,
        SrcDirective
    ]
})
export class GwPipeLibModule {

}
