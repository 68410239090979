import {Component, Input, OnInit} from '@angular/core';
import {ActionType} from 'projects/gw-web-components/src/app/gw-pipe-lib/enum/action-type.enum';
import {ResultState} from 'projects/gw-web-components/src/app/gw-search-lib/interface/result-state';
import {EventBusService} from 'projects/gw-web-components/src/app/gw-search-lib/service/event-bus.service';
import {FilterFieldInterface} from '../../../gw-search-lib/interface/filter-field-interface';
import {FilterOptionInterface} from '../../../gw-search-lib/interface/filter-option-interface';
import {FilterService} from '../../../gw-search-lib/service/filter.service';
import {ToolBoxService} from '../../../gw-search-lib/service/tool-box.service';
import {FilterFieldConfiguration} from "../../../gw-search-lib/type/filter-field-configuration";

@Component({
    selector: 'gw-count',
    template: `{{(option?.changed$ | async)?.count || option?.count || 0}}`,
    styleUrls: ['./count.component.scss']
})
export class CountComponent implements OnInit {

    private filterField: FilterFieldInterface;

    @Input('gw-field') public fieldName: string;

    @Input('gw-value') public fieldOptionValue: string;

    public option: FilterOptionInterface;

    constructor(
        protected filterService: FilterService,
        protected eventBus: EventBusService
    ) {
    }

    ngOnInit() {
        this.eventBus.on<ResultState>(ActionType.RESULT_STATE_READY, event => {
            this.getOption();

            this.eventBus.on<FilterFieldConfiguration>(ActionType.FILTER_SEGMENT_CHANGE, filterConfigurationChangeEvent => {
                    this.getOption();
                }
            );
        });

    }

    protected getOption(): any {
        this.filterField = this.filterService.getFilterFieldByName(this.fieldName);
        const value = ToolBoxService.transformInput(this.fieldOptionValue, this.filterField.optionValueType);
        this.option = this.filterService.getOption(this.filterField, value);
    }

}
