import {Component, Input, OnInit} from '@angular/core';
import {TranslationService} from '../../../gw-translation-lib/service/translation.service';

@Component({
  selector: 'gw-translate',
  template: `{{translationResult}}`,
  styleUrls: ['./translate.component.scss']
})
export class TranslateComponent implements OnInit {

  @Input('gw-id') translationId: string;

  @Input('gw-args') translationArguments: any[] = [];

  public translationResult: string = '';

  constructor(
    protected translationService: TranslationService
  ) {
  }

  ngOnInit() {
    if(!(typeof this.translationId === "string" && this.translationId.length > 0)) {
      this.translationId = 'no translation id defined for »gw-id«';
    }

    this.translationResult = this.translationService.translate(this.translationId, this.translationArguments);
  }

}
