import {Component, Input, OnInit} from '@angular/core';
import {ListAlignment} from '../../enum/list-alignment.enum';
import {ActionType} from '../../enum/action-type.enum';
import {EventBusService} from '../../../gw-search-lib/service/event-bus.service';
import {ImageStageEventPayload} from '../../interface/image-stage-event-payload';

@Component({
  selector:    'gw-image-list',
  templateUrl: './image-list.component.html',
  styleUrls:   ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit {

  @Input('gw-images') public images: string[];
  @Input('gw-config') public config: any;
  @Input('gw-for-stage') public stageName: string = '';

  public localImages: string[] = [];

  public listContainerWidth: number = 500;                     // list container width
  public listContainerHeight: number = 100;                    // list container height

  protected imageCssClasses: string;                              // extra css classes for image element
  protected imageWidth: number = 100;                             // image width
  protected imageHeight: number = 100;                            // image height
  protected imageDisplayCount: number = 5;                        // how many images to display side by side
  protected thumbnailSourcePath: string = '100_100_outbound/';    // path to subfolder for thumbnails (with ending slash)
  protected listAlignment: string = ListAlignment.LEFT_TO_RIGHT;                        // left to right - alternative: t2b: top to bottom

  constructor(protected eventBusService: EventBusService) { }

  ngOnInit() {
    this.localImages = [...this.images];
    if (typeof this.config.imageCssClasses === "string") {
      this.imageCssClasses = this.config.imageCssClasses;
    }
    if (typeof this.config.imageWidth === "number") {
      this.imageWidth = this.config.imageWidth;
    }
    if (typeof this.config.imageHeight === "number") {
      this.imageHeight = this.config.imageHeight;
    }
    if (typeof this.config.imageDisplayCount === "number") {
      this.imageDisplayCount = this.config.imageDisplayCount;
    }
    if (typeof this.config.thumbnailSourcePath === "string") {
      this.thumbnailSourcePath = this.config.thumbnailSourcePath;
    }
    if (typeof this.config.listAlignment === "string") {
      this.listAlignment = this.config.listAlignment;
    }

    if (this.listAlignment === ListAlignment.LEFT_TO_RIGHT) {
      this.listContainerWidth = this.imageWidth * this.imageDisplayCount;
      this.listContainerHeight = this.imageHeight;
    } else {
      this.listContainerWidth = this.imageWidth;
      this.listContainerHeight = this.imageHeight * this.imageDisplayCount;
    }
  };

  /**
   * show image on stage
   * @param image
   */
  public showImageOnStage(image: string) {
    this.eventBusService.broadcast<ImageStageEventPayload>(this, ActionType.IMAGE_STAGE_SELECT, {
      name:   this.stageName,
      source: image
    });
  };

  /**
   * slide list backwards
   * @return boolean
   */
  public slideBackwards(): boolean {
    this.localImages.unshift(this.localImages.pop());
    return false;
  }

  /**
   * slide list forward
   * @return boolean
   */
  public slideForward(): boolean {
    this.localImages.push(this.localImages.shift());
    return false;
  }

}
