import { Injectable } from '@angular/core';
import { EventBusService } from './event-bus.service';
import { ActionType } from '../../gw-pipe-lib/enum/action-type.enum';
import { LocalStorageService } from './local-storage.service';

export type LastSeenId = { id: string, lastSeenAt: number };

@Injectable({
  providedIn: 'root'
})
export class LastSeenService {

  static readonly LAST_SEEN = 'last_seen';

  constructor(
    protected storageService: LocalStorageService,
    protected eventBus: EventBusService
  ) {
    this.eventBus.on<string>(ActionType.LAST_SEEN_ADD, event => this.onLastSeenAdded(event.payload));
  }

  private onLastSeenAdded(carId: string) {
    this.addLastSeen(carId);
  }

  public getLastSeen(size: number = null): LastSeenId[] {
    if(this.storageService.has(LastSeenService.LAST_SEEN) === false) {
      return [];
    }

    let lastSeenEntities = this.storageService.get<LastSeenId[]>(LastSeenService.LAST_SEEN);
    if(size && lastSeenEntities.length > size) {
      lastSeenEntities = lastSeenEntities.slice(0, size);
    }
    return lastSeenEntities;
  }

  private addLastSeen(id: string): void {
    const lastSeen = this.getLastSeen();
    const index = lastSeen.findIndex(lastSeenEntity => {
      return lastSeenEntity.id === id;
    });
    if(index === -1) {
      // is new -> add to front of array
      lastSeen.unshift({id: id, lastSeenAt: (new Date().getTime())});
    } else {
      // is already seen -> remove from old index to first and update lastSeenAt
      lastSeen.splice(index, 1);
      lastSeen.unshift({id: id, lastSeenAt: (new Date().getTime())});
    }
    this.saveLastSeen(lastSeen);
    this.eventBus.broadcast(this, ActionType.LAST_SEEN_CHANGED, lastSeen);
  }

  /**
   *
   */
  public hasLastSeen(): boolean {
    return (
      this.storageService.has(LastSeenService.LAST_SEEN) &&
      this.getLastSeen().length > 0
    );
  }

  protected saveLastSeen(lastSeen: LastSeenId[]): void {
    this.storageService.set<LastSeenId[]>(LastSeenService.LAST_SEEN, lastSeen);
  }
}
