import { ComparatorType } from 'projects/gw-web-components/src/app/gw-search-lib/enum/comparator-type.enum';
import { FilterOptionInterface } from 'projects/gw-web-components/src/app/gw-search-lib/interface/filter-option-interface';
import { FilterValueSelectEvent } from 'projects/gw-web-components/src/app/gw-search-lib/type/filter-value-select-event.type';
import { OptionValueType } from '../enum/field-value-type.enum';
import { FilterFieldInterface } from '../interface/filter-field-interface';
import { FilterFieldResponse } from '../interface/filter-field-response';
import { FilterValueResponse } from '../interface/filter-value-response';
import { FilterOptionModel } from '../model/filter-option.model';
import upperCase from 'lodash-es/upperCase';
import { Observable } from 'rxjs';

export class FilterFieldModel implements FilterFieldInterface {

  protected optionsIndex = [];

  public inputType: string;

  public key: string;

  public label: string;

  public label_translated: string;

  public optionValueType: OptionValueType;

  public options: FilterOptionInterface[] = [];

  /**
   *
   * @param filterFieldResponse
   */
  constructor(filterFieldResponse: FilterFieldResponse) {
    this.inputType = filterFieldResponse.input_type;
    this.key = filterFieldResponse.key;
    this.label = filterFieldResponse.label || "" + (new Date()).getTime();
    this.label_translated = filterFieldResponse.label_translated || "" + (new Date()).getTime();
    this.optionValueType = upperCase(filterFieldResponse.option_value_type || OptionValueType.KEYWORD) as OptionValueType;

    filterFieldResponse.options.forEach((filterOptionResponse: FilterValueResponse) => this.addOption(new FilterOptionModel(this, filterOptionResponse)));
  }

  /**
   *
   * @param filterOptionModel
   */
  public addOption(filterOptionModel: FilterOptionModel): void {
    const key = `${filterOptionModel.type.toUpperCase()}:${filterOptionModel.label}`;
    if(this.optionsIndex.includes(key) === false) {
      this.options.push(filterOptionModel);
      this.optionsIndex.push(key);
    }
  }

  /**
   *
   */
  public getOptionSelectObservers(): Observable<FilterValueSelectEvent>[] {
    return this.options.map(option => {
      return option.selectChanged$;
    });
  }

  /**
   * find option by string compare of value and comparator
   * @param comparator
   * @param value
   */
  public findOption(comparator: ComparatorType, value: any): FilterOptionInterface {
    return this.options.find(option => {
      return (
        option.type === comparator &&
        "" + option.value === "" + value
      );
    });
  }

}
