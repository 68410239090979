import {Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import difference from 'lodash-es/difference';
import first from 'lodash-es/first';
import isArray from 'lodash-es/isArray';
import isString from 'lodash-es/isString';
import isEqual from "lodash-es/isEqual";
import sortBy from "lodash-es/sortBy";
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {ComparatorType} from '../../../gw-search-lib/enum/comparator-type.enum';
import {FilterFieldInterface} from '../../../gw-search-lib/interface/filter-field-interface';
import {FilterOptionInterface} from '../../../gw-search-lib/interface/filter-option-interface';
import {ResultState} from '../../../gw-search-lib/interface/result-state';
import {FilterOptionModel} from '../../../gw-search-lib/model/filter-option.model';
import {EventBusService} from '../../../gw-search-lib/service/event-bus.service';
import {FilterService} from '../../../gw-search-lib/service/filter.service';
import {ResultStateService} from '../../../gw-search-lib/service/result-state.service';
import {TranslationService} from '../../../gw-translation-lib/service/translation.service';
import {ActionType} from '../../enum/action-type.enum';
import {distinctUntilChanged, map, takeUntil} from "rxjs/operators";
import {OptionValueType} from "../../../gw-search-lib/enum/field-value-type.enum";
import {SortingType} from "../../../gw-search-lib/enum/sorting-type.enum";
import {Helpers} from "../../helpers";
import {ChangeEventContext} from "../../types";
import {FilterFieldConfiguration} from "../../../gw-search-lib/type/filter-field-configuration";

type FilterOptionCount = Map<string, FilterOptionInterface>;

@Component({
    selector: 'gw-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, OnDestroy {

    private _options: FilterOptionInterface[] = [];
    private _multiple: boolean = false;
    private _required: boolean = false;
    private _placeholder: string = this._translationService.translate('filter.misc.option.eq.any');
    private _originalPlaceholder: string = '';
    private _comparator: ComparatorType = ComparatorType.EQ;
    private _hitsOnly: boolean = false;
    private _oldSelectedOptions: FilterOptionInterface[] = [];
    private _filterField: FilterFieldInterface;
    private _doNotBroadcast: boolean = false;
    private _dependsOnOptions: FilterOptionInterface[] = [];
    private _dependsOn: string[] = [];
    private _isDisabled: boolean = false;
    private _stickyValues: string[] = [];
    private _sorting: SortingType = SortingType.ASC;
    private _destroy$ = new Subject<void>();
    private _anyOption: FilterOptionInterface = null;

    public get isDisabled(): boolean {
        return this._isDisabled;
    };

    public set isDisabled(isDisabled: boolean) {
        if (this._isDisabled === isDisabled) {
            return;
        }

        if (isDisabled && !!this.selectElement.value) {
            const anyOption = this._options.find(option => option.value === null);
            if (anyOption && this.selectElement.value !== anyOption) {
                this.selectElement.patchValue(anyOption);
            }
        }

        this._isDisabled = isDisabled;

        isDisabled ? this.selectElement.disable() : this.selectElement.enable();
    }

    public selectElement = new FormControl();
    public filteredOptions$: BehaviorSubject<FilterOptionInterface[]> = new BehaviorSubject<FilterOptionInterface[]>([]);
    public placeholder$ = new BehaviorSubject<string>(this._translationService.translate('filter.misc.option.eq.any'));

    @Input('gw-sorting')
    public set sorting(sorting: SortingType) {
        this._sorting = [SortingType.ASC, SortingType.DESC].includes(sorting.toUpperCase() as SortingType)
            ? sorting.toUpperCase() as SortingType
            : SortingType.ASC;
    }

    public get sorting(): SortingType {
        return this._sorting;
    }

    @Input('gw-sticky-values')
    public set stickyValues(stickyValues: any) {
        if (typeof stickyValues !== 'string' || stickyValues.length < 1) {
            return;
        }
        this._stickyValues = stickyValues.split(',');
    }

    @Input('gw-placeholder')
    public set placeholder(placeholder) {
        placeholder = '' + placeholder;
        if (placeholder.length > 0) {
            if (placeholder === this._placeholder) {
                return;
            }
            this._originalPlaceholder = this._placeholder = placeholder;
            this.placeholder$.next(this.placeholder);
        }
    }

    public get placeholder(): string {
        return this._placeholder
    };

    @Input('gw-multiple')
    public set multiple(multiple) {
        this._multiple = isString(multiple) ? (['true', '1', 'gw-multiple'].includes(multiple)) : !!multiple;
    };

    public get multiple(): boolean {
        return this._multiple;
    }

    @Input('gw-required')
    public set required(required) {
        this._required = isString(required) ? (['true', '1', 'gw-required'].includes(required)) : !!required;
    };

    public get required(): boolean {
        return this._required;
    }

    @Input('gw-comparator')
    public set comparator(comparator) {
        if (isString(comparator) && [ComparatorType.EQ, ComparatorType.LTE, ComparatorType.GTE].includes(comparator.toUpperCase() as ComparatorType)) {
            this._comparator = comparator.toUpperCase() as ComparatorType;
        }
    }

    public get comparator(): ComparatorType {
        return this._comparator;
    }

    @Input('gw-hits-only')
    public set hitsOnly(hitsOnly) {
        if (isString(hitsOnly)) {
            this._hitsOnly = isString(hitsOnly) ? (['true', '1', 'gw-hits-only'].includes(hitsOnly)) : !!hitsOnly;
        }
    }

    public get hitsOnly(): boolean {
        return this._hitsOnly;
    }

    @Input('gw-depends-on')
    public set dependsOn(dependsOn: any) {
        if (typeof dependsOn !== 'string' || dependsOn.length < 1) {
            return;
        }
        this._dependsOn = dependsOn.split(',');
    }

    @Input('gw-class') public cssClasses: string = '';
    @Input('gw-id') public id: string = '';
    @Input('gw-field') public fieldName: string = '';
    @Input('gw-filters') public filtersAsJsonString: string;
    @Input('gw-format-function') public format: string = '';
    @Input('gw-format-unit') public unit: string = '';
    @Input('gw-format-function-param') public additionalParam: string = '';
    @Input('gw-format-appendix') public appendix: string = '';
    // not in use
    @Input('gw-value') public value: any = '';

    constructor(
        protected filterService: FilterService,
        protected _translationService: TranslationService,
        protected resultStateService: ResultStateService,
        protected eventBus: EventBusService,
        @Inject(LOCALE_ID) protected localeId: string
    ) {
    }

    ngOnInit() {
        this.eventBus.on<ResultState>(ActionType.RESULT_STATE_READY, resultStateReadyEvent => {
            this.checkForIsDisabled(resultStateReadyEvent.payload);
            this.onResultStateReady(resultStateReadyEvent.payload);

            this.eventBus.on<ChangeEventContext>(ActionType.FILTERS_CHANGE, filterChangeEvent => {
                // update selected option(s)
                if (filterChangeEvent.sender !== this && (filterChangeEvent.payload.remove.length > 0 || filterChangeEvent.payload.add.length > 0)) {
                    this._onSelectedFilterOptionChanged(filterChangeEvent.payload.remove, filterChangeEvent.payload.add);
                }
                // disable element if preset filters from result settings match
                if (typeof filterChangeEvent.sender === "string" && filterChangeEvent.sender === "ResultComponent" && filterChangeEvent.payload.add.findIndex(o => o.fieldName === this.fieldName) > -1) {
                    this.selectElement.disable();
                }
            }, false, true);

            this.eventBus.on<ResultState>(ActionType.RESULT_STATE_CHANGED, resultStateChangeEvent => {
                this.checkForIsDisabled(resultStateChangeEvent.payload);
                if (!this.isDisabled) {
                    this.onResultStateChanged(resultStateChangeEvent.payload);
                }
            }, false, true);

            this.eventBus.on<FilterFieldConfiguration>(ActionType.FILTER_SEGMENT_CHANGE, filterConfigurationChangeEvent => {
                    this.onFilterSegmentChange(resultStateReadyEvent.payload);
                }
            );

            if (!this.isDisabled) {
                this.onResultStateChanged(resultStateReadyEvent.payload)
            }
        });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete()
    }

    /**
     * format option labels
     * @param options
     * @param type
     * @param unit
     * @param additionalParam
     */
    protected formatOptionLabels(options: FilterOptionInterface[], type: string, unit: string, additionalParam: string) {
        return Helpers.formatOptionLabels(options, type, unit, this.localeId, additionalParam);
    }

    /**
     * append unit string to option labels
     * @param options
     * @param appendix
     */
    private appendStringToOptionLabels(options: FilterOptionInterface[], appendix: string) {
        options.forEach(option => option.label_translated += appendix);

        return options;
    }

    /**
     * @api
     * @param remove
     * @param add
     */
    private _onSelectedFilterOptionChanged(remove: FilterOptionInterface[] = [], add: FilterOptionInterface[] = []) {
        const value = this.selectElement.value;
        const selectedOptions: FilterOptionInterface[] = Array.isArray(value) ? value : (value ? [value] : []);
        const filterOptions = Array.from(this.filterService.selectedFilterOptions.value.values());

        this.isDisabled = false;
        if (this._dependsOn.length > 0) {
            const dependenciesFulfilled = this._dependsOn.every(dependency => filterOptions.some(f => f.fieldName === dependency));

            if (!dependenciesFulfilled) {
                this.isDisabled = true;
                // remove options from filters
                const removableFilterOptions = filterOptions.filter(o => o.fieldName === this.fieldName);
                if (removableFilterOptions.length > 0) {
                    this.eventBus.broadcast<ChangeEventContext>(
                        this,
                        ActionType.FILTERS_CHANGE,
                        {
                            remove: removableFilterOptions,
                            add: []
                        }
                    );
                }
                this.selectElement.patchValue(this._multiple ? [] : this._anyOption);
                return;
            } else {
                this.isDisabled = false;
                this._dependsOnOptions = filterOptions.filter(filterOption => this._dependsOn.includes(filterOption.fieldName));

                this.filteredOptions$.next(this._dependsOnOptions.length > 0
                    ? this.filterOptionsByDependencies(
                        this._options,
                        this._dependsOnOptions
                    )
                    : this._options);
            }
        }

        let newSelectedOptions: FilterOptionInterface[] | FilterOptionInterface = filterOptions.filter(o => o.fieldName === this.fieldName && o.type === this.comparator);
        if (!this._multiple) {
            newSelectedOptions = newSelectedOptions.length < 1 ? this._anyOption : first(newSelectedOptions);
        }
        this.selectElement.patchValue(newSelectedOptions);
    }

    private _onSelectedOptionsChanged(newSelectedFilterOptions: FilterOptionInterface[]): void {
        const deselectedFilterOptions: FilterOptionInterface[] = difference(
            this._oldSelectedOptions,
            newSelectedFilterOptions
        );

        if (this._doNotBroadcast === false && (deselectedFilterOptions.length > 0 || newSelectedFilterOptions.filter(filter => filter.value !== null).length > 0)) {
            this.eventBus.broadcast<ChangeEventContext>(this, ActionType.FILTERS_CHANGE, {
                remove: deselectedFilterOptions,
                add: newSelectedFilterOptions.filter(o => o.value !== null)
            });
        }

        this._oldSelectedOptions = newSelectedFilterOptions;
        this.refreshPlaceholder();
    }

    private prepareOptions() {
        let options = [...this._filterField.options].filter(option => option.type === this.comparator);

        // format label - if format is given
        if (this.format.length > 0) {
            options = this.formatOptionLabels(options, this.format, this.unit, this.additionalParam);
        }

        // enrich option labels with unit information - if given (like "km" or "PS")
        if (this.appendix.length > 0) {
            options = this.appendStringToOptionLabels(options, this.appendix);
        }

        if (this.multiple === false) {
            options = this.appendAnyOption(options);
        }

        if (this._filterField.optionValueType.toUpperCase() !== OptionValueType.BOOLEAN) {
            options = Helpers.sortOptions(options, this._sorting, this._filterField.optionValueType, this._stickyValues);
        }

        // save complete option list
        this._options = options;
    }

    /**
     *
     * @param filterField
     * @param resultState
     */
    private initOptions(filterField: FilterFieldInterface, resultState: ResultState): void {
        if (!filterField) {
            console.error(`[GW] misconfiguration: filter field »${this.fieldName}« not available. (1539552967823)`);
            return;
        }

        this.prepareOptions();

        this._dependsOnOptions = Array.from(resultState.filterOptions.values()).filter(filterOption => this._dependsOn.includes(filterOption.fieldName));

        this.filteredOptions$.next(this._dependsOnOptions.length > 0
            ? this.filterOptionsByDependencies(
                this._options,
                this._dependsOnOptions
            )
            : this._options);

        if (this.hitsOnly) {
            this.eventBus.on<Map<string, FilterOptionCount>>(ActionType.FILTERS_RECOUNT, event => this.onFilterOptionsRecounted(event.payload));
        }

        this.selectElement.valueChanges.pipe(
            takeUntil(this._destroy$),
            distinctUntilChanged((oldOptions, newOptions) => {
                return isEqual(
                    Array.isArray(oldOptions) ? sortBy(oldOptions) : oldOptions,
                    Array.isArray(newOptions) ? sortBy(newOptions) : newOptions
                );
            })
        ).subscribe((selection) => {
            selection = this.multiple
                ? selection as FilterOptionInterface[]
                : (Array.isArray(selection) ? selection : [selection as FilterOptionInterface]).filter(filter => typeof filter === "object");

            this._onSelectedOptionsChanged(selection);
        });

        this.setPreSelectedOptions(resultState);
    }

    private onResultStateChanged(resultState: ResultState): void {
        this._doNotBroadcast = true;
        this.setPreSelectedOptions(resultState);
        this._doNotBroadcast = false;
    }

    private onResultStateReady(resultState: ResultState): any {
        this._filterField = this.filterService.getFilterFieldByName(this.fieldName);
        this.initOptions(this._filterField, resultState);
    }

    private onFilterSegmentChange(resultState: ResultState): any {
        if (this.fieldName !== 'segment') {
            // redefine field with updated filter service
            this._filterField = this.filterService.getFilterFieldByName(this.fieldName);
            this.prepareOptions();

            // update available options
            this.filteredOptions$.next(this._dependsOnOptions.length > 0
                ? this.filterOptionsByDependencies(
                    this._options,
                    this._dependsOnOptions
                )
                : this._options);

            this.setPreSelectedOptions(resultState);
        }
    }

    /**
     *
     * @param resultState
     */
    private setPreSelectedOptions(resultState: ResultState): void {
        let selectedOptions: FilterOptionInterface[] = this.getSelectedOptions(resultState);

        const availableOptionsByValue = new Map();
        this._options.forEach(option => availableOptionsByValue.set(option.value, option));
        selectedOptions = selectedOptions.map(option => availableOptionsByValue.get(option.value));

        if (this.multiple === false && selectedOptions.length < 1) {
            const anyOption = this.filteredOptions$.value.find(option => option.value === null);
            selectedOptions = [anyOption];
        }

        this.selectElement.patchValue(this.multiple ? selectedOptions : first(selectedOptions), {emitEvent: true});
        this._oldSelectedOptions = selectedOptions;
        this.refreshPlaceholder();
    }

    /**
     *
     * @param resultState
     * @private
     */
    private getSelectedOptions(resultState: ResultState) {
        return Array.from(resultState.filterOptions)
            .filter(([, filterOption]: [string, FilterOptionInterface]) =>
                // check if global filter options contain given filter option
                Boolean(
                    this._options.filter(
                        (globalFilterOption: FilterOptionInterface) =>
                            globalFilterOption.fieldName === filterOption.fieldName &&
                            globalFilterOption.type === filterOption.type &&
                            globalFilterOption.value === filterOption.value
                    ).length
                )
            )
            .map(([, filterOption]: [string, FilterOptionInterface]) => filterOption);
    }

    /**
     * @api
     * @param options
     */
    private appendAnyOption(options: FilterOptionInterface[]): FilterOptionInterface[] {
        if (options.some(option => option.value === null)) {
            return options;
        }

        this._anyOption = new FilterOptionModel(this._filterField, {
            type: ComparatorType.EQ,
            field: this._filterField.key,
            label: this._translationService.translate('filter.misc.option.eq.any'),
            label_translated: this._translationService.translate('filter.misc.option.eq.any'),
            value: null,
            count: this._filterField.options.reduce<number>((acc, option) => acc < option.count ? option.count : acc, 0)
        });

        combineLatest(options.map(option => option.changed$)).subscribe(options => this.recalculatedAnyOptionCount(this._anyOption, options));

        this.recalculatedAnyOptionCount(this._anyOption, options);

        return [this._anyOption, ...options];
    }

    /**
     * only called if hintsOnly is true
     * @param payload
     */
    protected onFilterOptionsRecounted(payload: Map<string, FilterOptionCount>): void {

        if (payload.has(this.fieldName) === false) {
            return;
        }

        const selectedFilterOptions: FilterOptionInterface[] = isArray(this.selectElement.value) ? this.selectElement.value : [this.selectElement.value];

        const filteredOptions = this._options.filter(option => {
            return option.count !== 0 || selectedFilterOptions.includes(option);
        });

        this.filteredOptions$.next(this.appendAnyOption(filteredOptions));
    }

    private checkForIsDisabled(resultState: ResultState) {
        if (this._dependsOn.length < 1) {
            return;
        }

        const filterFieldNames = Array.from(resultState.filterOptions.values()).map(option => option.fieldName);
        this.isDisabled = !(this._dependsOn.every(depending => filterFieldNames.includes(depending)));
    }

    private refreshPlaceholder(): void {
        const options = Array.isArray(this.selectElement.value) ? this.selectElement.value : [this.selectElement.value];
        this.placeholder = (this.multiple && options.length === 0)
            ? this._translationService.translate('filter.misc.option.eq.any')
            : first(options).label_translated;
    }

    private recalculatedAnyOptionCount(anyOption: FilterOptionInterface, options: FilterOptionInterface[]): void {
        switch (this.comparator) {
            case ComparatorType.GTE:
                anyOption.count = Math.max(...options.map(option => option.count));
                break;
            case ComparatorType.LTE:
                anyOption.count = Math.max(...options.map(option => option.count));
                break;
            case ComparatorType.EQ:
            default:
                anyOption.count = options.reduce((acc, currentOption) => acc + currentOption.count, 0);
                break;
        }
    }

    private filterOptionsByDependencies(options: FilterOptionInterface[], addedDependingFilterOptions: FilterOptionInterface[]): FilterOptionInterface[] {
        const dependingFilterOptionValues = addedDependingFilterOptions.map(o => o.value.toString());

        return options.filter(
            o => o.value === null || dependingFilterOptionValues.some(val => o.value.toString().startsWith(val + '-') || o.value.toString() === val)
        );
    }
}
