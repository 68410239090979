import {createCustomElement} from '@angular/elements';
import {Compiler, COMPILER_OPTIONS, CompilerFactory, DoBootstrap, Inject, Injector, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {LastSeenSettingsComponent} from './component/last-seen-settings/last-seen-settings.component';
import {GwTranslationLibModule} from './gw-translation-lib/gw-translation-lib.module';
import {LastSeenComponent} from './component/last-seen/last-seen.component';
import {AppConfigService} from './gw-configuration-lib/service/app-config.service';
import {GwPipeLibModule} from './gw-pipe-lib/gw-pipe-lib.module';
import {WINDOW} from './gw-configuration-lib/reference/window-ref';
import {GwSearchLibModule} from './gw-search-lib/gw-search-lib.module';
import {FormComponent} from './component/form/form.component';
import {ResultComponent} from './component/result/result.component';
import {EntityComponent} from './component/entity/entity.component';
import {ContainerComponent} from './component/container/container.component';
import {CommonModule, registerLocaleData} from '@angular/common';
import {DetailsComponent} from './component/details/details.component';
import {ResultSettingsComponent} from './component/result-settings/result-settings.component';
import {FormSettingsComponent} from './component/form-settings/form-settings.component';
import {JitCompilerFactory} from "@angular/platform-browser-dynamic";
import {TeaserSettingsComponent} from "./component/teaser-settings/teaser-settings.component";
import {LastSeenHelperComponent} from "./component/last-seen-helper/last-seen-helper.component";
import {ResultStateService} from "./gw-search-lib/service/result-state.service";
import {RandomSettingsComponent} from "./component/random-settings/random-settings.component";
import {SliderComponent} from "./gw-pipe-lib/component/slider/slider.component";
import {ActiveFilterOptionsComponent} from "./component/active-filter-options/active-filter-options.component";
import {PaginatorComponent} from "./gw-pipe-lib/component/paginator/paginator.component";

@NgModule({
    declarations: [
        FormComponent,
        ResultComponent,
        EntityComponent,
        ContainerComponent,
        LastSeenComponent,
        DetailsComponent,
        ResultSettingsComponent,
        FormSettingsComponent,
        LastSeenSettingsComponent,
        LastSeenHelperComponent,
        TeaserSettingsComponent,
        RandomSettingsComponent,
        ActiveFilterOptionsComponent
    ],
    imports: [
        CommonModule,
        GwSearchLibModule,
        GwPipeLibModule,
        GwTranslationLibModule
    ],
    providers: [
        {
            provide: COMPILER_OPTIONS,
            useValue: {},
            multi: true
        },
        {
            provide: CompilerFactory,
            useClass: JitCompilerFactory,
            deps: [COMPILER_OPTIONS]
        },
        {
            provide: Compiler,
            useFactory: (compilerFactory: CompilerFactory) => compilerFactory.createCompiler(),
            deps: [CompilerFactory]
        },
        {
            provide: LOCALE_ID, useFactory: (appConfigService: AppConfigService) => {
                const locale = appConfigService.get('locale.id', 'de'),
                    localeData = appConfigService.get(`locale.data`, []);

                registerLocaleData(localeData, locale);
                return locale;
            }, deps: [AppConfigService]
        },
    ],
    entryComponents: [
        FormComponent,
        ResultComponent,
        EntityComponent,
        ContainerComponent,
        LastSeenComponent,
        DetailsComponent,
        ResultSettingsComponent,
        FormSettingsComponent,
        LastSeenSettingsComponent,
        LastSeenHelperComponent,
        TeaserSettingsComponent,
        RandomSettingsComponent,

    ],
    schemas: [
        NO_ERRORS_SCHEMA
    ]
})
export class GwModule implements DoBootstrap {

    // noinspection JSUnusedLocalSymbols
    constructor(
        resultStateService: ResultStateService,
        @Inject(WINDOW) protected window: Window,
        protected injector: Injector
    ) {}

    ngDoBootstrap() {
        customElements.define('gw-result', createCustomElement(ResultComponent, {injector: this.injector}));
        customElements.define('gw-entity', createCustomElement(EntityComponent, {injector: this.injector}));
        customElements.define('gw-form', createCustomElement(FormComponent, {injector: this.injector}));
        if (!customElements.get('gw-last-seen')) {
            customElements.define('gw-last-seen', createCustomElement(LastSeenComponent, {injector: this.injector}));
        }
        if (!customElements.get('gw-last-seen-helper')) {
            customElements.define('gw-last-seen-helper', createCustomElement(LastSeenHelperComponent, {injector: this.injector}));
        }
        if (!customElements.get('gw-slider')) {
            customElements.define('gw-slider', createCustomElement(SliderComponent, {injector: this.injector}));
        }
        if (!customElements.get('gw-active-filters')) {
            customElements.define('gw-active-filters', createCustomElement(ActiveFilterOptionsComponent, {injector: this.injector}));
        }
        customElements.define('gw-details', createCustomElement(DetailsComponent, {injector: this.injector}));
        customElements.define('gw-result-settings', createCustomElement(ResultSettingsComponent, {injector: this.injector}));
        customElements.define('gw-form-settings', createCustomElement(FormSettingsComponent, {injector: this.injector}));
        customElements.define('gw-last-seen-settings', createCustomElement(LastSeenSettingsComponent, {injector: this.injector}));
        customElements.define('gw-teaser-settings', createCustomElement(TeaserSettingsComponent, {injector: this.injector}));
        customElements.define('gw-random-settings', createCustomElement(RandomSettingsComponent, {injector: this.injector}));
        if (!customElements.get('gw-paginator')) {
            customElements.define('gw-paginator', createCustomElement(PaginatorComponent, {injector: this.injector}));
        }
    }
}
