import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ComparatorType} from '../../gw-search-lib/enum/comparator-type.enum';
import {StoredResultSettings} from '../../gw-search-lib/type/stored-result-settings';
import {ViewType} from '../../gw-pipe-lib/enum/view-type.enum';
import {SortingType} from '../../gw-search-lib/enum/sorting-type.enum';
import {FilterFieldInterface} from '../../gw-search-lib/interface/filter-field-interface';
import {FilterOptionInterface} from '../../gw-search-lib/interface/filter-option-interface';
import {FilterOptionModel} from '../../gw-search-lib/model/filter-option.model';
import {FilterService} from '../../gw-search-lib/service/filter.service';
import {BehaviorSubject, Subject} from "rxjs";
import {IResultFilterSetting} from "../../gw-pipe-lib/component/result-filter-setting/result-filter-setting-interface";
import {filter, takeUntil} from "rxjs/operators";
import {ISortingSetting} from "../../interface/sorting-setting.interface";

@Component({
    //selector: 'gw-teaser-settings',
    templateUrl: './teaser-settings.component.html',
    encapsulation: ViewEncapsulation.Emulated
})
export class TeaserSettingsComponent implements OnInit, OnDestroy {

    @Input('gw-name')
    public name: string;

    @Input('gw-id')
    public id: string;

    @Input('gw-settings')
    public set settings(settings: string) {
        this._settings = Object.assign(this._settings, JSON.parse(settings));
    };

    private _settings: StoredResultSettings = {
        filters: [],
        sorting: {
            field: 'price_gross',
            order: SortingType.ASC
        },
        perPage: 3,
        scrollType: "perpage",
        isGlobal: false,
        startPos: 0,
        viewType: ViewType.BOX
    };

    private _destroy$ = new Subject<void>();

    private _filterSettings: IResultFilterSetting[] = [];

    public set filterSettings(filterSettings: IResultFilterSetting[]) {
        this._filterSettings = filterSettings;
        this._settings.isGlobal = false;
        this._settings.filters = filterSettings
            .filter(filterSetting => !!filterSetting.filterValue && !!filterSetting.filterField && !!filterSetting.comparator)
            .map(filterSetting => {
                return {
                    field: filterSetting.filterValue.fieldName,
                    comparator: filterSetting.filterValue.type.toUpperCase() as ComparatorType,
                    value: filterSetting.filterValue.value
                }
            });

        this.settingsAsJson = JSON.stringify(this._settings);
    }

    public get filterSettings(): IResultFilterSetting[] {
        return this._filterSettings;
    }

    public set sortingSetting(sortingSetting: ISortingSetting) {
        this._settings.sorting = sortingSetting;
        this.settingsAsJson = JSON.stringify(this._settings);
    };

    public get sortingSetting(): ISortingSetting {
        return this._settings.sorting || {field: 'price_gross', order: SortingType.ASC};
    };

    public set viewTypeSetting(viewType: ViewType) {
        this._settings.viewType = viewType;
        this.settingsAsJson = JSON.stringify(this._settings);
    };

    public get viewTypeSetting(): ViewType {
        return this._settings.viewType || ViewType.BOX;
    }

    public set perPageSetting(perPage: number) {
        this._settings.perPage = perPage;
        this.settingsAsJson = JSON.stringify(this._settings);
    }

    public get perPageSetting(): number {
        return this._settings.perPage || 4;
    }

    public settingsAsJson: string = '';

    public availableFilterFields$ = new BehaviorSubject<FilterFieldInterface[]>([]);

    public areForAllFilterSettingsDefined: boolean = true;

    constructor(
        protected filterService: FilterService
    ) {}

    ngOnInit() {
        this.filterService.filterConfiguration$.pipe(
            takeUntil(this._destroy$)
        ).subscribe(
            filterFields => this.availableFilterFields$.next(Array.from(filterFields.values()))
        );
        if (!!this._settings) {
            this.availableFilterFields$
                .pipe(
                    takeUntil(this._destroy$),
                    filter(fields => fields.length > 0)
                )
                .subscribe(() => {
                    // set filters
                    this._settings.filters = this._settings.filters || [];
                    this.onFilterConfigurationLoaded(
                        this._settings.filters.map(filter => this.filterService.getOptionByFieldName(filter.field, filter.value, filter.comparator)),
                        this._settings.sorting || { field: 'price_gross', order: SortingType.ASC }
                    );
                });
        }
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    /**
     *
     * @param removingFilterSetting
     */
    public onClickRemove(removingFilterSetting: IResultFilterSetting) {
        const index = this.filterSettings.indexOf(removingFilterSetting);
        if (index > -1) {
            this.filterSettings.splice(index, 1);
            this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
        }
    }

    /**
     *
     */
    public onClickAdd() {
        this.filterSettings = [...this.filterSettings, {filterField: null, filterValue: null, comparator: ComparatorType.EQ}];
        this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
    }

    /**
     *
     * @param filterValues
     * @param sorting
     */
    protected onFilterConfigurationLoaded(filterValues: FilterOptionInterface[], sorting: ISortingSetting) {
        filterValues.forEach(filterValue => {
            filterValue.select = true;
            this.   filterSettings = [...this.filterSettings, {
                filterField: (<FilterOptionModel>filterValue).field,
                filterValue: filterValue,
                comparator: filterValue.type
            }];
        });
        if(!this.viewTypeSetting) {
            this.viewTypeSetting = ViewType.BOX;
        }
        if(!this.perPageSetting) {
            this.perPageSetting = 3;
        }
        this.sortingSetting = {field: sorting.field, order: sorting.order};
        this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
    }

    /**
     *
     */
    protected checkForAllFilterSettingsDefined(): boolean {
        for (const filterSetting of this.filterSettings) {
            if (!(filterSetting.filterField && filterSetting.filterValue && filterSetting.comparator)) {
                return false;
            }
        }
        this.filterSettings = [...this.filterSettings];

        return true;
    }

    public onFilterSettingChanged(filterSetting: IResultFilterSetting) {
        this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
    }
}
