import {Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation, Input} from '@angular/core';
import {FilterService} from '../../gw-search-lib/service/filter.service';
import {ToolBoxService} from '../../gw-search-lib/service/tool-box.service';
import {first} from 'lodash';
import {ViewContainerRef} from '@angular/core';
import {GwFormTemplate} from '../../interface/gw-form-template';
import {ComparatorType} from "../../gw-search-lib/enum/comparator-type.enum";
import {FilterFieldConfiguration} from "../../gw-search-lib/type/filter-field-configuration";
import {ActionType} from "../../gw-pipe-lib/enum/action-type.enum";
import {ChangeEventContext} from "../../gw-pipe-lib/types";
import {EventBusService} from "../../gw-search-lib/service/event-bus.service";
import {FilterOptionInterface} from "../../gw-search-lib/interface/filter-option-interface";
import {ResultState} from "../../gw-search-lib/interface/result-state";

@Component({
    selector: 'gw-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FormComponent implements OnInit {

    private _additionalFilterInput: { field: string, comparator: ComparatorType, value: any }[] = [];
    @Input('gw-filters')
    public set additionalFilterInput(additionalFilterInput: string) {
        if (typeof additionalFilterInput === 'string' && additionalFilterInput.length > 0) {
            this._additionalFilterInput = JSON.parse(additionalFilterInput || '[]');
        }
    }

    public get additionalFilterInput(): string {
        return JSON.stringify(this._additionalFilterInput);
    }

    @ViewChild('tmpl')
    public tmpl: TemplateRef<any>;

    public template: GwFormTemplate = {item: null};

    public total: number = 0;

    public hasFilter: (filterName: string, filterValue?: any) => boolean = (name: string, value?: any) => {
        if (Array.from(this.filterService.selectedFilterOptions.getValue().values()).length < 1) {
            return false;
        }

        return Array.from(this.filterService.selectedFilterOptions.getValue().values()).some(o => {
            const has = o.fieldName === name;

            if (!has || [undefined, null].includes(value)) {
                return has;
            }

            return o.value === value;
        });
    };

    constructor(
        protected eventBus: EventBusService,
        protected filterService: FilterService,
        protected toolboxService: ToolBoxService,
        protected vcRef: ViewContainerRef
    ) {
        const projectableNodes = <Node[]>first(this.vcRef["_hostTNode"].projection);
        this.template = this.toolboxService.querySelectorTemplate<GwFormTemplate>(projectableNodes);
    }

    ngOnInit() {
        /*this.eventBus.on<FilterFieldConfiguration>(ActionType.FILTER_CONFIGURATION_READY, () => {
                console.info('FILTER_CONFIGURATION_READY in form component');
                //console.log("broadcast " + ActionType.FILTERS_CHANGE + " in form component");
                this.eventBus.broadcast<ChangeEventContext>(this, ActionType.FILTERS_CHANGE, {
                    remove: [],
                    add: this.getLocalFilterOptions()
                })
            }
        );*/

        this.filterService.totalChanged$.subscribe(total => this.total = total);
    }

    private getLocalFilterOptions(): FilterOptionInterface[] {
        console.log("getLocalFilterOptions in form component");
        try {
            return this._additionalFilterInput.map(localFilterOption => this.filterService.getOptionByFieldName(localFilterOption.field, localFilterOption.value, localFilterOption.comparator))
                .filter(localFilterOption => !!localFilterOption)
                .map(filterOption => {
                    filterOption.fixed = true;
                    return filterOption;
                });
        } catch (e) {
        }
    }
}
