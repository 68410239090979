import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatCheckbox} from '@angular/material/checkbox';
import {ActionType} from 'projects/gw-web-components/src/app/gw-pipe-lib/enum/action-type.enum';
import {
    FilterOptionInterface
} from 'projects/gw-web-components/src/app/gw-search-lib/interface/filter-option-interface';
import {ResultState} from 'projects/gw-web-components/src/app/gw-search-lib/interface/result-state';
import {EventBusService} from 'projects/gw-web-components/src/app/gw-search-lib/service/event-bus.service';
import {FilterFieldInterface} from '../../../gw-search-lib/interface/filter-field-interface';
import {FilterService} from '../../../gw-search-lib/service/filter.service';
import {ToolBoxService} from '../../../gw-search-lib/service/tool-box.service';
import {Helpers} from "../../helpers";
import {ChangeEventContext} from "../../types";
import {ComparatorType} from "../../../gw-search-lib/enum/comparator-type.enum";

@Component({
    selector: 'gw-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    //encapsulation: ViewEncapsulation.None
})
export class CheckboxComponent implements OnInit {

    private _filterOption: FilterOptionInterface;

    private _isSingleSelect: boolean = false;

    private _isPreSelected: boolean = false;

    private _dependsOn: Map<string, boolean | string[]> = new Map<string, boolean | string[]>();

    private _label: HTMLElement = null;

    @ViewChild('checkbox') public matCheckbox: MatCheckbox;

    @Input('gw-id') public id: string = '';

    @Input('gw-field') public fieldName: string = '';

    @Input('gw-class') public class: string = '';

    @Input('gw-single-select')
    public set multiSelect(singleSelect: string | boolean) {
        if (typeof singleSelect === "string") {
            singleSelect = ["true", "on", "1"].includes(singleSelect);
        }

        this._isSingleSelect = singleSelect;
    }

    @Input('gw-selected')
    public set isPreSelected(preSelected: string | boolean) {
        if (typeof preSelected === "string") {
            preSelected = ["true", "on", "1"].includes(preSelected);
        }

        this._isPreSelected = preSelected;
    }

    public get isPreSelected(): boolean {
        return this._isPreSelected;
    }

    @Input('gw-value')
    public set value(value: any) {
        this._value = value;
    }

    public get value(): any {
        return this._value;
    }

    @Input('gw-depends-on')
    public set dependsOn(dependsOn: any) {
        if (typeof dependsOn !== 'string' || dependsOn.length < 1) {
            return;
        }

        this._dependsOn = new Map(dependsOn.split(',').map(d => {
            d = d.trim();
            if (d.includes(':')) {
                d = d.replace(':', ':|') + '|';
            }

            return [d, false];
        }));
    }

    public checkboxElement = new FormControl(false);

    protected _filterField: FilterFieldInterface;

    protected _value: any;

    constructor(
        protected filterService: FilterService,
        protected eventBus: EventBusService
    ) {
    }

    public ngOnInit() {
        if (Helpers.isFilledString(this.fieldName)) {
            this._bindToLabel();
        }
        this.eventBus.on<ResultState>(ActionType.RESULT_STATE_READY, event => this._onResultStateReady(event.payload));
    }

    private _onResultStateReady(resultState: ResultState) {
        this.eventBus.on<ChangeEventContext>(ActionType.FILTERS_CHANGE, filterChangeEvent => {
            if (filterChangeEvent.sender !== this) {
                this._onFiltersChanged(filterChangeEvent.payload);
            }

            if (typeof filterChangeEvent.sender === "string" && filterChangeEvent.sender === "ResultComponent") {
                if (filterChangeEvent.payload.add.findIndex(o => o.fieldName === this.fieldName && o.value === this.value) > -1) {
                    this._disable(true);
                }

                if (this._isSingleSelect && filterChangeEvent.payload.add.some(o => o.fieldName === this.fieldName)) {
                    // disable if any option of filter is active
                    this._disable(true);
                }
            }
        }, false, true);

        this._onGettingFilterField(
            this.filterService.getFilterFieldByName(this.fieldName),
            resultState
        );

        this.checkboxElement.valueChanges.subscribe(
            newValue => this._onSelectionChange(newValue)
        );

        this._checkForPreSelect(resultState);
    }

    /**
     *
     * @param newValue
     */
    private _onSelectionChange(newValue: boolean): void {
        const addingFilterOptions = [];
        const removingFilterOptions = [];

        if (this._filterOption.select !== newValue) {
            this._filterOption.select = newValue
        }

        const selectedFilterOptions = Array.from(this.filterService.selectedFilterOptions.getValue().values());
        if (newValue === true) {
            this._label?.classList.add('gw-checked');

            if (selectedFilterOptions.indexOf(this._filterOption) === -1) {
                addingFilterOptions.push(this._filterOption);

                if (this._isSingleSelect && selectedFilterOptions.findIndex(o => o === this._filterOption) === -1) {
                    // removing other options of filter
                    const otherFilterOptionsOfThisFilter = selectedFilterOptions.filter(o => o.fieldName === this.fieldName && o !== this._filterOption);
                    removingFilterOptions.push(...otherFilterOptionsOfThisFilter);
                }
            }
        } else {
            this._label?.classList.remove('gw-checked');
            if (selectedFilterOptions.indexOf(this._filterOption) !== -1) {
                removingFilterOptions.push(this._filterOption);
            }
        }

        if (addingFilterOptions.length > 0 || removingFilterOptions.length > 0) {
            this.eventBus.broadcast<ChangeEventContext>(this, ActionType.FILTERS_CHANGE, {
                add: addingFilterOptions,
                remove: removingFilterOptions
            });
        }
    }

    /**
     * check if checkbox should be preselected
     * @param resultState
     */
    private _checkForPreSelect(resultState: ResultState): void {
        if (this._isPreSelected) {
            // set only if filter options do not contain current field
            if (!Array.from(resultState.filterOptions.values()).some(o => o.fieldName === this.fieldName)) {
                this.checkboxElement.setValue(true);
                this._filterOption.select = true;
                this._label?.classList.add('gw-checked');
            }
        }
    }

    /**
     *
     * @param filterField
     * @param resultState
     */
    private _onGettingFilterField(filterField: FilterFieldInterface, resultState: ResultState) {
        this._filterField = filterField;
        this.value = ToolBoxService.transformInput(this.value, filterField.optionValueType);
        const option = this.filterService.getOption(filterField, this.value);
        if (!option) {
            throw Error(`no option found for field ${filterField.key} and value ${this.value}`);
        }

        this._filterOption = option;

        const addedFilters = Array.from(resultState.filterOptions.values());

        if (addedFilters.some(o => o === this._filterOption)) {
            this.checkboxElement.setValue(true);
            this._filterOption.select = true;
            this._label?.classList.add('gw-checked');
        }

        if (this._dependsOn.size > 0) {
            this._checkDependencies();
        }
    }

    private _onFiltersChanged(payload: ChangeEventContext) {
        if (this._dependsOn.size > 0) {
            this._checkDependencies();
        }

        if (this.checkboxElement.disabled) {
            return;
        }

        if (payload.add.some(o => o === this._filterOption)) {
            this.checkboxElement.patchValue(true);
            this._filterOption.select = true;
            return;
        }

        if (this._filterOption.selected && this._isSingleSelect) {
            const selectingOtherOptionOfFilter = payload.add.findIndex(o => o.field === this._filterField) > -1;
            if (selectingOtherOptionOfFilter) {
                this.checkboxElement.patchValue(false);
                this._filterOption.select = false;
                return;
            }
        }

        if (this.checkboxElement.value === true && payload.remove.some(o => this._filterOption === o)) {
            this.checkboxElement.patchValue(false);
            this._filterOption.select = false;
        }
    }

    private _bindToLabel() {
        this._label = document.body.querySelector(`[for=${this.id}]`) as HTMLElement;
        if (!(this._label instanceof HTMLElement)) {
            return;
        }

        this._label.addEventListener('click', event => this._onLabelClick(event));

        if (this.checkboxElement.disabled) {
            // label.style.cursor = 'pointer';
            this._label.classList.add('gw-disabled');
        }

        if (this.checkboxElement.value === true) {
            this._label.classList.add('gw-checked');
        }
    }

    private _onLabelClick(event: MouseEvent) {
        if (this.checkboxElement.disabled) {
            return;
        }

        if (!(event.target as Element).classList.contains('mat-checkbox-inner-container')) {
            this.checkboxElement.setValue(!this.checkboxElement.value);
        }
    }

    private _disable(withoutChangeState = false): void {
        this._label?.classList.add('gw-disabled');
        if (this.checkboxElement.enabled) {
            this.checkboxElement.disable();
        }
        if (withoutChangeState === true) {
            return void 0;
        }
        if (this.checkboxElement.value === true) {
            this.checkboxElement.setValue(false);
        }
    }

    private _enable() {
        this._label?.classList.remove('gw-disabled');
        if (this.checkboxElement.disabled) {
            this.checkboxElement.enable();
        }
    }

    private _checkDependencies() {
        const selectedFilterOptions = Array.from(this.filterService.selectedFilterOptions.getValue().values());
        const dependencyTests = Array.from(this._dependsOn.keys()).map(
            dependency => selectedFilterOptions.some(
                o => (new RegExp(`^${o.fieldName}$|^${o.fieldName}:.*\\|${o.value.toString()}\\|.*$`)).test(dependency)
            )
        );

        dependencyTests.every(d => d)
            ? this._enable()
            : this._disable();
    }
}
