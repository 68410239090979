import { Component, ElementRef, Inject, Input } from '@angular/core';
import { ViewportService } from '../../service/viewport.service';
import { ActionType } from '../../enum/action-type.enum';
import { ImageComponent } from '../image/image.component';
import { EventBusService } from '../../../gw-search-lib/service/event-bus.service';
import { AppConfigService } from '../../../gw-configuration-lib/service/app-config.service';
import { WINDOW } from '../../../gw-configuration-lib/reference/window-ref';
import {ImageStageEventPayload} from '../../interface/image-stage-event-payload';

@Component({
  selector: 'gw-image-stage',
  templateUrl: './image-stage.component.html',
  styleUrls: ['./image-stage.component.scss']
})
export class ImageStageComponent extends ImageComponent {

  @Input('gw-name') public name: string;

  constructor(
    protected appConfigService: AppConfigService,
    protected elementRef: ElementRef,
    protected viewportService: ViewportService,
    @Inject(WINDOW) protected windowRef: Window,
    protected eventBusService: EventBusService
  ) {
    super(appConfigService, elementRef, viewportService, windowRef);
    this.eventBusService.on<ImageStageEventPayload>(ActionType.IMAGE_STAGE_SELECT, payload => {
      this.src = `${this.pathToImageThumbnails}/` + payload.payload.source;
    });
  }

}
