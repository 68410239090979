import {Component, OnInit, Input} from '@angular/core';
import {AppConfigService} from 'projects/gw-web-components/src/app/gw-configuration-lib/service/app-config.service';
import {TranslationService} from 'projects/gw-web-components/src/app/gw-translation-lib/service/translation.service';
import {StoredFormSettings} from '../../gw-search-lib/type/stored-form-settings';
import {FormControl} from '@angular/forms';

@Component({
    //selector: 'gw-form-settings',
    templateUrl: './form-settings.component.html',
    styleUrls: ['./form-settings.component.scss']
})
export class FormSettingsComponent implements OnInit {

    public availableForms: { key: string, label: string }[];

    public control: FormControl = new FormControl();

    public settings: StoredFormSettings = {
        selectedForm: ''
    };

    public settingsAsString: string = '';

    @Input('gw-id')
    public id: string;

    @Input('gw-name')
    public name: string;

    @Input('gw-settings')
    public set gwSettings(settings: string) {
        this.settings = JSON.parse(settings);
    };

    public placeholder: string = this.translationService.translate('formsettings.trigger.placeholder');

    constructor(
        protected appConfigService: AppConfigService,
        protected translationService: TranslationService
    ) {
    }

    ngOnInit() {
        this.availableForms = this.appConfigService.get('form.availableForms', []).map((form: { key, label }) => {
            form.label = this.translationService.translate(form.label);
            return form;
        });
        if (this.settings.selectedForm && this.availableForms.map(form => form.key).includes(this.settings.selectedForm)) {
            const selectedForm = this.availableForms.find(form => form.key === this.settings.selectedForm);
            this.control.patchValue(selectedForm, {emitEvent: false});
        }

        this.control.valueChanges.subscribe(newValue => {
            this.settings.selectedForm = newValue.key;
            this.settingsAsString = JSON.stringify(this.settings);
        });
    }
}
