import { Pipe, PipeTransform } from '@angular/core';
import { GwPaginatorPage } from '../../type/gw-paginator-page';

@Pipe({
  name: 'paginate'
})
export class PaginatePipe implements PipeTransform {

  transform(pages: GwPaginatorPage[], currentPage?: GwPaginatorPage, count: number = 2): any {
    const pseudoGwPaginatorPage: GwPaginatorPage = {
      isFirst:    false,
      isLast:     false,
      selected:   false,
      selectable: false,
      size:       -1,
      startPos:   -1,
      pageNumber: -1,
      label:      "…"
    };

    let navigationItems: GwPaginatorPage[] = [];
    if(!pages || !currentPage) {
      return navigationItems;
    }

    const currentPageNumber = currentPage.pageNumber;
    let leftPageLimit = currentPageNumber - count,
        rightPageLimit = currentPageNumber + count;

    //let buffer = 0;
    if(leftPageLimit < 0) {
      //rightPageLimit = currentPageNumber + count + leftPageLimit * -1;
      leftPageLimit = 0;
    }

    if(rightPageLimit >= pages.length) {
      rightPageLimit = pages.length-1;
    }

    navigationItems = pages.filter(page => {
      return (
        page.isFirst ||
        page.isLast ||
        (
          page.pageNumber >= leftPageLimit &&
          page.pageNumber <= rightPageLimit
        )
      )
    });

    if(navigationItems.length > 2 && navigationItems[1].pageNumber !== 1) {
      // add pseudo GwPaginatorPage
      navigationItems = [navigationItems[0], pseudoGwPaginatorPage,...navigationItems.slice(1)]
    }

    if(navigationItems.length > 2 && navigationItems[navigationItems.length-2].pageNumber !== pages.length-2) {
      // add pseudo GwPaginatorPage
      navigationItems = [...navigationItems.slice(0, navigationItems.length-1), pseudoGwPaginatorPage, navigationItems[navigationItems.length-1]]
    }
    return navigationItems;
  }

}
