import { SortingType } from '../app/gw-search-lib/enum/sorting-type.enum';

export const environment = {
  production: true,
  appPrefix: 'gw',
  result: {
    minHeight: 220,
  },
  search: {
    defaults: {
      start: 0,
      limit: 20,
      sorting: {
        field: 'price_gross',
        order: SortingType.ASC
      }
    }
  }
};
