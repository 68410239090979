import { Injectable } from '@angular/core';
import { FilterOptionInterface } from '../interface/filter-option-interface';

@Injectable({
  providedIn: 'root'
})
export class HashService {

  constructor() { }

  protected static rotateLeft(lValue, iShiftBits): number {
    return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
  };

  protected static addUnsigned(lX, lY) {
    let lX4, lY4, lX8, lY8, lResult;
    lX8 = (lX & 0x80000000);
    lY8 = (lY & 0x80000000);
    lX4 = (lX & 0x40000000);
    lY4 = (lY & 0x40000000);
    lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
    if (lX4 & lY4) {
      return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
    }
    if (lX4 | lY4) {
      if (lResult & 0x40000000) {
        return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
      } else {
        return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
      }
    } else {
      return (lResult ^ lX8 ^ lY8);
    }
  };

  protected static _F(x, y, z) {
    return (x & y) | ((~x) & z);
  };

  protected static _G(x, y, z) {
    return (x & z) | (y & (~z));
  };

  protected static _H(x, y, z) {
    return (x ^ y ^ z);
  };

  protected static _I(x, y, z) {
    return (y ^ (x | (~z)));
  };

  protected static _FF(a, b, c, d, x, s, ac) {
    a = HashService.addUnsigned(a, HashService.addUnsigned(HashService.addUnsigned(HashService._F(b, c, d), x), ac));
    return HashService.addUnsigned(HashService.rotateLeft(a, s), b);
  };

  protected static _GG(a, b, c, d, x, s, ac) {
    a = HashService.addUnsigned(a, HashService.addUnsigned(HashService.addUnsigned(HashService._G(b, c, d), x), ac));
    return HashService.addUnsigned(HashService.rotateLeft(a, s), b);
  };

  protected static _HH(a, b, c, d, x, s, ac) {
    a = HashService.addUnsigned(a, HashService.addUnsigned(HashService.addUnsigned(HashService._H(b, c, d), x), ac));
    return HashService.addUnsigned(HashService.rotateLeft(a, s), b);
  };

  protected static _II(a, b, c, d, x, s, ac) {
    a = HashService.addUnsigned(a, HashService.addUnsigned(HashService.addUnsigned(HashService._I(b, c, d), x), ac));
    return HashService.addUnsigned(HashService.rotateLeft(a, s), b);
  };

  /**
   *
   * @param str
   */
  protected static convertToWordArray(str): number[] {
    const lMessageLength = str.length;

    const lNumberOfWords_temp1 = lMessageLength + 8,
          lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64,
          lNumberOfWords       = (lNumberOfWords_temp2 + 1) * 16,
          lWordArray           = new Array<number>(lNumberOfWords - 1);

    let lWordCount,
        lBytePosition = 0,
        lByteCount    = 0;

    while (lByteCount < lMessageLength) {
      lWordCount = (lByteCount - (lByteCount % 4)) / 4;
      lBytePosition = (lByteCount % 4) * 8;
      lWordArray[lWordCount] = (lWordArray[lWordCount] | (str.charCodeAt(lByteCount) << lBytePosition));
      lByteCount++;
    }

    lWordCount = (lByteCount - (lByteCount % 4)) / 4;
    lBytePosition = (lByteCount % 4) * 8;
    lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
    lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
    lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;

    return lWordArray;
  };

  /**
   *
   * @param lValue
   */
  protected static wordToHex(lValue) {
    let wordToHexValue      = '',
        wordToHexValue_temp = '',
        lByte, lCount;
    for (lCount = 0; lCount <= 3; lCount++) {
      lByte = (lValue >>> (lCount * 8)) & 255;
      wordToHexValue_temp = '0' + lByte.toString(16);
      wordToHexValue = wordToHexValue + wordToHexValue_temp.substr(wordToHexValue_temp.length - 2, 2);
    }
    return wordToHexValue;
  }

  /**
   *
   * @param string
   */
  protected static utf8Encode(string): string {
    string = (string + '').replace(/\r\n/g, '\n').replace(/\r/g, '\n');

    let utfText = '', start, end;
    start = end = 0;

    const stringLength = string.length;

    for(let n = 0; n < stringLength; n++) {
      let c1 = string.charCodeAt(n),
          enc = null;

      if(c1 < 128) {
        end++;
      } else if ((c1 > 127) && (c1 < 2048)) {
        enc = String.fromCharCode((c1 >> 6) | 192) + String.fromCharCode((c1 & 63) | 128);
      } else {
        enc = String.fromCharCode((c1 >> 12) | 224) + String.fromCharCode(((c1 >> 6) & 63) | 128) + String.fromCharCode((c1 & 63) | 128);
      }

      if (enc !== null) {
        if (end > start) {
          utfText += string.substring(start, end);
        }
        utfText += enc;
        start = end = n + 1;
      }
    }

    if (end > start) {
      utfText += string.substring(start, string.length);
    }

    return utfText;
  }

  /**
   *
   * @param filterOption
   */
  public static md5ForFilterOption(filterOption: FilterOptionInterface): string {
    if(!filterOption) {
      return null;
    }
    return HashService.md5(filterOption.asJsonString());
  }

  /**
   *
   * @param str
   */
  public static md5(str: string): string {
    let xl,
        x                                  = [],
        k, AA, BB, CC, DD, a, b, c, d, S11 = 7,
        S12                                = 12,
        S13                                = 17,
        S14                                = 22,
        S21                                = 5,
        S22                                = 9,
        S23                                = 14,
        S24                                = 20,
        S31                                = 4,
        S32                                = 11,
        S33                                = 16,
        S34                                = 23,
        S41                                = 6,
        S42                                = 10,
        S43                                = 15,
        S44                                = 21;

    x = HashService.convertToWordArray(HashService.utf8Encode(str));
    a = 0x67452301;
    b = 0xEFCDAB89;
    c = 0x98BADCFE;
    d = 0x10325476;

    xl = x.length;
    for (k = 0; k < xl; k += 16) {
      AA = a;
      BB = b;
      CC = c;
      DD = d;
      a = HashService._FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
      d = HashService._FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
      c = HashService._FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
      b = HashService._FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
      a = HashService._FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
      d = HashService._FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
      c = HashService._FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
      b = HashService._FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
      a = HashService._FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
      d = HashService._FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
      c = HashService._FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
      b = HashService._FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
      a = HashService._FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
      d = HashService._FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
      c = HashService._FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
      b = HashService._FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
      a = HashService._GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
      d = HashService._GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
      c = HashService._GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
      b = HashService._GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
      a = HashService._GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
      d = HashService._GG(d, a, b, c, x[k + 10], S22, 0x2441453);
      c = HashService._GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
      b = HashService._GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
      a = HashService._GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
      d = HashService._GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
      c = HashService._GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
      b = HashService._GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
      a = HashService._GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
      d = HashService._GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
      c = HashService._GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
      b = HashService._GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
      a = HashService._HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
      d = HashService._HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
      c = HashService._HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
      b = HashService._HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
      a = HashService._HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
      d = HashService._HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
      c = HashService._HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
      b = HashService._HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
      a = HashService._HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
      d = HashService._HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
      c = HashService._HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
      b = HashService._HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
      a = HashService._HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
      d = HashService._HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
      c = HashService._HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
      b = HashService._HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
      a = HashService._II(a, b, c, d, x[k + 0], S41, 0xF4292244);
      d = HashService._II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
      c = HashService._II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
      b = HashService._II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
      a = HashService._II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
      d = HashService._II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
      c = HashService._II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
      b = HashService._II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
      a = HashService._II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
      d = HashService._II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
      c = HashService._II(c, d, a, b, x[k + 6], S43, 0xA3014314);
      b = HashService._II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
      a = HashService._II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
      d = HashService._II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
      c = HashService._II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
      b = HashService._II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
      a = HashService.addUnsigned(a, AA);
      b = HashService.addUnsigned(b, BB);
      c = HashService.addUnsigned(c, CC);
      d = HashService.addUnsigned(d, DD);
    }

    let temp = HashService.wordToHex(a) + HashService.wordToHex(b) + HashService.wordToHex(c) + HashService.wordToHex(d);

    return temp.toLowerCase();
  }
}
