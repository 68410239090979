import { Component, OnInit, Input, Output } from '@angular/core';
import { AppConfigService } from '../../../gw-configuration-lib/service/app-config.service';
import { FormControl } from '@angular/forms';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'gw-result-pagination-per-page-setting',
  templateUrl: './result-pagination-per-page-setting.component.html',
  styleUrls: ['./result-pagination-per-page-setting.component.scss']
})
export class ResultPaginationPerPageSettingComponent implements OnInit {

  static readonly INFINITE = 9999;
  static readonly INFINITE_LABEL = 'unendlich';

  @Input() protected perPageSetting: number;
  @Output() public perPageSettingChange: EventEmitter<number> = new EventEmitter<number>();

  public availablePerPages: number[] = [];
  public paginationPerPageCtl: FormControl = new FormControl();
  public get placeholder(): string {
    return (this.paginationPerPageCtl && typeof this.paginationPerPageCtl.value === 'number')
        ? (this.paginationPerPageCtl.value === ResultPaginationPerPageSettingComponent.INFINITE
            ? ResultPaginationPerPageSettingComponent.INFINITE_LABEL
            : this.paginationPerPageCtl.value.toString()
        )
        : "Bitte Anzahl Fzg. per Seite wählen …"
  }

  constructor(
    protected appConfigService: AppConfigService
  ) { }

  ngOnInit() {
    this.availablePerPages = this.appConfigService.get('result.perPageSelector.availableQuantities', [4,8,12,16]);
    if(this.perPageSetting) {
      this.paginationPerPageCtl.patchValue(this.perPageSetting, {emitEvent: false});
    }
    this.paginationPerPageCtl.valueChanges.subscribe(newValue => {
      this.perPageSettingChange.emit(newValue);
    });
  }

}
