import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Compiler,
    Component,
    ComponentFactory,
    ComponentRef,
    Inject,
    Injector,
    Input,
    NgModuleRef,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import has from 'lodash-es/has';
import {AppConfigService} from 'projects/gw-web-components/src/app/gw-configuration-lib/service/app-config.service';
import {DynamicComponent} from 'projects/gw-web-components/src/app/gw-search-lib/interface/dynamic-component';
import {WINDOW} from '../../gw-configuration-lib/reference/window-ref';
import first from 'lodash-es/first';
import {TypeBuildService} from "../../service/type-build.service";

@Component({
    selector: 'gw-container',
    template: '<ng-container #dynamicContainer></ng-container>',
    styleUrls: ['./container.component.css']
})
export class ContainerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @ViewChild('dynamicContainer', {read: ViewContainerRef, static: false}) _container: ViewContainerRef;

    private _templateNode: { selector?: string, template: string, host: { [key: string]: string }, changeDetection: ChangeDetectionStrategy };
    private _data: { [p: string]: any };

    @Input('template')
    public set templateNode(templateNode: Element | Element[]) {

        if (Array.isArray(templateNode)) {
            if (templateNode.length === 1) {
                const element = first(templateNode);
                this._templateNode = {
                    selector: element.nodeName,
                    template: element.outerHTML,
                    host: {}, //this.getAttributes(element),
                    changeDetection: ChangeDetectionStrategy.Default
                };
            } else {
                const wrapper = this._window.document.createElement('div');
                wrapper.append(...templateNode);
                this._templateNode = {
                    selector: wrapper.nodeName,
                    template: wrapper.innerHTML,
                    host: {},
                    changeDetection: ChangeDetectionStrategy.Default
                }
            }
        } else {
            if(!templateNode) {
                templateNode = document.createElement('div');
            }
            this._templateNode = {
                selector: templateNode.nodeName,
                template: templateNode.outerHTML,
                host: this.getAttributes(templateNode),
                changeDetection: ChangeDetectionStrategy.Default
            };
        }
    }

    @Input('dataContext')
    public set data(data: { [key: string]: any }) {
        this._data = data;
    }
    public get data(): { [key: string]: any } {
        return this._data;
    }

    private cmpRef: ComponentRef<any>;

    constructor(
        private _compiler: Compiler,
        private _injector: Injector,
        private _moduleRef: NgModuleRef<any>,
        private _appConfigService: AppConfigService,
        private _typeBuildService: TypeBuildService,
        @Inject(WINDOW) private _window: Window
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.cmpRef) {
            if (has(changes, 'data')) {
                Object.assign(this.cmpRef.instance, changes['data'].currentValue);
            }
            if (has(changes, 'templateNode')) {
                this.cmpRef.destroy();
                this.buildComponent();
            }
        }
    }

    ngOnDestroy() {
        if (this.cmpRef) {
            this.cmpRef.destroy();
        }
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        if (!this._templateNode || Array.isArray(this._templateNode) && this._templateNode.length < 1) {
            return;
        }
        this.buildComponent();
    }

    protected buildComponent(): void {
        const cmpDef: Component = this._templateNode;

        this._typeBuildService
            .createComponentFactory(cmpDef)
            .then((factory: ComponentFactory<DynamicComponent>) => {
                // Target will instantiate and inject component (we'll keep reference to it)
                this.cmpRef = this._container.createComponent(factory);

                // let's inject @Inputs to component instance
                Object.entries<{ [key: string]: any }>(this.data).forEach(([key, value]) => {
                    Object.defineProperty(
                        this.cmpRef.instance,
                        key,
                        {
                            set: (val) => this['_' + key] = val,
                            get: () => this['_' + key],
                        });
                        this.cmpRef.instance[key] = value;
                });
                this.cmpRef.instance;
                this._container.insert(this.cmpRef.hostView);
            });
    }

    /**
     * @param templateNode
     */
    protected getAttributes(templateNode: Element | Element[]): { [key: string]: string } {
        if (Array.isArray(templateNode)) {
            return {};
        }

        const attributes = {};
        Array.from((<Element>templateNode).attributes).forEach(attribute => attributes[attribute.name] = attribute.value);

        return attributes;
    }
}
