import { Pipe, Inject, LOCALE_ID } from '@angular/core';
import { formatCurrency } from '@angular/common';
import { PipeTransform } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
import { AppConfigService } from '../../gw-configuration-lib/service/app-config.service';

@Pipe({
  name: 'waehrung'
})
export class WaehrungPipe implements PipeTransform {
  private readonly _locale: string;
  private readonly _currencyCode: string;

  constructor(@Inject(LOCALE_ID) private localeId, private appConfigService: AppConfigService) {
    this._locale = localeId;
    this._currencyCode = this.appConfigService.get(`locale.currency.code`, '€');
  }

  /**
   * transforms value to formatted value with currency symbol
   * @param value
   * @param currencyCode
   * @param display
   * @param digitsInfo
   * @param locale
   */
  public transform(value: any, currencyCode?: string, display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol', digitsInfo?: string, locale?: string): string | null {
    if (isEmpty(value)) return null;

    locale = locale || this._locale;

    if (typeof display === 'boolean') {
      if (<any>console && <any>console.warn) {
        console.warn(
          `Warning: the currency pipe has been changed in Angular v5. The symbolDisplay option (third parameter) is now a string instead of a boolean. The accepted values are "code", "symbol" or "symbol-narrow".`);
      }
      display = display ? 'symbol' : 'code';
    }

    let currency: string = this._currencyCode || 'USD';
    if (display !== 'code') {
      if (display === 'symbol' || display === 'symbol-narrow') {
        currency = getCurrencySymbol(currency, display === 'symbol' ? 'wide' : 'narrow', locale);
      } else {
        currency = display;
      }
    }

    try {
      const num = strToNumber(value);
      return formatCurrency(num, locale, currency, currencyCode, digitsInfo);
    } catch (error) {
      throw Error(`InvalidPipeArgument: '${value}' for pipe 'waehrung'`);
    }
  }
}

function isEmpty(value: any): boolean {
  return value == null || value === '' || value !== value;
}

/**
 * Transforms a string into a number (if needed).
 */
function strToNumber(value: number | string): number {
  // Convert strings to numbers
  if (typeof value === 'string' && !isNaN(Number(value) - parseFloat(value))) {
    return Number(value);
  }
  if (typeof value !== 'number') {
    throw new Error(`${value} is not a number`);
  }
  return value;

}
