import {ChangeDetectorRef, Component, Inject, Input, LOCALE_ID, OnInit} from "@angular/core";
import {FilterService} from "../../../gw-search-lib/service/filter.service";
import {TranslationService} from "../../../gw-translation-lib/service/translation.service";
import {ResultStateService} from "../../../gw-search-lib/service/result-state.service";
import {EventBusService} from "../../../gw-search-lib/service/event-bus.service";
import {ResultState} from "../../../gw-search-lib/interface/result-state";
import {ActionType} from "../../enum/action-type.enum";
import {GwEvent} from "../../../gw-search-lib/interface/gw-event";
import {FilterFieldInterface} from "../../../gw-search-lib/interface/filter-field-interface";
import {CustomStepDefinition, LabelType, Options} from "@angular-slider/ngx-slider";
import {Helpers} from "../../helpers";
import {ComparatorType} from "../../../gw-search-lib/enum/comparator-type.enum";
import isString from "lodash-es/isString";
import {SortingType} from "../../../gw-search-lib/enum/sorting-type.enum";
import {FilterOptionInterface} from "../../../gw-search-lib/interface/filter-option-interface";
import {ChangeContext} from "@angular-slider/ngx-slider/change-context";
import {ChangeEventContext, ExtendedCustomStepDefinition} from "../../types";
import {OptionValueType} from "../../../gw-search-lib/enum/field-value-type.enum";

@Component({
    // selector: 'gw-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

    private _orientation: string = 'horizontal';
    private _showTicks: boolean;
    private _format: string;

    private get _isVertical(): boolean {
        return this._orientation === 'vertical';
    }

    private get _isDualMode(): boolean {
        return this._comparators.length === 2;
    }

    private get _isSingleMode(): boolean {
        return this._comparators.length === 1;
    }

    private _filterField: FilterFieldInterface = null;
    private _comparators: ComparatorType[] = [ComparatorType.EQ];
    private _sorting: SortingType = SortingType.ASC;
    private _options: FilterOptionInterface[] = [];
    private _minValue: { current: number, old: number } = {current: 0, old: 0};
    private _maxValue: { current: number, old: number } = {current: null, old: null};

    @Input('gw-ticks')
    public set showTicks(show: string) {
        this._showTicks = ['true', '1', 'show', 'on'].includes(show.toLowerCase());
    }

    @Input('gw-orientation')
    public set orientation(value: string | undefined | null) {
        if (['horizontal', 'vertical'].includes(value)) {
            this._orientation = value;
        }
    }

    public get orientationValue(): string {
        return this._orientation;
    }

    @Input('gw-comparator')
    public set comparator(comparator) {
        if (!isString(comparator)) {
            return;
        }

        let comparators;
        if (comparator.includes(',')) {
            comparators = comparator
                .split(',')
                .map(c => c.trim().toUpperCase() as ComparatorType)
                .sort((c1, c2) => {
                    if (c1 === c2) {
                        return 0;
                    }

                    return c1 === ComparatorType.LTE ? 1 : -1;
                });
        } else {
            comparators = [comparator.toUpperCase() as ComparatorType];
        }

        this._comparators = comparators.filter(type => [ComparatorType.EQ, ComparatorType.LTE, ComparatorType.GTE].includes(type));
    }

    public get comparators(): ComparatorType[] {
        return this._comparators;
    }

    @Input('gw-sorting')
    public set sorting(sorting: SortingType) {
        this._sorting = [SortingType.ASC, SortingType.DESC].includes(sorting.toUpperCase() as SortingType)
            ? sorting.toUpperCase() as SortingType
            : SortingType.ASC;
    }

    public get sorting(): SortingType {
        return this._sorting;
    }

    @Input('gw-class') public cssClasses: string = '';
    @Input('gw-id') public id: string = '';
    @Input('gw-field') public fieldName: string = '';
    @Input('gw-filters') public filtersAsJsonString: string;
    @Input('gw-format-function')
    public set format(format: string) {
        if(typeof format === "string" && format.length > 0) {
            this._format = format;
        }
    }

    @Input('gw-format-unit') public unit: string = '';
    @Input('gw-format-function-param') public additionalParam: string = '';
    @Input('gw-format-appendix') public appendix: string = '';

    public steps: Array<CustomStepDefinition & { option: FilterOptionInterface }> = [];

    public selectedOptions: { readonly min?: FilterOptionInterface, readonly max?: FilterOptionInterface } = {};

    public set minValue(minValue: number) {
        if (minValue === this._minValue.current) {
            return;
        }
        this._minValue.current = minValue;
    }

    public get minValue(): number {
        return this._minValue.current;
    }

    public set maxValue(maxValue: number) {
        if (maxValue === this._maxValue.current) {
            return;
        }
        this._maxValue.current = maxValue;
    }

    public get maxValue(): number {
        return this._maxValue.current;
    }

    public settings: Options = {
        hidePointerLabels: false,
        stepsArray: [],
        bindIndexForStepsArray: true,
        vertical: this._orientation === 'vertical',
        translate: this._formatLabel.bind(this),
        showTicksValues: false,
        showTicks: false,
    };

    // noinspection JSUnusedGlobalSymbols
    public constructor(
        protected filterService: FilterService,
        protected _translationService: TranslationService,
        protected resultStateService: ResultStateService,
        protected eventBus: EventBusService,
        protected changeDetectorRef: ChangeDetectorRef,
        @Inject(LOCALE_ID) protected localeId: string
    ) {
    }

    ngOnInit(): void {
        const self = this;
        Object.defineProperties(this.selectedOptions, {
            min: {
                get(): FilterOptionInterface {
                    if (typeof self.minValue !== "number") {
                        return null;
                    }

                    const step: ExtendedCustomStepDefinition = self.settings.stepsArray[self.minValue];
                    if (!step) {
                        return null;
                    }

                    const value = step.optionValue;

                    return self._options.find(o => o.type === self.comparators[0] && o.value === value);
                }
            },
            max: {
                get(): FilterOptionInterface {
                    if (typeof self.maxValue !== "number" || self._isSingleMode) {
                        return null;
                    }

                    const step: ExtendedCustomStepDefinition = self.settings.stepsArray[self.maxValue];
                    if (!step) {
                        return null;
                    }

                    const value = step.optionValue;

                    const option = self._options.find(o => o.type === self.comparators[1] && o.value === value);
                    if(option === undefined) {
                        debugger;
                    }
                    return option;
                }
            }
        });

        this.eventBus.on<ResultState>(ActionType.RESULT_STATE_READY, evt => this._onResultStateReady(evt));
    }

    private _onResultStateReady(resultStateReadyEvent: GwEvent<ResultState>) {
        const resultState: ResultState = resultStateReadyEvent.payload;

        this._filterField = this.filterService.getFilterFieldByName(this.fieldName);

        if (!this._filterField) {
            console.error(`[GW] misconfiguration: filter field »${this.fieldName}« not available. (1638188672879)`);
            return;
        }

        this._initOptions(this._filterField);
        const stepsArray = this.settings.stepsArray as Array<ExtendedCustomStepDefinition>;
        const minComparator = this._comparators[0];
        const resultStateFilterOptions = Array.from(resultState.filterOptions.values());

        const minOption = resultStateFilterOptions.find(o => o.fieldName === this.fieldName && o.type === minComparator);
        this.minValue = minOption ? stepsArray.findIndex(s => s.optionValue === minOption.value) : 0;

        if (this._isSingleMode) {
            return;
        }

        const maxOption = resultStateFilterOptions.find(o => o.fieldName == this.fieldName && o.type === ComparatorType.LTE);
        this._maxValue.old = this.maxValue = maxOption ? stepsArray.findIndex(s => s.optionValue === maxOption.value) : stepsArray.length - 1;

        this.eventBus.on<ChangeEventContext>(ActionType.FILTERS_CHANGE, filterChangeEvent => this._onFiltersChange(filterChangeEvent), false, true);
    }

    private _initOptions(_filterField: FilterFieldInterface) {
        const options = _filterField.options.filter(o => this._comparators.includes(o.type));
        this._options = Helpers.sortOptions(options, this._sorting, this._filterField.optionValueType);

        const steps = new Map<string, ExtendedCustomStepDefinition>();
        let idx = 0;
        for(let option of this._options) {
            const key = option.value;
            if(!steps.has(key)) {
                steps.set(key, <ExtendedCustomStepDefinition>{
                    value: idx,
                    legend: Helpers.formatOptionLabel(option, this._format, this.unit, this.localeId, this.additionalParam),
                    optionValue: option.value
                });
                idx++;
            }
        }

        this.settings = {
            ...this.settings, ...{
                showSelectionBar: this._isSingleMode && this.comparators.includes(ComparatorType.LTE),
                showSelectionBarEnd: this._isSingleMode && this.comparators.includes(ComparatorType.GTE),
                showTicks: this._showTicks,
                vertical: this._isVertical,
                stepsArray: Array.from(steps.values())
            }
        };
    }

    private _formatLabel(index: number, label: LabelType): string {
        if (this._isSingleMode) {
            return this.settings.stepsArray[index].legend;
        }

        if (label === LabelType.Low) {
            return 'min. ' + this.settings.stepsArray[index].legend;
        } else {
            return 'max. ' + this.settings.stepsArray[index].legend;
        }
    }

    public onChangeByUser(context: ChangeContext) {
        const addingFilterOptions = [];
        const removingFilterOptions = [];

        // minValue changed
        const newMinValue = context.value;
        if (this.minValue !== newMinValue) {
            const oldMinFilterOption = this.selectedOptions.min;
            this.minValue = newMinValue;
            const newMinFilterOption = this.selectedOptions.min;

            removingFilterOptions.push(oldMinFilterOption);
            addingFilterOptions.push(newMinFilterOption);
            if(newMinFilterOption === undefined) {
                debugger;
            }
        }

        // maxValue changed
        const newMaxValue = context.highValue;
        if (this._isDualMode && this.maxValue !== newMaxValue) {
            const oldMaxFilterOption = this.selectedOptions.max;
            this.maxValue = newMaxValue;
            const newMaxFilterOption = this.selectedOptions.max;

            removingFilterOptions.push(oldMaxFilterOption);
            addingFilterOptions.push(newMaxFilterOption);
            if(newMaxFilterOption === undefined) {
                debugger;
            }
        }

        this.eventBus.broadcast<ChangeEventContext>(this, ActionType.FILTERS_CHANGE, {
            remove: removingFilterOptions,
            add: addingFilterOptions
        });
    }

    private _onFiltersChange(filterChangeEvent: GwEvent<ChangeEventContext>) {
        if (filterChangeEvent.sender === this) {
            return;
        }

        const addableFilterOptions = filterChangeEvent.payload.add.filter(o => o.fieldName === this.fieldName);
        const removableFilterOptions = filterChangeEvent.payload.remove.filter(o => o.fieldName === this.fieldName);
        if (removableFilterOptions.length > 0 || addableFilterOptions.length > 0) {
            const newMinValue = this._findIndexValue(addableFilterOptions, removableFilterOptions, this._comparators[0]);
            if (typeof newMinValue === "number") {
                this.minValue = newMinValue;
            }

            if (this._isSingleMode) {
                return;
            }

            const newMaxValue = this._findIndexValue(addableFilterOptions, removableFilterOptions, this._comparators[1]);
            if (typeof newMaxValue === "number") {
                this.maxValue = newMaxValue;
            }
        }

        if (typeof filterChangeEvent.sender === "string" && filterChangeEvent.sender === "ResultComponent" && addableFilterOptions.findIndex(o => o.fieldName === this.fieldName) > -1) {
            this.settings = {
                ...this.settings, ...{
                    disabled: true
                }
            };
        }
    }

    private _findIndexValue(addableFilterOptions: FilterOptionInterface[], removableFilterOptions: FilterOptionInterface[], comparator: ComparatorType) {
        const newOption = addableFilterOptions.find(a => a.type === comparator);
        if (newOption) {
            const steps = this.settings.stepsArray as Array<ExtendedCustomStepDefinition>;
            return steps.findIndex(s => s.optionValue === newOption.value) || this.settings.stepsArray.length - 1;
        }

        const removableMaxFilterOption = removableFilterOptions.find(a => a.type === comparator);
        if (removableMaxFilterOption) {
            return comparator === ComparatorType.GTE ? 0 : this.settings.stepsArray.length -1;
        }
    }

    public getComparatorLabelPrefix(comparator: ComparatorType): string {
        switch (comparator) {
            case ComparatorType.EQ:
                return ``;
            case ComparatorType.LTE:
                return `bis`;
            case ComparatorType.GTE:
                return `von`;
        }
    }

    public getComparatorLabel(comparatorIndex: number): string {
        return this.settings.stepsArray[comparatorIndex === 1 ? this.maxValue : this.minValue].legend;
    }
}
