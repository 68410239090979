import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActionType} from 'projects/gw-web-components/src/app/gw-pipe-lib/enum/action-type.enum';
import {CarModel} from 'projects/gw-web-components/src/app/gw-search-lib/model/car-model';
import {EventBusService} from 'projects/gw-web-components/src/app/gw-search-lib/service/event-bus.service';
import {map, takeUntil} from 'rxjs/operators';
import {ComparatorType} from '../../gw-search-lib/enum/comparator-type.enum';
import {OptionValueType} from '../../gw-search-lib/enum/field-value-type.enum';
import {SortingType} from '../../gw-search-lib/enum/sorting-type.enum';
import {CarInterface} from '../../gw-search-lib/interface/car-interface';
import {FilterOptionInterface} from '../../gw-search-lib/interface/filter-option-interface';
import {FilterFieldModel} from '../../gw-search-lib/model/filter-field.model';
import {FilterOptionModel} from '../../gw-search-lib/model/filter-option.model';
import {SearchService} from '../../gw-search-lib/service/search.service';
import {Subject} from "rxjs";
import {LastSeenService} from "../../gw-search-lib/service/last-seen.service";

@Component({
  selector: 'gw-last-seen-helper',
  template: ''
})
export class LastSeenHelperComponent implements OnInit, OnDestroy {

  @Input('gw-car-id')
  public carId: string;

  private _destroy$ = new Subject<void>();

  constructor(
    protected eventBus: EventBusService,
    protected searchService: SearchService,
    protected lastSeenService: LastSeenService
  ) {
  }

  public ngOnInit() {
    this.saveInLastSeenHistory();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private existsCarId(): boolean {
    return typeof this.carId === 'string' && this.carId.length > 0;
  }

  private saveInLastSeenHistory() {
    if(this.existsCarId() === false) {
      return;
    }

    this.getCarById(this.carId);
  }

  /**
   *
   * @param carId
   */
  protected getCarById(carId: string) {
    const carFilterValue: FilterOptionInterface = new FilterOptionModel(new FilterFieldModel({
      input_type:        'keyword',
      key:               'id',
      label:             '',
      label_translated:  '',
      option_value_type: OptionValueType.KEYWORD,
      options:           []
    }), {
      field: 'id',
      type:  ComparatorType.EQ,
      value: carId,
      label: '',
      label_translated: '',
      count: 1
    });

    this.searchService.search<CarInterface>(CarModel,{
      filterOptions: new Map<string, FilterOptionInterface>([['id', carFilterValue]]),
      sorting:       { field: 'id', order: SortingType.ASC },
      offset:        0,
      limit:         1,
      isGlobal:      false
    }).pipe(
        takeUntil(this._destroy$),
        map(response => response[0])
    ).subscribe((car) => {
      if (car) {
        this.eventBus.broadcast<string>(this, ActionType.LAST_SEEN_ADD, carId);
      }
    });
  }

}
