import {Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import first from 'lodash-es/first';
import {ActionType} from 'projects/gw-web-components/src/app/gw-pipe-lib/enum/action-type.enum';
import {CarModel} from 'projects/gw-web-components/src/app/gw-search-lib/model/car-model';
import {EventBusService} from 'projects/gw-web-components/src/app/gw-search-lib/service/event-bus.service';
import {ResultStateService} from 'projects/gw-web-components/src/app/gw-search-lib/service/result-state.service';
import {map} from 'rxjs/operators';
import {ComparatorType} from '../../gw-search-lib/enum/comparator-type.enum';
import {OptionValueType} from '../../gw-search-lib/enum/field-value-type.enum';
import {SortingType} from '../../gw-search-lib/enum/sorting-type.enum';
import {CarInterface} from '../../gw-search-lib/interface/car-interface';
import {FilterOptionInterface} from '../../gw-search-lib/interface/filter-option-interface';
import {ResultState} from '../../gw-search-lib/interface/result-state';
import {FilterFieldModel} from '../../gw-search-lib/model/filter-field.model';
import {FilterOptionModel} from '../../gw-search-lib/model/filter-option.model';
import {LastSeenService} from '../../gw-search-lib/service/last-seen.service';
import {LocationHashService} from '../../gw-search-lib/service/location-hash.service';
import {SearchService} from '../../gw-search-lib/service/search.service';
import {ToolBoxService} from '../../gw-search-lib/service/tool-box.service';
import {GwDetailsTemplate} from '../../interface/gw-details-template';

@Component({
  selector:    'gw-details',
  templateUrl: './details.component.html',
  styleUrls:   ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  @Input('gw-car-id') public carId: string;
  @Input('gw-class') public classes: string;

  public template: GwDetailsTemplate = { item: null };
  public car: CarInterface;

  @ViewChild('tmpl')
  protected tmpl: TemplateRef<any>;

  constructor(
    protected locationHashService: LocationHashService,
    protected eventBus: EventBusService,
    protected searchService: SearchService,
    protected vcRef: ViewContainerRef,
    protected toolboxService: ToolBoxService,
    protected resultStateService: ResultStateService,
    protected lastSeenService: LastSeenService
  ) {
    const projectableNodes = <Node[]>first(this.vcRef["_hostTNode"].projection);
    this.template = <GwDetailsTemplate>this.toolboxService.querySelectorTemplate<GwDetailsTemplate>(projectableNodes);
  }

  private existsCarId(): boolean {
    return typeof this.carId === 'string' && this.carId.length > 0;
  }

  private onResultStateReady() {
    if (this.existsCarId() === false && this.locationHashService.getHashArguments().has('details')) {
      this.carId = this.locationHashService.getHashArguments().get('details');
    }

    if(this.existsCarId() === false) {
      return;
    }

    this.getCarById(this.carId);
    this.eventBus.on<Map<string, string>>(ActionType.HASH_CHANGED, event => this.onLocationHashChanged(event.payload));
  }

  /**
   *
   * @param hashArguments
   */
  private onLocationHashChanged(hashArguments: Map<string, string>): void {
    if (hashArguments.has('details')) {
      const carIdByLocationHash = hashArguments.get('details');
      if (this.carId !== carIdByLocationHash) {
        this.carId = carIdByLocationHash;
        this.getCarById(this.carId);
      }
    }
  }

  ngOnInit() {
    this.eventBus.on<ResultState>(ActionType.RESULT_STATE_READY, () => this.onResultStateReady());
  }

  /**
   *
   * @param carId
   */
  protected getCarById(carId: string) {
    const carFilterValue: FilterOptionInterface = new FilterOptionModel(new FilterFieldModel({
      input_type:        'keyword',
      key:               'id',
      label:             '',
      label_translated:  '',
      option_value_type: OptionValueType.KEYWORD,
      options:           []
    }), {
      field: 'id',
      type:  ComparatorType.EQ,
      value: carId,
      label: '',
      label_translated: '',
      count: 1
    });

    this.searchService.search<CarInterface>(CarModel,{
      filterOptions: new Map<string, FilterOptionInterface>([['id', carFilterValue]]),
      sorting:       { field: 'id', order: SortingType.ASC },
      offset:        0,
      limit:         1,
      isGlobal:      false
    }).pipe(map(response => {
      return response[0];
    })).subscribe((value) => {
      this.car = value;
      this.eventBus.broadcast<string>(this, ActionType.LAST_SEEN_ADD, carId);
    });
  }

}
