import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'projects/gw-web-components/src/app/gw-translation-lib/service/translation.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(protected translationService: TranslationService) {}

  transform(value: string, ...args: any[]): string {
    let translation: string = this.translationService.translate(value, ...args);
    return translation;
  }

}
