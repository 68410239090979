import { Directive, Input, HostListener } from '@angular/core';
import { EventBusService } from '../../gw-search-lib/service/event-bus.service';
import { ActionType } from '../enum/action-type.enum';

@Directive({
  selector: '[gw-action]'
})
export class ActionDirective {

  @Input('gw-action') protected actionName: ActionType;

  @Input('gw-payload') protected payload: any;

  constructor(
    protected eventBusService: EventBusService
  ) {
  }

  @HostListener('click') onClick() {
    this.eventBusService.broadcast(this, this.actionName);
  }
}
