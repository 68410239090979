import {Directive, HostBinding, Inject, Input, OnInit} from '@angular/core';
import {WINDOW} from '../../gw-configuration-lib/reference/window-ref';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Directive({
    selector: 'img[gw-src]'
})
export class SrcDirective implements OnInit {

    @Input('gw-src')
    public srcNewValue: string;

    @HostBinding('src')
    public targetSrc: SafeUrl;

    constructor(
        @Inject(WINDOW) protected windowRef: Window,
        protected domSanitizer: DomSanitizer
    ) {
    }

    public ngOnInit() {
        this.buildTargetSrc();
    }

    protected buildTargetSrc(): any {
        if(!(typeof this.srcNewValue === 'string' && this.srcNewValue.length > 0)) {
            return;
        }
        this.targetSrc = this.domSanitizer.bypassSecurityTrustUrl(this.srcNewValue.toString());
    }
}
