import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { HttpCache } from '../cache/http.cache';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {

  private fromCachingExcludedUris: string[] = [];

  constructor(private cache: HttpCache) {}

  public intercept(req: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
    // Before doing anything, it's important to only cache GET requests.
    // Skip this interceptor if the request method isn't GET.
    if (this.isExcludedFromCaching(req)) {
      return httpHandler.handle(req);
    }

    if (this.cache.has(req)) {
      return of(this.cache.get(req));
    }

    if(this.cache.hasSubject(req)) {
      return this.cache.getSubject(req).asObservable();
    }

    httpHandler.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.cache.set(req, event);
        }
      })
    ).subscribe(response => {
      this.cache.applySubject(req, response);
    });

    return this.cache.getSubject(req).asObservable();
  }

  /**
   * @param req
   */
  private isExcludedFromCaching(req: HttpRequest<any>): boolean {
    return false;
  }

  /**
   * searches for uri in regex list
   *
   * @param {string} needle
   * @param {string[]} haystack
   * @return {boolean}
   */
  private matchInArray(needle: string, haystack: string[]): boolean {
    let len = haystack.length, i = 0;
    for (; i < len; i++) {
      if (needle.match(haystack[i])) {
        return true;
      }
    }
    return false;
  }
}
