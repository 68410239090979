import {Directive, HostBinding, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {WINDOW} from '../../gw-configuration-lib/reference/window-ref';
import {LocationHashService} from '../../gw-search-lib/service/location-hash.service';
import {Subject} from "rxjs";
import {startWith, takeUntil} from "rxjs/operators";
import {LocalStorageService} from "../../gw-search-lib/service/local-storage.service";

@Directive({
  selector: 'a[gw-href]'
})
export class HrefDirective implements OnInit, OnDestroy {

  private _destroy$ = new Subject<void>();

  @Input('gw-href')
  public targetAction: string;

  @Input('href')
  public sourceHref: string;

  @HostBinding('href')
  public targetHref: string;

  @HostBinding('class.gw-result-first')
  public isFirst: boolean = false;

  @HostBinding('class.gw-result-last')
  public isLast: boolean = false;

  constructor(
    protected locationHashService: LocationHashService,
    protected localStorageService: LocalStorageService,
    @Inject(WINDOW) protected windowRef: Window
  ) {
  }

  public ngOnInit() {
    this.locationHashService.settingsByUri$.pipe(
        startWith(new Map<string, any>()),
        takeUntil(this._destroy$)
    ).subscribe(() => this.buildTargetHref());
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private getMergedHashArguments(sourceHref: string, targetAction: string): URL {
    const argumentsOfCurrentUri = this.locationHashService.getHashArguments();
    const targetUri = new URL(sourceHref, this.windowRef.location.toString());
    const argumentsOfLinkUri = this.locationHashService.parseHash(targetUri.hash);
    const mergedHashArguments = new Map<string, any>([...Array.from(argumentsOfCurrentUri), ...Array.from(argumentsOfLinkUri)]);
    const [actionName, actionValue] = typeof targetAction === 'string' ? targetAction.split(':') : [null, null];

    if (actionName === 'details' && this.localStorageService.has('gw-result-list')) {
      const regexpResult = /(?<slug>[^.\/]+|[^ \/]+)(\.html|\/?)$/.exec(this.windowRef.location.pathname);
      const slug = regexpResult?.groups?.slug ?? null;
      const gwResultList = this.localStorageService.get<string[]>('gw-result-list');
      const gwResultListIndex = gwResultList.indexOf(slug);

      if (gwResultListIndex > -1) {
        switch (actionValue) {
          case 'next':
            if (gwResultListIndex === gwResultList.length-1) {
              this.isLast = true;
            } else {
              const gwResultListNextIndex = gwResultListIndex + 1;
              if (HrefDirective._nextPageExists(gwResultList, gwResultListNextIndex)) {
                HrefDirective._appendSlug(targetUri, gwResultList[gwResultListNextIndex]);
              }
            }
            break;
          case 'before':
            if (gwResultListIndex === 0) {
              this.isFirst = true;
            } else {
              const gwResultListNextIndex = gwResultListIndex - 1;
              if (HrefDirective._nextPageExists(gwResultList, gwResultListNextIndex)) {
                HrefDirective._appendSlug(targetUri, gwResultList[gwResultListNextIndex]);
              }
            }
            break;
          default:
            this.isFirst = true;
            this.isLast = true;
        }
      }
    }

    if (actionName && actionValue) {
      mergedHashArguments.set(actionName, actionValue);
    }
    targetUri.hash = '?' + this.locationHashService.buildUriHash(mergedHashArguments);

    return targetUri;
  }

  protected buildTargetHref(): any {
    const newTargetUri = this.getMergedHashArguments(this.sourceHref, this.targetAction);
    this.targetHref = newTargetUri.toString();
  }

  private static _appendSlug(targetUri: URL, slug: string): void {
    let pathname = targetUri.pathname;
    if(/\.html$/g.test(pathname)) {
      pathname = pathname.replace(/(?<slug>[^\/]+)\.html$/, `$1/${slug}.html`);
    } else {
      if(pathname[pathname.length-1] !== '/') {
        pathname += '/';
      }
      pathname += slug + '/';
    }

    targetUri.pathname = pathname;
  }

  private static _nextPageExists(gwResultList, gwResultListNextIndex): boolean {
    return gwResultListNextIndex > -1 && gwResultList[gwResultListNextIndex] !== 'undefined';
  }
}
