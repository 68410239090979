import {FilterOptionInterface} from "../gw-search-lib/interface/filter-option-interface";
import isString from "lodash-es/isString";
import {formatDate, formatNumber} from "@angular/common";
import {OptionValueType} from "../gw-search-lib/enum/field-value-type.enum";
import {SortingType} from "../gw-search-lib/enum/sorting-type.enum";
import {InputType} from "../gw-search-lib/enum/input-type.enum";

export class Helpers {

    public static formatOptionLabel(option: any, type: string, unit: string, localeId: string, additionalParam: string): string {
        switch (type) {
            case 'number':
                let unitString = '';
                if (isString(unit) && unit.length > 0) {
                    unitString = ' ' + unit;
                }
                return formatNumber(parseInt(option.label_translated), localeId, additionalParam) + unitString;
            case 'date':
                return formatDate(parseInt(option.label_translated), additionalParam, localeId);
            case 'horsepower':
                return Helpers.formatHorsePower(option.value, localeId, unit, additionalParam);
            default:
                switch (option.field.inputType) {
                    case InputType.SELECT:
                        return (`${option.label_translated} ${unit || ''}`).trim();
                    case InputType.CHECKBOX:
                        return ((option.field.optionValueType === OptionValueType.BOOLEAN ? option.field.label_translated : option.label_translated) + ' ' + (unit || '')).trim();
                }
                return (`${option.label_translated} ${unit || ''}`).trim();
        }
    }

    public static formatOptionLabels(options: FilterOptionInterface[], type: string, unit: string, localeId: string, additionalParam: string) {
        for (let option of options) {
            option.label_translated = Helpers.formatOptionLabel(option, type, unit, localeId, additionalParam);
        }

        return options;
    }

    public static formatHorsePower(value: number, localeId: string, unit: string = 'PS', additionalParam: string = null) {
        const kw = formatNumber(value, localeId, additionalParam);
        const ps = formatNumber(Math.ceil(value * 1.35962), localeId, additionalParam);
        return `${kw} kW (${ps} ${unit})`;
    }

    public static sortOptions(options: FilterOptionInterface[], sorting: SortingType, optionValueType: OptionValueType, stickyValues: string[] = []) {
        return options.sort((optionA, optionB) => {
            const isStickyA = stickyValues.includes(optionA.value);
            const isStickyB = stickyValues.includes(optionB.value);

            if (optionA.value === null) {
                return -1
            }

            if (optionB.value === null) {
                return 1
            }

            if (isStickyA && isStickyB) {
                return stickyValues.indexOf(optionA.value) - stickyValues.indexOf(optionB.value);
            }

            if (isStickyA && !isStickyB) {
                return -1;
            }

            if (!isStickyA && isStickyB) {
                return 1;
            }

            let labelA, labelB;
            // noinspection TypeScriptValidateTypes
            if ([OptionValueType.FLOAT, OptionValueType.INTEGER, OptionValueType.YEAR, OptionValueType.BYTE].includes(optionValueType)) {
                labelA = optionA.value;
                labelB = optionB.value;
            } else {
                labelA = optionA.label.toString().toLocaleLowerCase().replace('š', 's');
                labelB = optionB.label.toString().toLocaleLowerCase().replace('š', 's');
            }

            if (labelA === labelB) {
                return 0;
            }

            return (sorting === SortingType.DESC ? -1 : 1) * (labelA < labelB ? -1 : 1);
        });
    }

    public static isFilledString(value?: string): boolean {
        return typeof value === "string" && value.length > 0;
    }
}
