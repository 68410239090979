import {Injectable} from '@angular/core';
import {AppConfigService} from '../../gw-configuration-lib/service/app-config.service';
import {sprintf} from "sprintf-js";

@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    protected _translations: Map<string, string>;

    constructor(
        protected appConfigService: AppConfigService
    ) {
        this.initialize();
    }

    /**
     *
     * @param key
     * @param args
     */
    public translate(key: string, ...args: any[]): string {
        let translatedString = key.toLowerCase()
            .replace(/[/\\ ]+/g, '-')
            .replace('-+', '-');

        if (this._translations.has(translatedString)) {
            translatedString = this._translations.get(translatedString);
            if (Array.isArray(args) && args.length > 0) {
                translatedString = sprintf('' + translatedString, ...args);
            }
        }

        if (/^filter\./.test(translatedString)) {
            translatedString = translatedString.split('.').pop();
            translatedString = translatedString.charAt(0).toUpperCase() + translatedString.slice(1);
            translatedString = translatedString.split('-').map(c => c.charAt(0).toUpperCase() + c.slice(1)).join(' ');
        }

        return translatedString;
    }

    protected initialize(): void {
        this._translations = new Map(this.appConfigService.get<[string, string][]>('translations', []));
    }
}
