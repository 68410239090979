import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import mapCollection from 'lodash-es/map';
import {
    FilterFieldConfiguration
} from 'projects/gw-web-components/src/app/gw-search-lib/type/filter-field-configuration';
import {BehaviorSubject, Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AppConfigService} from '../../gw-configuration-lib/service/app-config.service';
import {ActionType} from '../../gw-pipe-lib/enum/action-type.enum';
import {TranslationService} from '../../gw-translation-lib/service/translation.service';
import {ComparatorType} from '../enum/comparator-type.enum';
import {BodyRequestParameters} from '../interface/body-request-parameters';
import {FilterConfigurationResponse} from '../interface/filter-configuration-response';
import {FilterFieldInterface} from '../interface/filter-field-interface';
import {FilterFieldResponse} from '../interface/filter-field-response';
import {FilterOptionInterface} from '../interface/filter-option-interface';
import {FilterValueResponse} from '../interface/filter-value-response';
import {SortingValue} from '../interface/sorting-value';
import {FilterFieldModel} from '../model/filter-field.model';
import {FilterOptionModel} from '../model/filter-option.model';
import {EventBusService} from './event-bus.service';
import {InputType} from "../enum/input-type.enum";
import {ResultState} from "../interface/result-state";
import {ChangeEventContext} from "../../gw-pipe-lib/types";
import has = Reflect.has;

@Injectable({
    providedIn: 'root'
})
export class FilterService {

    public countChanged$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public totalChanged$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public segmentChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public activeSegment$: BehaviorSubject<string> = new BehaviorSubject<string>("");

    public ready$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    readonly FILTER_CONFIGURATION_URI = `${this.appConfigService.get<string>('apiScheme', 'https')}://${this.appConfigService.get<string>('apiHost', 'localhost')}${this.appConfigService.get<string>('basePath', '/')}/filterConfiguration`;

    public selectedFilterOptions: BehaviorSubject<Map<string, FilterOptionInterface>> = new BehaviorSubject<Map<string, FilterOptionInterface>>(new Map<string, FilterOptionInterface>());

    public selectedSorting: BehaviorSubject<SortingValue> = new BehaviorSubject<SortingValue>(null);

    public filterFields: Map<string, FilterFieldInterface>;

    protected _filterConfiguration$: ReplaySubject<Map<string, FilterFieldInterface>> = new ReplaySubject<Map<string, FilterFieldInterface>>(1);

    protected _countRequest: Subscription;

    constructor(
        protected appConfigService: AppConfigService,
        protected eventBus: EventBusService,
        protected translationService: TranslationService,
        protected httpClient: HttpClient
    ) {
        this.initialize();
    }

    public get count(): number {
        return this.countChanged$.getValue();
    }

    public set count(count: number) {
        if (count !== this.countChanged$.getValue()) {
            this.countChanged$.next(count);
        }
    }

    public get total(): number {
        return this.totalChanged$.getValue();
    }

    public set total(newTotal: number) {
        if (newTotal !== this.totalChanged$.getValue()) {
            this.totalChanged$.next(newTotal);
        }
    }

    public get segmentChanged(): boolean {
        return this.segmentChanged$.getValue();
    }

    public set segmentChanged(segmentChanged: boolean) {
        if (segmentChanged !== this.segmentChanged$.getValue()) {
            this.segmentChanged$.next(segmentChanged);
        }
    }

    public get activeSegment(): string {
        return this.activeSegment$.getValue();
    }

    public set activeSegment(segment: string) {
        if (segment !== this.activeSegment$.getValue()) {
            this.activeSegment$.next(segment);
        }
    }

    public get filterConfiguration$(): Observable<Map<string, FilterFieldInterface>> {
        return this._filterConfiguration$;
    }

    protected _selectedFilterOptions$: Observable<Map<string, FilterOptionInterface>> = this.selectedFilterOptions.asObservable();

    public get selectedFilterOptions$(): Observable<Map<string, FilterOptionInterface>> {
        return this._selectedFilterOptions$;
    }

    protected _selectedSorting$: Observable<SortingValue> = this.selectedSorting.asObservable();

    public get selectedSorting$(): Observable<SortingValue> {
        return this._selectedSorting$;
    };

    /**
     *
     * @param filterKey
     */
    public getFilterFieldByName(filterKey: string): FilterFieldInterface | null {
        if (this.filterFields.has(filterKey) === false) {
            throw new Error(`1535980947339: There are no filter with name ${filterKey}`);
        }
        return this.filterFields.get(filterKey);
    }

    /**
     *
     * @param filterField
     * @param value
     * @param comparator
     */
    public getOption(filterField: FilterFieldInterface, value: any, comparator: ComparatorType = ComparatorType.EQ): FilterOptionInterface | null {
        let option = filterField.options.find((option: FilterOptionInterface) => option.value.toString() === value.toString() && option.type === comparator.toUpperCase());
        if (!option && filterField.inputType === InputType.TEXT) {
            option = new FilterOptionModel(filterField, {
                field: filterField.key,
                label: value,
                label_translated: value,
                value: value,
                type: ComparatorType.EQ,
                count: 0
            });
        }

        return option;
    }

    /**
     *
     * @param filterFieldName
     * @param value
     * @param comparator
     */
    public getOptionByFieldName(filterFieldName: string, value: any, comparator: ComparatorType = ComparatorType.EQ): FilterOptionInterface | null {
        if (this.filterFields.has(filterFieldName) === false) {
            return null;
        }
        const filterField = this.filterFields.get(filterFieldName);
        return this.getOption(filterField, value, comparator);
    }

    /**
     *
     * @param requestParams
     */
    public requestCountsForSelectedFilterOptions(requestParams: BodyRequestParameters): void {
        this.resetCountRequest();

        this._countRequest = this.httpClient
            .get<FilterConfigurationResponse>(this.FILTER_CONFIGURATION_URI, {
                params: this.transformBodyRequestParametersToHttpParams(requestParams)
            })
            .pipe(catchError(() => of(<FilterConfigurationResponse>{})))
            .subscribe(response => {

                let filterSegment = "";
                if (requestParams._filter) {
                    Object.entries(requestParams._filter).forEach(o => {
                        if (o[0] === 'segment') {
                            filterSegment = o[1];
                        }
                    });

                    if (filterSegment !== this.activeSegment) {
                        this.activeSegment = filterSegment;
                        // update filter
                        this.filterFields = this.mapResponse(response);
                        this._filterConfiguration$.next(this.filterFields);
                        this.eventBus.broadcast<FilterFieldConfiguration>(this, ActionType.FILTER_SEGMENT_CHANGE, this.filterFields);
                    }
                }

                if (filterSegment !== this.activeSegment) {
                    this.activeSegment = filterSegment;
                }

                this.resetCountRequest();
                this.mapCountResponse(response);
            });
    }

    protected initialize(): void {
        this.httpClient
            .get<FilterConfigurationResponse>(this.FILTER_CONFIGURATION_URI)
            .pipe(catchError(err => this.onError(err)))
            .subscribe((response: FilterConfigurationResponse) => {
                if (has(response, 'result')) {
                    this.filterFields = this.mapResponse(response);
                    this._filterConfiguration$.next(this.filterFields);
                    this.eventBus.broadcast<FilterFieldConfiguration>(this, ActionType.FILTER_CONFIGURATION_READY, this.filterFields);
                }
            });

        this.eventBus.on<ResultState>(ActionType.RESULT_STATE_READY, (readyEvent) => {
            this.selectedFilterOptions.next(readyEvent.payload.filterOptions);
        });

        this.eventBus.on<ChangeEventContext>(ActionType.FILTERS_CHANGE, (filtersChangeEvent) => {
            const changes = filtersChangeEvent.payload;
            if (changes.add.length < 1 && changes.remove.length < 1) {
                return;
            }

            const currentFilterOptions = new Map(this.selectedFilterOptions.getValue().entries());

            changes.remove.forEach(o => {
                if (currentFilterOptions.has(o.hash)) {
                    currentFilterOptions.delete(o.hash);
                }
            });

            changes.add.forEach(o => {
                if (currentFilterOptions.has(o.hash) === false) {
                    currentFilterOptions.set(o.hash, o);
                }
            });

            this.selectedFilterOptions.next(currentFilterOptions);
        });
    }

    /**
     *
     * @param response
     */
    protected mapResponse(response: FilterConfigurationResponse): Map<string, FilterFieldInterface> {
        const filterConfiguration = new Map<string, FilterFieldInterface>();

        response.result.forEach((filterFieldResponse: FilterFieldResponse) => {
            this.translateLabels(filterFieldResponse);

            const filterField = new FilterFieldModel(filterFieldResponse);
            filterConfiguration.set(filterField.key, filterField);
        });

        return filterConfiguration;
    }

    /**
     *
     * @param response
     */
    protected mapCountResponse(response: FilterConfigurationResponse): void {
        type FilterOptionCount = Map<string, FilterOptionInterface>;

        const payload: Map<string, FilterOptionCount> = new Map<string, FilterOptionCount>();

        response.result.forEach((filterFieldResponse: FilterFieldResponse) => {
            const optionsResponse = new Map<any, FilterValueResponse>();

            filterFieldResponse.options.forEach(filterFieldOption => {
                optionsResponse.set(`${filterFieldOption.type.toUpperCase()}:${filterFieldOption.value}`, filterFieldOption);
            });

            const filterField = this.getFilterFieldByName(filterFieldResponse.key),
                payloadItem: FilterOptionCount = new Map<string, FilterOptionInterface>();

            filterField.options.forEach(filterValue => {
                const key = `${filterValue.type.toUpperCase()}:${filterValue.value}`;
                filterValue.count = (optionsResponse.has(key)) ? optionsResponse.get(key).count : 0;
                payloadItem.set(filterValue.hash, filterValue);
                optionsResponse.delete(key);
            });
            // there are unmapped options -> add
            if (optionsResponse.size > 0) {
                optionsResponse.forEach(filterValueResponse => {
                    const newFilterValue = new FilterOptionModel(filterField, filterValueResponse);
                    filterField.addOption(newFilterValue);
                    payloadItem.set(newFilterValue.hash, newFilterValue);
                });
            }
            payload.set(filterField.key, payloadItem);
        });
        this.total = response.count;

        this.eventBus.broadcast<Map<string, FilterOptionCount>>(this, ActionType.FILTERS_RECOUNT, payload);
    }

    /**
     *
     * @param filterFieldResponse
     */
    protected translateLabels(filterFieldResponse: FilterFieldResponse) {
        filterFieldResponse.label_translated = this.translationService.translate(filterFieldResponse.label);
        filterFieldResponse.options.forEach(option => {
            option.label_translated = `${this.translationService.translate(option.label)}`;
        });
    }

    /**
     *
     * @param requestParams
     */
    protected transformBodyRequestParametersToHttpParams(requestParams: BodyRequestParameters): HttpParams {
        let httpParams = new HttpParams();
        mapCollection(requestParams._filter, (val, key) => {
            if (typeof val === 'string' && val.includes(',')) {
                val.split(',').forEach(item => httpParams = httpParams.set(`_filter[${key}][]`, item));
            } else {
                httpParams = httpParams.set(`_filter[${key}]`, val)
            }
        });

        return httpParams;
    }

    /**
     *
     * @param err
     */
    private onError(err: any): Observable<{}> {
        console.error(err);
        return of({});
    }

    private resetCountRequest() {
        if (this._countRequest && !this._countRequest.closed) {
            this._countRequest.unsubscribe();
            this._countRequest = null;
        }
    }
}
