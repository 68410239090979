import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FilterFieldInterface} from '../../../gw-search-lib/interface/filter-field-interface';
import {FormControl, Validators} from '@angular/forms';
import {ComparatorType} from "../../../gw-search-lib/enum/comparator-type.enum";
import {IResultFilterSetting} from "./result-filter-setting-interface";
import {FilterOptionInterface} from "../../../gw-search-lib/interface/filter-option-interface";
import {OptionValueType} from "../../../gw-search-lib/enum/field-value-type.enum";
import {FilterFieldModel} from "../../../gw-search-lib/model/filter-field.model";
import {FilterService} from "../../../gw-search-lib/service/filter.service";

@Component({
    selector: 'gw-result-filter-setting',
    templateUrl: './result-filter-setting.component.html',
    styleUrls: ['./result-filter-setting.component.scss']
})
export class ResultFilterSettingComponent implements OnInit {

    private _setting: IResultFilterSetting;

    @ViewChild('defaultValueInput')
    public defaultValueInput: TemplateRef<HTMLElement>;

    @Input('setting')
    public set setting(setting: IResultFilterSetting) {
        this._setting = setting;
    }

    public get setting(): IResultFilterSetting {
        return this._setting;
    }

    private _availableFilterFields: FilterFieldInterface[] = [];

    @Input('filters')
    public set availableFilterFields(availableFilterFields: FilterFieldInterface[]) {
        this._availableFilterFields = availableFilterFields.sort((filterFieldA, filterFieldB) => {
            if(filterFieldA.label === filterFieldB.label) {
                return 0;
            }

            return filterFieldA.label < filterFieldB.label ? -1 : 1;
        });
    }

    public get availableFilterFields(): FilterFieldInterface[] {
        return this._availableFilterFields;
    }

    @Input('comparator')
    public comparator: ComparatorType = ComparatorType.EQ;

    @Output()
    public changed: EventEmitter<IResultFilterSetting> = new EventEmitter<IResultFilterSetting>();

    public selectedFilterFieldCtl: FormControl = new FormControl(null, {validators: [Validators.required], updateOn: "change"});
    public selectedFilterValueCtl: FormControl = new FormControl(null, {validators: [Validators.required], updateOn: "change"});
    public selectedComparatorCtl: FormControl = new FormControl(this.comparator || ComparatorType.EQ, {validators: [Validators.required], updateOn: "change"});

    public comparatorStrings = {
        [ComparatorType.EQ]: 'gleich',
        [ComparatorType.LTE]: 'kleiner gleich',
        [ComparatorType.GTE]: 'größer gleich'
    };

    private _optionsByComparatorType: { [comparator: string]: FilterOptionInterface[] } = {};

    public get label(): string {
        return this.selectedComparatorCtl && this.selectedComparatorCtl.value
            ? this.comparatorStrings[this.selectedComparatorCtl.value]
            : "Bitte Komparator wählen …";
    }

    public get filteredFilterOptions(): FilterOptionInterface[] {
        if(!this.selectedFilterFieldCtl.value) {
            return [];
        }

        if (!this.comparator) {
            return this.selectedFilterFieldCtl.value?.options;
        }

        if (!this._optionsByComparatorType.hasOwnProperty(this.comparator)) {
            this._optionsByComparatorType[this.comparator] = (this.selectedFilterFieldCtl.value?.options ?? []).filter(
                (option: FilterOptionInterface) => option.type === this.comparator
            );
            this._optionsByComparatorType[this.comparator].sort((optionA, optionB) => {
                let labelA, labelB;

                if ([OptionValueType.FLOAT, OptionValueType.INTEGER, OptionValueType.YEAR, OptionValueType.BYTE].includes(this.selectedFilterFieldCtl?.value?.optionValueType)) {
                    labelA = optionA.value;
                    labelA = optionB.value;
                } else {
                    labelA = optionA.label.toString().toLocaleLowerCase().replace('š', 's');
                    labelB = optionB.label.toString().toLocaleLowerCase().replace('š', 's');
                }

                if (labelA === labelB) {
                    return 0;
                }

                return labelA < labelB ? -1 : 1;
            });
        }

        return this._optionsByComparatorType[this.comparator];
    }

    public constructor(
        private filterService: FilterService
    ) {
    }

    public ngOnInit() {
        if (this.setting) {
            if (!this.setting.comparator) {
                this.setting.comparator = ComparatorType.EQ;
            }

            this.selectedFilterFieldCtl.patchValue(this.setting.filterField);
            this.selectedComparatorCtl.patchValue(this.setting.comparator);
            this.selectedFilterValueCtl.patchValue(this.setting.filterValue);
        }
        this.selectedFilterFieldCtl.valueChanges.subscribe(newValue => {
            if (this.setting.filterField !== newValue) {
                this._optionsByComparatorType = {};
                this.setting.filterField = newValue;
                if (this.isSettingComplete()) {
                    this.changed.emit(this.setting);
                }
            }
        });
        this.selectedComparatorCtl.valueChanges.subscribe(newValue => {
            if (this.setting.comparator !== newValue) {
                this.comparator = this.setting.comparator = newValue;
                if (this.isSettingComplete()) {
                    this.changed.emit(this.setting);
                }
            }
        });
        this.selectedFilterValueCtl.valueChanges.subscribe(newValue => {
            if (this.setting.filterValue !== newValue) {
                this.setting.filterValue = newValue;
                if (this.isSettingComplete()) {
                    this.changed.emit(this.setting);
                }
            }
        });
    }

    private isSettingComplete(): boolean {
        return !!this.setting.filterField
            &&
            !!this.setting.comparator
            &&
            !!this.setting.filterValue;
    }

    public isInputType(type: string): boolean {
        return this.selectedFilterFieldCtl.value instanceof FilterFieldModel
            && this.selectedFilterFieldCtl.value.inputType === type;
    }

    public onChangeValueTextInput($event: InputEvent) {
        const newOption = this.filterService.getOption(
            this.selectedFilterFieldCtl.value,
            ($event.target as HTMLInputElement).value,
            ComparatorType.EQ
        );
        this.selectedFilterValueCtl.patchValue(newOption);
    }
}
