import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatCheckbox} from '@angular/material/checkbox';
import {ComparatorType} from '../../gw-search-lib/enum/comparator-type.enum';
import {StoredResultSettings} from '../../gw-search-lib/type/stored-result-settings';
import {ViewType} from '../../gw-pipe-lib/enum/view-type.enum';
import {SortingType} from '../../gw-search-lib/enum/sorting-type.enum';
import {FilterFieldInterface} from '../../gw-search-lib/interface/filter-field-interface';
import {FilterOptionInterface} from '../../gw-search-lib/interface/filter-option-interface';
import {FilterOptionModel} from '../../gw-search-lib/model/filter-option.model';
import {FilterService} from '../../gw-search-lib/service/filter.service';
import {IResultFilterSetting} from "../../gw-pipe-lib/component/result-filter-setting/result-filter-setting-interface";

@Component({
    //selector: 'gw-result-settings',
    templateUrl: './result-settings.component.html',
    styleUrls: ['./result-settings.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class ResultSettingsComponent implements OnInit {

    @Input('gw-name') name: string;

    @Input('gw-id') id: string;

    private _settings: StoredResultSettings = {
        filters: [],
        sorting: {
            field: 'price_gross',
            order: SortingType.ASC
        },
        perPage: 21,
        scrollType: "perpage",
        isGlobal: true,
        startPos: 0,
        viewType: ViewType.BOX
    };

    @Input('gw-settings') set settings(settings: string) {
        this._settings = Object.assign(this._settings, JSON.parse(settings));
    };

    private _filterSettings: IResultFilterSetting[] = [];

    public set filterSettings(filterSettings: IResultFilterSetting[]) {

        this._filterSettings = filterSettings;
        this._settings.filters = filterSettings
            .filter(filterSetting => !!filterSetting.filterValue && !!filterSetting.filterField && !!filterSetting.comparator)
            .map(filterSetting => {
                return {
                    field: filterSetting.filterValue.fieldName,
                    comparator: filterSetting.filterValue.type.toUpperCase() as ComparatorType,
                    value: filterSetting.filterValue.value
                }
            });

        this.settingsAsJson = JSON.stringify(this._settings);
    }

    public get filterSettings(): IResultFilterSetting[] {
        return this._filterSettings;
    }

    public set sortingSetting(sortingSetting: { field: string, order: SortingType }) {
        this._settings.sorting = sortingSetting;
        this.settingsAsJson = JSON.stringify(this._settings);
    };

    public get sortingSetting(): { field: string, order: SortingType } {
        return this._settings.sorting || {field: 'price_gross', order: SortingType.ASC};
    };

    public set viewTypeSetting(viewType: ViewType) {
        this._settings.viewType = viewType;
        this.settingsAsJson = JSON.stringify(this._settings);
    };

    public get viewTypeSetting(): ViewType {
        return this._settings.viewType || ViewType.BOX;
    }

    public set scrollTypeSetting(scrollTypeSetting: 'perpage' | 'infinite') {
        this._settings.scrollType = scrollTypeSetting;
        this.settingsAsJson = JSON.stringify(this._settings);
    }

    public get scrollTypeSetting(): 'perpage' | 'infinite' {
        return this._settings.scrollType || 'perpage';
    }

    public set perPageSetting(perPage: number) {
        this._settings.perPage = perPage;
        this.settingsAsJson = JSON.stringify(this._settings);
    }

    public get perPageSetting(): number {
        return this._settings.perPage || 4;
    }

    public set isGlobalSetting(isGlobal: boolean) {
        this._settings.isGlobal = isGlobal;
        this.settingsAsJson = JSON.stringify(this._settings);
    }

    public get isGlobalSetting(): boolean {
        return this._settings.isGlobal ?? true;
    }

    public settingsAsJson: string = '';

    public availableFilterFields: FilterFieldInterface[] = [];

    public areForAllFilterSettingsDefined: boolean = true;

    @ViewChild('observeGlobalFiltersElement') public observeGlobalFiltersElement: MatCheckbox;
    public observeGlobalFiltersCtl: FormControl = new FormControl(false);

    constructor(
        private filterService: FilterService
    ) {
    }

    ngOnInit() {
        this.filterService.filterConfiguration$.subscribe(filterFields => {
            this.availableFilterFields = Array.from(filterFields.values());

            if (this._settings) {
                // set filters
                this._settings.filters = this._settings.filters || [];
                this.observeGlobalFiltersCtl.patchValue(this.isGlobalSetting);
                this.observeGlobalFiltersCtl.valueChanges.subscribe(newValue => {
                    this.isGlobalSetting = newValue;
                });

                const filterValues = this._settings.filters.map(filter => this.filterService.getOptionByFieldName(filter.field, filter.value, filter.comparator));
                this.onFilterConfigurationLoaded(filterValues);
            }
        });
    }

    /**
     *
     * @param removingFilterSetting
     */
    public onClickRemove(removingFilterSetting: IResultFilterSetting) {
        const index = this.filterSettings.indexOf(removingFilterSetting);

        if (index > -1) {
            this.filterSettings.splice(index, 1);
            this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
        }
    }

    /**
     *
     */
    public onClickAdd() {
        this.filterSettings = [...this.filterSettings, {filterField: null, filterValue: null, comparator: ComparatorType.EQ}];
        this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
    }

    /**
     *
     * @param filterValues
     */
    private onFilterConfigurationLoaded(filterValues: FilterOptionInterface[]) {
        filterValues.forEach(filterValue => {
            filterValue.select = true;
            this.filterSettings = [...this.filterSettings, {
                filterField: (<FilterOptionModel>filterValue).field,
                filterValue: filterValue,
                comparator: (<FilterOptionModel>filterValue).type
            }];
        });
        this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
    }

    /**
     *
     */
    private checkForAllFilterSettingsDefined(): boolean {
        for (const filterSetting of this.filterSettings) {
            if (!filterSetting.filterField || !filterSetting.filterValue || !filterSetting.comparator) {
                return false;
            }
        }
        this.filterSettings = [...this.filterSettings];

        return true;
    }

    public onFilterSettingChanged(filterSetting: IResultFilterSetting) {
        this.areForAllFilterSettingsDefined = this.checkForAllFilterSettingsDefined();
    }

    public onScrollingTypeSettingChanged(scrollingType: 'perpage' | 'infinite') {
        this.scrollTypeSetting = scrollingType;
    }
}
