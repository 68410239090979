import {Inject, Injectable} from '@angular/core';
import {WINDOW} from 'projects/gw-web-components/src/app/gw-configuration-lib/reference/window-ref';
import {filter, share, switchMap} from 'rxjs/operators';
import {fromEvent, Observable, of, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewportService {

  protected _scrollSubject: Subject<MouseEvent> = new Subject<MouseEvent>();
  protected _scrollObservable: Observable<MouseEvent>;

  public get scroll$(): Observable<MouseEvent> {
    if(!this._scrollObservable) {
      this.initScrollObservable();
    }
    return this._scrollObservable;
  }

  constructor(
    @Inject(WINDOW) protected windowRef: Window
  ) {

  }

  /**
   *
   */
  protected initScrollObservable(): void {
    fromEvent<Event>(this.windowRef, 'scroll').subscribe(event => this._scrollSubject.next(event as MouseEvent));

    let lastTop: number|null = 0;

    this._scrollObservable = this._scrollSubject.asObservable()
      .pipe(
        switchMap((event) => of(event)),
        filter((event: MouseEvent) => {
          const currentTop = (<HTMLDocument>event.target).body.getBoundingClientRect().top,
                distance = Math.abs(lastTop - currentTop),
                isOverLimit = distance > 50;
          if(isOverLimit) {
            lastTop = currentTop;
          }
          return isOverLimit;
        }),
        share()
      );
  }
}
