import { Directive, ElementRef, Input } from '@angular/core';
import { TranslationService } from '../service/translation.service';

@Directive({
  selector: '[gw-translate]'
})
export class TranslateDirective {

  @Input('gw-translate') translationId: string;

  @Input('gw-translate-args') translationArguments: any[] = [];

  constructor(
    translationService: TranslationService,
    elementRef: ElementRef
  ) {
    if(!(typeof this.translationId === "string" && this.translationId.length > 0)) {
      this.translationId = 'no translation id defined for »gw-translate«';
    }
    (elementRef.nativeElement as HTMLElement).innerText = translationService.translate(this.translationId, this.translationArguments);
  }
}
