import {Component, Inject, Input, LOCALE_ID, OnInit} from "@angular/core";
import {EventBusService} from "../../gw-search-lib/service/event-bus.service";
import {ResultState} from "../../gw-search-lib/interface/result-state";
import {ActionType} from "../../gw-pipe-lib/enum/action-type.enum";
import {BehaviorSubject} from "rxjs";
import {Helpers} from "../../gw-pipe-lib/helpers";
import {ComparatorType} from "../../gw-search-lib/enum/comparator-type.enum";
import {FilterOptionInterface} from "../../gw-search-lib/interface/filter-option-interface";
import {InputType} from "../../gw-search-lib/enum/input-type.enum";
import {OptionValueType} from "../../gw-search-lib/enum/field-value-type.enum";
import {ChangeEventContext} from "../../gw-pipe-lib/types";

type FilterOptionTag = {
    hash: string,
    label: string,
    option: FilterOptionInterface
};
type FormatSetting = {
    field: string,
    function?: 'number' | 'date' | 'horsepower',
    functionParam?: string,
    unit?: string
}

@Component({
    selector: 'gw-active-filters',
    templateUrl: './active-filter-options.component.html',
    styleUrls: ['./active-filter-options.component.scss'],
    host: {"class": "mt-active-filter-options"}
})
export class ActiveFilterOptionsComponent implements OnInit {

    private _useGlobalResultState: boolean = true;

    private _formatSettings: FormatSetting[] = [];

    @Input('gw-separate')
    public set useGlobalResultState(hasOwnResultState: any) {
        if(typeof hasOwnResultState === 'string' && hasOwnResultState.length > 0) {
            this._useGlobalResultState = !['true', '1', 'hasOwnResultState'].includes(hasOwnResultState);
        }
        if (typeof hasOwnResultState === 'boolean') {
            this._useGlobalResultState = !hasOwnResultState;
        }
    }

    @Input('gw-format-settings')
    public set formatSettings(settings: string) {
        if(typeof settings === "string") {
            try {
                this._formatSettings = JSON.parse(settings);
            } catch (e) {
                throw new Error('Settings for active filters component are non-well-formed');
            }
        }
    }

    public filterOptionTags$ = new BehaviorSubject<FilterOptionTag[]>([]);

    public constructor(
        private _eventBus: EventBusService,
        @Inject(LOCALE_ID) private localeId: string
    ) {
    }

    public ngOnInit(): void {
        this._eventBus.on<ResultState>(ActionType.RESULT_STATE_READY, resultStateEvent => {
            this._eventBus.on<ResultState>(ActionType.RESULT_STATE_CHANGED, resultStateChangeEvent => {
                this._onResultStateChange(Array.from(resultStateChangeEvent.payload.filterOptions.values()))
            });
            this._onResultStateChange(Array.from(resultStateEvent.payload.filterOptions.values()));
        });

    }

    public removeFilterOption(option: FilterOptionInterface): void {
        this._eventBus.broadcast<ChangeEventContext>(this, ActionType.FILTERS_CHANGE, {
            remove: [option],
            add: []
        });
    }

    private static _getLabelPrefixByComparator(type: ComparatorType): string {
        switch (type) {
            case ComparatorType.LTE:
                return 'bis';
            case ComparatorType.GTE:
                return 'von';
            default:
                return '';
        }
    }

    private _onResultStateChange(filterOptions: FilterOptionInterface[]): void {
        const tags = Array.from(filterOptions.values()).map<FilterOptionTag>(filterOption => {
            const formatSetting = this._formatSettings.find(setting => setting.field === filterOption.fieldName) || {
                unit: null,
                function: null,
                functionParam: null,
                field: filterOption.fieldName
            };

            return {
                hash: filterOption.hash,
                label: this._getLabel(filterOption, formatSetting),
                option: filterOption
            };
        });
        this.filterOptionTags$.next(tags);
    }

    private _getLabel(filterOption: FilterOptionInterface, formatSetting: FormatSetting) {
        return ActiveFilterOptionsComponent._getLabelPrefixByComparator(filterOption.type) + ' ' + Helpers.formatOptionLabel(
            filterOption,
            formatSetting.function,
            formatSetting.unit,
            this.localeId,
            formatSetting.functionParam
        );
    }
}
