import { Injectable } from '@angular/core';
import { ActionType } from 'projects/gw-web-components/src/app/gw-pipe-lib/enum/action-type.enum';
import { EventBusService } from './event-bus.service';
import { ViewType } from '../../gw-pipe-lib/enum/view-type.enum';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewTypeService {

  protected viewType: ViewType;
  static readonly VIEW_TYPE = 'view_type';
  public ready$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected localStorageService: LocalStorageService,
    protected eventBusService: EventBusService
  ) {
    if(this.localStorageService.has(ViewTypeService.VIEW_TYPE)) {
      this.viewType = this.localStorageService.get(ViewTypeService.VIEW_TYPE);
    }
    this.eventBusService.on(ActionType.VIEWTYPE_BOX, () => this.onViewTypeChanged(ViewType.BOX));
    this.eventBusService.on(ActionType.VIEWTYPE_LIST, () => this.onViewTypeChanged(ViewType.LIST));

    if(this.ready$.getValue() !== true) {
      this.ready$.next(true);
    }
  }

  protected onViewTypeChanged(viewType: ViewType): void {
    this.localStorageService.set(ViewTypeService.VIEW_TYPE, viewType);
    this.viewType = viewType;
  }

  public get(): ViewType | null {
    return this.viewType;
  }

  public isSet(): boolean {
    return !!this.viewType;
  }
}
