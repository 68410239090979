import { CarSearchResponse } from '../interface/car-search-response';
import { CarInterface } from '../interface/car-interface';
import camelCase from 'lodash-es/camelCase';

/**
 *
 */
export class CarModel implements CarInterface {

  public brand: string;
  public carriageVolume: number;
  public category: string;
  public colorExterior: string;
  public colorInterior: string;
  public colorSeatCover: string;
  public company: number;
  public description: string[];
  public drivingCab: string;
  public emissionEfficiencyClass: number;
  public emissionStandard: number;
  public emissions: number;
  public engineDisplacement: number;
  public fuelConsumptionAverage: number;
  public fuelConsumptionInnerCity: number;
  public fuelConsumptionOuterCity: number;
  public fuelType: string;
  public hasActiveParkingAssistant: boolean;
  public hasAlloyWheels: boolean;
  public hasBed: boolean;
  public hasBluetooth: boolean;
  public hasCameraBackUp: boolean;
  public hasColorMetallic: boolean;
  public hasCruiseControl: boolean;
  public hasDoorRear: boolean;
  public hasDoorSlide: boolean;
  public hasFourWheel: boolean;
  public hasFridge: boolean;
  public hasHeadlightsFog: boolean;
  public hasHeadlightsLed: boolean;
  public hasHeadlightsXenon: boolean;
  public hasHeatingAuxiliary: boolean;
  public hasHud: boolean;
  public hasLockingCentral: boolean;
  public hasLockingKeyless: boolean;
  public hasMediasystemCdPlayer: boolean;
  public hasMirrorsElectric: boolean;
  public hasNavigation: boolean;
  public hasPackageSport: boolean;
  public hasRetarder: boolean;
  public hasRoofBig: boolean;
  public hasRoofHigh: boolean;
  public hasRoofRails: boolean;
  public hasRoofSun: boolean;
  public hasSeatElectricAdjustment: boolean;
  public hasSeatHeating: boolean;
  public hasSeatVentilation: boolean;
  public hasSecondAircondition: boolean;
  public hasSpeakerphone: boolean;
  public hasStartStopAutomatic: boolean;
  public hasSuspensionAir: boolean;
  public hasSuspensionAirmatic: boolean;
  public hasTowBar: boolean;
  public hasVat: boolean;
  public id: number;
  public images: string[] = [];
  public isDemonstrator: boolean;
  public isJungerStern: boolean;
  public isOneDayReg: boolean;
  public isOneYear: boolean;
  public isSold: boolean;
  public isUsedCar: boolean;
  public mileage: number;
  public model: string;
  public numberDoors: number;
  public numberSeats: number;
  public payload: number;
  public power: number;
  public priceByList: number;
  public seatCoverMaterial: string;
  public segment: string;
  public sellingPrice: number;
  public subtitle: string;
  public title: string;
  public transmissionType: string;
  public vatValue: number;

  constructor(car: CarSearchResponse) {

    let camelCasePropertyName: string;
    for(let propertyName in car) {
      camelCasePropertyName = camelCase(propertyName);
      this[camelCasePropertyName] = car[propertyName];
    }
  }
}
