export enum OptionValueType {
  KEYWORD = 'KEYWORD',
  BOOLEAN = 'BOOLEAN',
  INTEGER = 'INTEGER',
  SHORT   = 'SHORT',
  FLOAT   = 'FLOAT',
  DATE    = 'DATE',
  YEAR    = 'YEAR',
  BYTE    = 'BYTE',
}
