import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { ToolBoxService } from 'projects/gw-web-components/src/app/gw-search-lib/service/tool-box.service';

@Pipe({
  name: 'horsepower'
})
export class HorsepowerPipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) protected localeId: string
  ) {}

  transform(value: string, unit: string = 'PS', ...args: any[]): string {
    const val = parseInt(value);
    return ToolBoxService.formatHorsePower(val, this.localeId, unit, ...args);
  }

}
