import { AbstractHttpCache } from './abstract-http.cache';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class HttpCache extends AbstractHttpCache {
  private subjects: Map<string, BehaviorSubject<HttpResponse<any>>> = new Map();

  private responses: Map<string, HttpResponse<any>> = new Map();

  /**
   *
   * @param req
   */
  private static generateCacheKey(req: HttpRequest<any>): string {
    let cacheKey: string[] = [];

      cacheKey.push(req.urlWithParams);
      if(['POST', 'PUT', 'PATCH'].includes(req.method)) {
        cacheKey.push(JSON.stringify(req.body));
      }
      return cacheKey.join(':');
  }

  /**
   *
   * @param req
   * @param response
   */
  public applySubject(req: HttpRequest<any>, response: any): void {
    const cacheKey = HttpCache.generateCacheKey(req);
    if (this.subjects.has(cacheKey)) {
      this.subjects.get(cacheKey).next(response);
    }
  }

  /**
   *
   * @param req
   */
  public getSubject(req: HttpRequest<any>): BehaviorSubject<HttpResponse<any>> {
    if (this.hasSubject(req) === false) {
      this.createSubject(req);
    }
    return this.subjects.get(HttpCache.generateCacheKey(req));
  }

  /**
   *
   * @param req
   */
  public hasSubject(req: HttpRequest<any>): boolean {
    return this.subjects.has(HttpCache.generateCacheKey(req));
  }

  /**
   *
   * @param req
   * @param resp
   */
  public set(req: HttpRequest<any>, resp: HttpResponse<any>): void {
    this.responses.set(HttpCache.generateCacheKey(req), resp);
  }

  /**
   *
   * @param req
   */
  public has(req: HttpRequest<any>): boolean {
    return this.responses.has(HttpCache.generateCacheKey(req));
  }

  /**
   *
   * @param req
   */
  public get(req: HttpRequest<any>): HttpResponse<any> {
    if (this.has(req)) {
      return this.responses.get(HttpCache.generateCacheKey(req));
    }
    return null;
  }

  /**
   *
   * @param req
   */
  protected createSubject(req: HttpRequest<any>): void {
    const subject = new BehaviorSubject<HttpResponse<any>>(null);
    this.subjects.set(HttpCache.generateCacheKey(req), subject);
  }
}
