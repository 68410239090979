import { Inject, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WINDOW } from '../gw-configuration-lib/reference/window-ref';
import { TranslationService } from './service/translation.service';
import { TranslateDirective } from './directive/translate.directive';
import { GwConfigurationLibModule } from '../gw-configuration-lib/gw-configuration-lib.module';

@NgModule({
  imports: [
    CommonModule,
    GwConfigurationLibModule
  ],
  providers: [
    TranslationService
  ],
  declarations: [
    TranslateDirective,
  ],
  exports: [
    TranslateDirective
  ]
})
export class GwTranslationLibModule {

  constructor(
    @Inject(WINDOW) protected window: Window,
    protected injector: Injector
  ) {
    // customElements.define('gw-translate', createCustomElement(TranslateComponent, { injector: this.injector }));
  }

}
