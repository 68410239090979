import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import first from 'lodash-es/first';
import groupBy from 'lodash-es/groupBy';
import isObject from 'lodash-es/isObject';
import {SortingType} from 'projects/gw-web-components/src/app/gw-search-lib/enum/sorting-type.enum';
import {BaseResponse} from 'projects/gw-web-components/src/app/gw-search-lib/interface/base-response';
import {ComparatorType} from '../enum/comparator-type.enum';
import {SearchJob} from '../type/search-job.type';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AppConfigService} from '../../gw-configuration-lib/service/app-config.service';
import {BodyRequestParameters} from '../interface/body-request-parameters';
import {CarsSearchResponse} from '../interface/cars-search-response';
import {FilterOptionInterface} from '../interface/filter-option-interface';
import {SortingValue} from '../interface/sorting-value';
import {FilterService} from './filter.service';
import {EventBusService} from "./event-bus.service";
import {ResultState} from "../interface/result-state";
import {ActionType} from "../../gw-pipe-lib/enum/action-type.enum";
import {LocalStorageService} from "./local-storage.service";

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    readonly CAR_BASE_URI = `${this.appConfigService.get<string>('apiScheme', 'https')}://${this.appConfigService.get<string>('apiHost', 'localhost')}${this.appConfigService.get<string>('basePath', '/rest/api/gw/v1')}`;
    readonly CAR_URI_SEARCH = `${this.CAR_BASE_URI}/search`;

    protected _selectedFilterOptions: Map<string, FilterOptionInterface>;

    public ready$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    protected set selectedFilterOptions(selectedFilterOptions: Map<string, FilterOptionInterface>) {
        this._selectedFilterOptions = selectedFilterOptions;
        this.queryChanged$.next(true);
    };

    private sorting: SortingValue;

    public queryChanged$: Subject<boolean> = new Subject<boolean>();

    // START POS
    protected _startPos: number = 0;
    public get startPos(): number {
        return this._startPos;
    }

    public set startPos(newStartPos: number) {
        if (typeof newStartPos !== 'number') {
            return;
        }
        this._startPos = newStartPos;
        this.eventBus.broadcast<number>(this, ActionType.RESULT_OFFSET, this._startPos);
    }

    // SIZE
    public size$: BehaviorSubject<number> = new BehaviorSubject(this.appConfigService.get('perPage', 9));
    protected _size: number;
    public get size(): number {
        return this._size;
    }

    public set size(newSize: number) {
        if (typeof newSize !== "number") {
            return;
        }
        this._size = newSize;
        this.size$.next(newSize);
    }

    constructor(
        protected eventBus: EventBusService,
        protected appConfigService: AppConfigService,
        protected filterService: FilterService,
        protected httpClient: HttpClient,
        protected localStorageService: LocalStorageService
    ) {
        this._initialize();
    }

    /**
     *
     * @param type
     * @param searchJob
     */
    public search<T>(type: (new (response: BaseResponse) => T), searchJob: SearchJob): Observable<T[]> {
        const requestParams = this.buildParamsForBodyRequest(
            searchJob.filterOptions,
            searchJob.sorting,
            searchJob.offset,
            searchJob.limit
        );

        if (searchJob.isGlobal) {
            this.filterService.requestCountsForSelectedFilterOptions(requestParams);
        }
        return (searchJob.limit > 0) ? this.requestingSearchResult<T>(type, requestParams, searchJob.isGlobal) : of<T[]>([]);
    }

    /**
     *
     * @param type
     * @param requestParams
     * @param isGlobal
     */
    protected requestingSearchResult<T>(type: (new (response: BaseResponse) => T), requestParams: BodyRequestParameters, isGlobal: boolean = true): Observable<T[]> {
        return this.httpClient
            .post<CarsSearchResponse>(this.CAR_URI_SEARCH, requestParams)
            .pipe(
                map<CarsSearchResponse, T[]>(response => {
                    if (isGlobal) {
                        this.filterService.total = typeof response.total === "number" ? response.total : response.total.value;
                        this.filterService.count = response.count;
                        this.localStorageService.set('gw-result-list', response.idsBy);
                    }

                    return response.result.map(carSearchResponse => new type(carSearchResponse));
                }),
                catchError(errorResponse => {
                    console.error(errorResponse);
                    return of([]);
                })
            );
    }

    /**
     *
     */
    private _initialize() {
        this.filterService.selectedFilterOptions$.subscribe(selectedFilterOptions => this._onSelectedFilterChanged(selectedFilterOptions));
        combineLatest([this.filterService.ready$]).subscribe(() => {
            if (this.ready$.getValue() !== true) {
                this.ready$.next(true);
            }
        });

        this.eventBus.on<ResultState>(ActionType.RESULT_STATE_READY, resultStateEvent => {
            if (resultStateEvent.payload.offset !== this._startPos) {
                this._startPos = resultStateEvent.payload.offset;
            }
            if (resultStateEvent.payload.limit !== this.size) {
                this.size = resultStateEvent.payload.limit;
            }
        });

        this.eventBus.on<ResultState>(ActionType.RESULT_STATE_CHANGED, resultStateEvent => {
            if (resultStateEvent.payload.limit !== this.size) {
                this.size = resultStateEvent.payload.limit;
            }
        });
    };

    private _onSelectedFilterChanged(selectedFilterOptions: Map<string, FilterOptionInterface>) {
        this.selectedFilterOptions = selectedFilterOptions;
    }

    public buildParamsForBodyRequest(filterOptions: Map<string, FilterOptionInterface>, sorting: { field: string, order: SortingType } = null, offset = 0, limit = 20): BodyRequestParameters {
        let requestParams: BodyRequestParameters = {};

        const filterParamsForBodyRequest = this.buildFilterParamsForBodyRequest(filterOptions);
        if (isObject(filterParamsForBodyRequest)) {
            requestParams = Object.assign({}, filterParamsForBodyRequest);
        }

        if (!sorting) {
            sorting = this.sorting;
        }

        if (sorting) {
            requestParams = Object.assign({}, requestParams, this.buildSortingParamForBodyRequest(sorting));
        }

        requestParams._start = offset;
        requestParams._limit = limit;
        requestParams._idsBy = 'slug';

        return requestParams;
    }

    /**
     *
     * @param selectedFilterOptions
     */
    protected buildFilterParamsForBodyRequest(selectedFilterOptions: Map<string, FilterOptionInterface>): BodyRequestParameters {
        if (!selectedFilterOptions || selectedFilterOptions.size < 1) {
            return null;
        }
        const requestBodyParams = {
                _filter: {}
            },
            values = (Array.from(selectedFilterOptions)).map(([key, filterValue]) => {
                return filterValue;
            }),
            groupByFieldName = groupBy(values, (filterValue: FilterOptionInterface) => {
                let key = '';
                switch (filterValue.type) {
                    case ComparatorType.LTE:
                        key = `${filterValue.fieldName}_to`;
                        break;
                    case ComparatorType.GTE:
                        key = `${filterValue.fieldName}_from`;
                        break;
                    default:
                        key = filterValue.fieldName;
                }
                return key;
            });

        for (let fieldName in groupByFieldName) {
            if (groupByFieldName.hasOwnProperty(fieldName)) {
                let groupedOptions = groupByFieldName[fieldName],
                    requestValue;

                if (groupedOptions.length === 1) {
                    const option = first(groupedOptions);
                    requestValue = option.value;
                } else {
                    requestValue = groupedOptions.map(option => option.value);
                }
                requestBodyParams._filter[fieldName] = requestValue;
            }
        }

        return requestBodyParams;
    }

    /**
     *
     * @param sorting
     */
    protected buildSortingParamForBodyRequest(sorting: SortingValue = null): BodyRequestParameters {
        const requestBodyParams = {};

        if (!sorting) {
            return requestBodyParams;
        }

        const sort = {};
        sort[sorting.field] = {
            order: sorting.order.toLowerCase()
        };

        return {
            _sort: [sort]
        };
    }

    protected getFirstValidSize(perPageSizes: number[]) {
        return perPageSizes.find(perPageSize => perPageSize !== 0);
    }
}
