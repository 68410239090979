import {Component, OnInit, Input, Output, OnDestroy} from '@angular/core';
import { TranslationService } from 'projects/gw-web-components/src/app/gw-translation-lib/service/translation.service';
import { ViewType } from '../../enum/view-type.enum';
import { FormControl } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'gw-result-type-setting',
  templateUrl: './result-type-setting.component.html',
  styleUrls: ['./result-type-setting.component.scss']
})
export class ResultTypeSettingComponent implements OnInit, OnDestroy {

  @Input()
  protected viewTypeSetting: ViewType;

  @Output()
  public viewTypeSettingChange: EventEmitter<ViewType> = new EventEmitter<ViewType>();

  public placeholderSorting = this.translationService.translate('resulttypesettings.sorting.label');

  public availableViewTypes: ViewType[] = [
    ViewType.BOX,
    ViewType.LIST
  ];

  public paginationTypeCtl: FormControl = new FormControl();

  private _translatedLabels: Map<string, string>;

  private _destroy$ = new Subject<void>();

  constructor(
    protected translationService: TranslationService
  ) { }

  ngOnInit() {
    this._translatedLabels = new Map<string, string>(this.availableViewTypes.map<[string, string]>((viewType: ViewType) => [
      viewType,
      this.translationService.translate(`resultsettings.pagination.viewtype.${viewType.toLowerCase()}`)
    ]));
    if(this.viewTypeSetting) {
      this.paginationTypeCtl.patchValue(this.viewTypeSetting, {emitEvent: false});
    }
    this.paginationTypeCtl.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(
        newValue => this.viewTypeSettingChange.emit(newValue)
    );
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public translateLabel(viewType: ViewType): string {
    return this._translatedLabels.get(viewType);
  }

}
