import { HashService } from 'projects/gw-web-components/src/app/gw-search-lib/service/hash.service';
import { FilterValueSelectEvent } from '../type/filter-value-select-event.type';
import { Observable, Subject } from 'rxjs';
import { ComparatorType } from '../enum/comparator-type.enum';
import { FilterFieldInterface } from '../interface/filter-field-interface';
import { FilterOptionInterface } from '../interface/filter-option-interface';
import { FilterValueResponse } from '../interface/filter-value-response';
import {TranslationService} from '../../gw-translation-lib/service/translation.service';

/**
 * filter option as model
 */
export class FilterOptionModel implements FilterOptionInterface {

  protected _subject: Subject<FilterOptionInterface> = new Subject<FilterOptionInterface>();

  protected _selectSubject: Subject<FilterValueSelectEvent> = new Subject<FilterValueSelectEvent>();

  public fieldName: string;

  public selected: boolean = false;

  public type: ComparatorType = ComparatorType.EQ;

  public value: any = null;

  public label: string = null;

  public label_translated: string = null;

  public field: FilterFieldInterface = null;

  public fixed: boolean = false;

  protected _hash: string;
  public get hash(): string {
    return this._hash;
  }

  protected _count: number = 0;

  protected _changed$: Observable<FilterOptionInterface> = this._subject.asObservable();

  protected _selectChanged$: Observable<FilterValueSelectEvent> = this._selectSubject.asObservable();

  public get selectChanged$(): Observable<FilterValueSelectEvent> {
    return this._selectChanged$;
  }

  /**
   *
   */
  public get changed$(): Observable<FilterOptionInterface> {
    return this._changed$;
  }

  /**
   *
   * @param newCount
   */
  public set count(newCount: number) {
    this._count = newCount;
    this._subject.next(this);
  }

  /**
   *
   */
  public get count(): number {
    return this._count;
  }

  /**
   *
   * @param select
   */
  public set select(select: boolean) {
    const selectChanged = this.selected !== select,
          oldSelect = this.selected;

    this.selected = select;
    if(selectChanged) {
      this._subject.next(this);
      this._selectSubject.next({ filterValue: this, select: select, oldSelect: oldSelect });
    }
  }

  /**
   *
   */
  public get select(): boolean {
    return this.selected;
  }

  /**
   *
   * @param filterField
   * @param filterOptionResponse
   */
  public constructor(filterField: FilterFieldInterface, filterOptionResponse: FilterValueResponse) {
    this.label = (filterOptionResponse.type.toUpperCase() === ComparatorType.EQ) ? filterOptionResponse.label : filterOptionResponse.value;
    this.label_translated = (filterOptionResponse.type.toUpperCase() === ComparatorType.EQ) ? filterOptionResponse.label_translated : filterOptionResponse.value;
    this.fieldName = filterOptionResponse.field;
    this.field = filterField;
    this.selected = false;
    this.type = filterOptionResponse.type.toUpperCase() as ComparatorType;
    this.value = filterOptionResponse.value;
    this._count = filterOptionResponse.count;
    this._hash = HashService.md5ForFilterOption(this);
  }

  /**
   *
   */
  public asJsonString(): string {
    return JSON.stringify({
      fieldName: this.fieldName,
      type: this.type,
      value: this.value
    });
  }

}
