import {Component, OnInit, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {Input} from '@angular/core';
import {ViewportService} from 'projects/gw-web-components/src/app/gw-pipe-lib/service/viewport.service';
import {WINDOW} from '../../../gw-configuration-lib/reference/window-ref';
import {AppConfigService} from '../../../gw-configuration-lib/service/app-config.service';
import {ElementRef} from '@angular/core';
import {Inject} from '@angular/core';
import {fromEvent} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import trimEnd from "lodash-es/trimEnd";

@Component({
    selector: 'gw-image',
    template: `<img #imgEl class="{{cssClasses}}" [src]="src" title="{{src}}" [attr.alt]="alt" [attr.width]="width" [attr.height]="height">`,
    styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit, OnChanges {

    @Input('gw-src')
    public set gwSrc(gwSrc: string) {
        const apiScheme = trimEnd(this.appConfigService.get<string>('apiScheme', 'http'), '/'),
            apiHost = trimEnd(this.appConfigService.get<string>('apiHost', 'localhost'), '/'),
            basePath = trimEnd(this.appConfigService.get<string>('basePath', '/rest/api/gw/v1'), '/'),
            imageSubPath = trimEnd(this.appConfigService.get<string>('result.item.image.subPath', '/images'), '/');

        this._gwSrc = `${apiScheme}://${apiHost}${basePath}${imageSubPath}${gwSrc}`;
    }

    public get gwSrc(): string {
        return this._gwSrc;
    }

    @ViewChild('imgEl', {static: true})
    public imageElement: ElementRef;

    public config: { thumbnail: string, placeholder?: string } = {thumbnail: '/'};

    @Input('gw-config')
    public set gwConfig(config: string) {
        try {
            if (config) {
                this.config = JSON.parse(config);
                if (this.config.thumbnail.length > 0) {
                    this.config.thumbnail = `/${this.config.thumbnail}/`;
                }
                return;
            }
        } catch (e) {
        }
    };

    @Input('gw-class')
    public cssClasses = '';

    @Input('gw-alt')
    public alt: string = '';

    @Input('gw-width')
    public width: number = 0;

    @Input('gw-height')
    public height: number = 0;

    public src: string = `https://via.placeholder.com/${this.width || 3}x${this.height || 2}/000000/000000`;
    public inViewport: boolean = false;

    private _gwSrc: string = '';

    private element: Element;

    private readonly loadByRelativeSpaceFrom: number;

    protected readonly pathToImageThumbnails: string;

    constructor(
        protected appConfigService: AppConfigService,
        protected elementRef: ElementRef,
        protected viewportService: ViewportService,
        @Inject(WINDOW) protected windowRef: Window
    ) {
        this.loadByRelativeSpaceFrom = this.appConfigService.get<number>('result.item.image.loadByRelativeSpaceFrom', 70);

        const apiScheme = this.appConfigService.get<string>('apiScheme', 'http'),
            apiHost = this.appConfigService.get<string>('apiHost', 'localhost'),
            basePath = this.appConfigService.get<string>('basePath', '/rest/api/gw/v1'),
            imageSubPath = this.appConfigService.get<string>('result.item.image.subPath', '/images');
        this.pathToImageThumbnails = `${apiScheme}://${apiHost}${basePath}${imageSubPath}`;

        if (this.config.placeholder) {
            this.src = this.config.placeholder;
        }
    }

    public ngOnInit() {
        this.element = <Element>this.elementRef.nativeElement;

        fromEvent(this.imageElement.nativeElement, 'error')
            .pipe(
                filter((event: Event) => {
                    return event.target === this.imageElement.nativeElement;
                }),
                take(1)
            )
            .subscribe((event: Event) => {
                event.preventDefault();
                if (this.config.placeholder) {
                    this.src = this.config.placeholder;
                } else {
                    this.src = `https://via.placeholder.com/${this.width || 3}x${this.height || 2}/000000/000000`;
                }
            });

        const subscription = this.viewportService
            .scroll$
            .subscribe(() => {
                if (this.isInViewport()) {
                    this.inViewport = true;
                    this.src = this.gwSrc;
                    subscription.unsubscribe();
                }
            });

        if (this.isInViewport()) {
            this.inViewport = true;
            this.src = this.gwSrc;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.isFileName(this.gwSrc)) {
            this.gwSrc = `${this.pathToImageThumbnails}${this.config.thumbnail}${this.gwSrc}`;
        }
    }

    /**
     * calc distance of bottom edge of image tag to bottom edge of viewport
     * by a value of 100, full image tag in viewport
     */
    protected isInViewport(): boolean {
        if (!this.element) {
            return false;
        }
        const rect = this.element.getBoundingClientRect(),
            relativeDistance = (1 - (rect.top + rect.height - this.windowRef.innerHeight) / this.windowRef.innerHeight) * 100;

        return relativeDistance > this.loadByRelativeSpaceFrom;
    }

    protected isFileName(src: string) {
        return (/^[^/]+$/gm).test(src);
    }
}
