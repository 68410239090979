import { Component, OnInit, Input, Output } from '@angular/core';
import { TranslationService } from '../../../gw-translation-lib/service/translation.service';
import { ViewType } from '../../enum/view-type.enum';
import { FormControl } from '@angular/forms';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'gw-result-scrolling-setting',
  templateUrl: './result-scrolling-setting.component.html',
  styleUrls: ['./result-scrolling-setting.component.scss']
})
export class ResultScrollingSettingComponent implements OnInit {

  @Input() protected scrollTypeSetting: string;

  @Output() protected changed: EventEmitter<'perpage' | 'infinite'> = new EventEmitter<'perpage' | 'infinite'>();

  public availableScrollTypes: string[] = [ 'infinite', 'perpage' ];

  public scrollTypeCtl: FormControl = new FormControl();

  protected _translatedLabels: Map<string, string>;

  constructor(
    protected translationService: TranslationService
  ) { }

  ngOnInit() {
    this._translatedLabels = new Map<string, string>(this.availableScrollTypes.map<[string, string]>((scrollType: string) => {
      return [scrollType, this.translationService.translate(`resultsettings.pagination.scrolltype.${scrollType.toLowerCase()}`)];
    }));
    if(this.scrollTypeSetting) {
      this.scrollTypeCtl.patchValue(this.scrollTypeSetting, {emitEvent: false});
    }
    this.scrollTypeCtl.valueChanges.subscribe(newValue => {
      this.changed.emit(newValue);
    });
  }

  public translateLabel(scrollType: string): string {
    return this._translatedLabels.get(scrollType);
  }

}
