import {Pipe, PipeTransform} from "@angular/core";
import {GwPaginatorPage} from "../../../type/gw-paginator-page";

@Pipe({
    name: 'gwPageSelection'
})
export class PageSelectionPipe implements PipeTransform {

    private _placeholder: GwPaginatorPage = {
        isFirst: false,
        selectable: false,
        selected: false,
        size: -1,
        isLast: false,
        pageNumber: -1,
        startPos: -1,
        label: "…"
    };

    public transform(pages: GwPaginatorPage[], currentPage: GwPaginatorPage, slotBudget: number): GwPaginatorPage[] {
        const currentPageIndex = pages.indexOf(currentPage);
        const currentSelectedPageIndex = pages.findIndex(p => p.selected);
        if (currentSelectedPageIndex && currentSelectedPageIndex !== currentPageIndex) {
            pages[currentSelectedPageIndex].selected = false;
            pages[currentPageIndex].selected = true;
        }

        if(pages.length < 10) {
            return pages;
        }

        return this._simpleTransform(pages, currentPageIndex);
    }

    private _simpleTransform(pages: GwPaginatorPage[], currentPageIndex: number): GwPaginatorPage[] {
        const lastPageIndex = pages.length - 1;
        const navigationBoxes: GwPaginatorPage[] = [
            pages[0],               // 0
            {...this._placeholder}, // 1 aka …
            null,                   // 2
            null,                   // 3
            null,                   // 4
            null,                   // 5
            null,                   // 6
            {...this._placeholder}, // 7 aka …
            pages[lastPageIndex]    // 8
        ];

        let pageIndex = currentPageIndex;

        if(currentPageIndex <= 4) {
            pageIndex = 4;
            navigationBoxes[1] = pages[1];
            navigationBoxes[4] = pages[4];
        } else if(currentPageIndex >= lastPageIndex - 4) {
            pageIndex = lastPageIndex - 4;
            navigationBoxes[4] = pages[lastPageIndex - 4];
            navigationBoxes[7] = pages[lastPageIndex - 1];
        }

        for (let i = 2; i < 7; i++) {
            navigationBoxes[i] = pages[pageIndex - 4 + i];
        }

        return navigationBoxes;
    }
}
