import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import first from 'lodash-es/first';
import {WINDOW} from '../../gw-configuration-lib/reference/window-ref';
import {ComparatorType} from '../../gw-search-lib/enum/comparator-type.enum';
import {ResultState} from '../../gw-search-lib/interface/result-state';
import {CarModel} from '../../gw-search-lib/model/car-model';
import {FilterFieldConfiguration} from '../../gw-search-lib/type/filter-field-configuration';
import {BehaviorSubject, fromEvent, Observable, of, Subject, Subscription} from 'rxjs';
import {share, switchMap, takeUntil} from 'rxjs/operators';
import {ActionType} from '../../gw-pipe-lib/enum/action-type.enum';
import {ViewType} from '../../gw-pipe-lib/enum/view-type.enum';
import {SortingType} from '../../gw-search-lib/enum/sorting-type.enum';
import {CarInterface} from '../../gw-search-lib/interface/car-interface';
import {FilterOptionInterface} from '../../gw-search-lib/interface/filter-option-interface';
import {EventBusService} from '../../gw-search-lib/service/event-bus.service';
import {FilterService} from '../../gw-search-lib/service/filter.service';
import {SearchService} from '../../gw-search-lib/service/search.service';
import {ToolBoxService} from '../../gw-search-lib/service/tool-box.service';
import {GwResultTemplates} from '../../interface/gw-result-templates';
import {ChangeEventContext} from "../../gw-pipe-lib/types";

@Component({
    // selector:      'gw-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss'],
    encapsulation: ViewEncapsulation.None,
    viewProviders: [ResultComponent]
})
export class ResultComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

    static readonly SCROLLING_INFINITE = 'infinite';
    static readonly SCROLLING_PER_PAGE = 'perpage';
    private _searchRequest: Subscription;
    private _hasOwnResultState: boolean = false;
    private _templates: Map<string, Element[]>;

    private _additionalFilterInput: { field: string, comparator: ComparatorType, value: any }[] = [];
    @Input('gw-filters')
    public set additionalFilterInput(additionalFilterInput: string) {
        if (typeof additionalFilterInput === 'string' && additionalFilterInput.length > 0) {
            this._additionalFilterInput = JSON.parse(additionalFilterInput || '[]');
        }
    }

    public get additionalFilterInput(): string {
        return JSON.stringify(this._additionalFilterInput);
    }

    @Input('gw-sorting-order') public sortingOrder: SortingType; // ASC || DESC

    @Input('gw-page-size')
    public set limit(limit: number) {
        if (typeof limit === 'string' && ("" + limit).length > 0) {
            this._limit = parseInt(limit);
        }
    }

    public get limit(): number {
        return this._limit;
    }

    @Input('gw-separate')
    public set hasOwnResultState(hasOwnResultState: any) {
        if (typeof hasOwnResultState === 'string' && hasOwnResultState.length > 0) {
            this._hasOwnResultState = ['true', '1', 'hasOwnResultState'].includes(hasOwnResultState);
        }
        if (typeof hasOwnResultState === 'boolean') {
            this._hasOwnResultState = hasOwnResultState;
        }
    }

    public get hasOwnResultState(): any {
        return this._hasOwnResultState;
    }

    @Input('gw-scrolling') public scrolling: string = ResultComponent.SCROLLING_PER_PAGE;

    @Input('gw-class') public classes: string;

    @Input('gw-sorting-field')
    public set sortingField(sortingField: string) { // field name
        if (typeof sortingField !== 'string' || sortingField.length < 1) {
            return;
        }
        this._sortingField = sortingField;
    }

    public get sortingField(): string {
        return this._sortingField;
    }

    @Input('gw-viewtype')
    public set viewType(viewType: ViewType) {
        if ([ViewType.BOX, ViewType.LIST].includes(viewType) === false) {
            return;
        }
        this._viewType = viewType;
        this.switchTemplate(viewType);
    }

    public get viewType(): ViewType {
        return this._viewType;
    }

    public template: GwResultTemplates = {item: null, currentViewType: null};
    public total: number = 0;
    public isScrollable: boolean = false;

    private _sortingField: string = 'price_gross';
    private _viewType = null;
    private _destroy$ = new Subject<void>();
    private _limit: number = 20;

    @HostBinding('class.gw-cmp-ready') private isReadyToShow: boolean = false;

    private _resultState: ResultState = {
        filterOptions: new Map(),
        viewType: null,
        sorting: null,
        offset: null,
        limit: null,
        createdAt: Date.now(),
    };

    private _initializedViewportFilling: boolean = false;

    constructor(
        @Inject(WINDOW) protected window: Window,
        protected searchService: SearchService,
        protected filterService: FilterService,
        protected toolboxService: ToolBoxService,
        protected eventBus: EventBusService,
        protected vcRef: ViewContainerRef,
        protected elementRef: ElementRef
    ) {
    }

    protected _observableCars: BehaviorSubject<CarInterface[]> = new BehaviorSubject<CarInterface[]>([]);

    public get cars$(): Observable<CarInterface[]> {
        return this._observableCars.pipe(
            share(),
            //tap(cars => this.overwriteScrolling(this._resultState))
        );
    };

    ngOnInit() {
        if (this._hasOwnResultState) {
            this.eventBus.on<FilterFieldConfiguration>(ActionType.FILTER_CONFIGURATION_READY, () => this.onFilterConfigurationReadyForLocalResultState());
        } else {
            // using global state
            this.eventBus.on<FilterFieldConfiguration>(ActionType.FILTER_CONFIGURATION_READY, () =>
                this.eventBus.broadcast<ChangeEventContext>('ResultComponent', ActionType.FILTERS_CHANGE, {
                    remove: [],
                    add: this.getLocalFilterOptions()
                })
            );
            this.eventBus.on<ResultState>(ActionType.RESULT_STATE_READY, resultStateEvent => {
                this.eventBus.on<ResultState>(ActionType.RESULT_STATE_CHANGED, event => this.onResultStateChanged(event.payload), false, true);
                this.eventBus.on<ResultState>(ActionType.VIEWTYPE_LIST, () => this.onViewTypeChanged(ViewType.LIST));
                this.eventBus.on<ResultState>(ActionType.VIEWTYPE_BOX, () => this.onViewTypeChanged(ViewType.BOX));

                const resultState = resultStateEvent.payload;
                this.viewType = resultState.viewType;
                if (this.limit !== resultState.limit) {
                    this.eventBus.broadcast<number>(this, ActionType.RESULT_LIMIT, this.limit);
                } else {
                    this.onResultStateChanged(resultState);
                }
            });
        }
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    ngAfterViewChecked(): void {
        if (this._initializedViewportFilling === false && this.scrolling === ResultComponent.SCROLLING_INFINITE) {
            this.overwriteScrolling(this._resultState);
        }
    }

    ngAfterViewInit(): void {
        if (this.scrolling === ResultComponent.SCROLLING_INFINITE) {
            this.isScrollable = true;
            fromEvent(this.window, 'scroll')
                .pipe(
                    takeUntil(this._destroy$),
                    switchMap(() => {
                        const boundingBox = this.elementRef.nativeElement.getBoundingClientRect();
                        return of(1 - (boundingBox.bottom - this.window.innerHeight) / boundingBox.height > 0.75);
                    })
                )
                .subscribe(() => {
                    if (!this._resultState) {
                        return;
                    }
                    const newOffset = this._resultState.offset + this._resultState.limit;
                    this.setLocalResultState({
                        filterOptions: new Map<string, FilterOptionInterface>(Array.from(this._resultState.filterOptions)),
                        sorting: {
                            field: this._resultState.sorting.field,
                            order: this._resultState.sorting.order
                        },
                        limit: this._resultState.limit,
                        offset: newOffset,
                        viewType: this._resultState.viewType,
                        createdAt: Date.now()
                    });
                });
        }
    }

    protected requestEntities(resultState: ResultState): void {
        this.resetSearchRequest();

        const searchJob = {
            filterOptions: resultState.filterOptions,
            sorting: {
                field: resultState.sorting.field.key,
                order: resultState.sorting.order
            },
            limit: resultState.limit,
            offset: resultState.offset,
            isGlobal: this._hasOwnResultState === false
        };

        this._searchRequest = this.searchService
            .search<CarInterface>(CarModel, searchJob)
            .pipe(takeUntil(this._destroy$))
            .subscribe(cars => {
                this.resetSearchRequest();
                if (this.scrolling === ResultComponent.SCROLLING_INFINITE && resultState.offset !== 0) {
                    cars = [...this._observableCars.getValue(), ...cars];
                }
                this._observableCars.next(cars);
                this.total = this.filterService.total;
                this.isReadyToShow = true;
            });

        this.emitSearchEvent(searchJob);
    }

    private overwriteScrolling(resultState: ResultState): ResultState {
        const boundingBox = (this.elementRef.nativeElement as Element).getBoundingClientRect();
        const bottomAbsPos = 1 - (boundingBox.bottom - this.window.innerHeight) / boundingBox.height;

        if (bottomAbsPos > .75) {
            const itemElement = (this.elementRef.nativeElement as Element).querySelector('li');
            const itemBox = (itemElement) ? itemElement.getBoundingClientRect() : null;

            if (itemBox) {
                this._initializedViewportFilling = true;
                const newOffset = this._resultState.offset + this._resultState.limit;
                this.setLocalResultState({
                    filterOptions: new Map<string, FilterOptionInterface>(Array.from(this._resultState.filterOptions)),
                    sorting: {
                        field: this._resultState.sorting.field,
                        order: this._resultState.sorting.order
                    },
                    limit: this._resultState.limit,
                    offset: newOffset,
                    viewType: this._resultState.viewType,
                    createdAt: Date.now()
                });
            }
        }
        return resultState;
    }

    private setLocalResultState(resultState: ResultState) {
        if (this._resultState) {
            if (resultState.createdAt === this._resultState.createdAt) {
                return;
            }
            if (this._resultState.viewType !== resultState.viewType) {
                this.viewType = resultState.viewType;
            }
        }

        this._resultState = resultState;
        this.requestEntities(resultState);
    }

    /**
     *
     */
    private getLocalFilterOptions(): FilterOptionInterface[] {
        try {
            return this._additionalFilterInput.map(localFilterOption => this.filterService.getOptionByFieldName(localFilterOption.field, localFilterOption.value, localFilterOption.comparator))
                .filter(localFilterOption => !!localFilterOption)
                .map(filterOption => {
                    filterOption.fixed = true;

                    return filterOption;
                });
        } catch (e) {
        }
    }

    private buildLocalResultState(resultState: ResultState): ResultState {
        const filterOptionsMap = [
            ...Array.from(resultState.filterOptions),
            ...this.getLocalFilterOptions().map<[string, FilterOptionInterface]>(filterOption => [filterOption.hash, filterOption])
        ];

        return {
            filterOptions: new Map<string, FilterOptionInterface>(filterOptionsMap),
            sorting: {
                field: resultState.sorting.field || this.filterService.getFilterFieldByName(this.sortingField),
                order: resultState.sorting.order || this.sortingOrder.toUpperCase() as SortingType
            },
            limit: resultState.limit || this.limit,
            offset: resultState.offset || 0,
            viewType: resultState.viewType || this.viewType,
            createdAt: Date.now()
        };
    }

    /**
     * using global filters
     * @param resultState
     */
    private onResultStateChanged(resultState: ResultState) {
        this.setLocalResultState(this.buildLocalResultState(resultState));
    }

    /**
     * using only local settings of result list
     */
    private onFilterConfigurationReadyForLocalResultState(): void {
        const localResultState: ResultState = {
            filterOptions: new Map<string, FilterOptionInterface>(this.getLocalFilterOptions().map<[string, FilterOptionInterface]>(option => [option.hash, option])),
            sorting: {
                field: this.filterService.getFilterFieldByName(this.sortingField),
                order: (this.sortingOrder || SortingType.ASC).toUpperCase() as SortingType
            },
            limit: this.limit,
            offset: 0,
            viewType: this.viewType,
            createdAt: Date.now()
        };
        this.setLocalResultState(localResultState);
    }

    private onViewTypeChanged(viewType: ViewType): void {
        this._resultState.viewType = viewType;
        this.viewType = viewType;
        this.requestEntities(this._resultState);
    }

    private emitSearchEvent(searchJob: {
        filterOptions: Map<string, FilterOptionInterface>,
        sorting: { field: string, order: SortingType },
        offset: number,
        limit: number
    }): void {
        const serializedSearchJob = this.searchService.buildParamsForBodyRequest(
            searchJob.filterOptions,
            searchJob.sorting,
            searchJob.offset,
            searchJob.limit
        );

        const evt = new CustomEvent('gw:search', {
            bubbles: true,
            cancelable: false,
            detail: {
                searchJob: serializedSearchJob
            }
        });

        this.elementRef.nativeElement.gwSearchJob = serializedSearchJob;
        this.elementRef.nativeElement.dispatchEvent(evt);
    }

    private resetSearchRequest() {
        if (this._searchRequest && !this._searchRequest.closed) {
            this._searchRequest.unsubscribe();
            this._searchRequest = null;
        }
    }

    private switchTemplate(viewType: ViewType): void {
        if (this.template?.currentViewType === viewType) {
            return;
        }

        if (!this._templates) {
            this._templates = this.toolboxService.queryAllTemplates(
                first<Element[]>(this.vcRef["_hostTNode"].projection)
            );
        }

        if (!this.template || !this.template.currentViewType) {
            this.template = {
                currentViewType: viewType,
                headline: this._templates.has('headline') ? this._templates.get('headline') : null,
                footer: this._templates.has('footer') ? this._templates.get('footer') : null,
                item: this._templates.has(this.viewType) ? this._templates.get(viewType) : []
            };
            return;
        }

        if (this._templates.has(viewType)) {
            this.template.currentViewType = viewType;
            this.template.item = this._templates.get(viewType);
        }
    }

    public getCar(car: CarInterface): CarInterface {
        return car;
    }
}
