import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslationService} from 'projects/gw-web-components/src/app/gw-translation-lib/service/translation.service';
import {SearchService} from '../../../gw-search-lib/service/search.service';
import {GwPaginatorPage} from '../../../type/gw-paginator-page';
import {FilterService} from '../../../gw-search-lib/service/filter.service';
import {BehaviorSubject, merge, Subject} from 'rxjs';
import {EventBusService} from "../../../gw-search-lib/service/event-bus.service";
import {ResultState} from "../../../gw-search-lib/interface/result-state";
import {ActionType} from "../../enum/action-type.enum";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";

@Component({
    selector: 'gw-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnDestroy {

    private _initialized = false;

    private _destroy$ = new Subject<void>();

    private _slotBudget = 9;

    public currentPage: GwPaginatorPage;

    @Input('gw-budget')
    public set slotBudget(budget: number|string) {
        if (typeof budget === "string") {
            budget = parseInt(budget);
        }

        this._slotBudget = budget;
    }
    public get slotBudget(): number {
        return this._slotBudget;
    }

    public pages$ = new BehaviorSubject<GwPaginatorPage[]>([]);

    public labels: {
        previous: string,
        next: string,
        spacer: string
    } = {
        previous: this.translationService.translate('pagination.navigation.previous'),
        next: this.translationService.translate('pagination.navigation.next'),
        spacer: this.translationService.translate('pagination.navigation.spacer')
    };

    constructor(
        protected eventBus: EventBusService,
        protected filterService: FilterService,
        protected searchService: SearchService,
        protected translationService: TranslationService
    ) {
    }

    ngOnInit() {
        merge(
            this.searchService.size$.pipe(distinctUntilChanged()),
            this.filterService.totalChanged$.pipe(distinctUntilChanged()),
            this.filterService.filterConfiguration$.pipe(distinctUntilChanged())
        ).pipe(takeUntil(this._destroy$)).subscribe(() => this._onSizeChanged(this.searchService.size$.getValue()));

        // init
        this.eventBus.on<ResultState>(ActionType.RESULT_STATE_READY, resultState => {
            if (!this._initialized) {
                this._initialized = true;
                this._initByResultState(resultState.payload);
            }
        });

        // change
        this.eventBus.on<ResultState>(
            ActionType.RESULT_STATE_CHANGED,
            resultStateEvent => this.onPageChanged(resultStateEvent.payload.offset)
        );
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public previous() {
        if (this.currentPage && !this.currentPage.isFirst) {
            this.searchService.startPos = this.pages$.getValue()[this.currentPage.pageNumber - 1].startPos;
        }
    }

    public current(page: GwPaginatorPage) {
        if (!page.selectable) {
            return;
        }
        this.searchService.startPos = page.startPos;
    }

    public next() {
        if (this.currentPage && !this.currentPage.isLast) {
            this.searchService.startPos = this.pages$.getValue()[this.currentPage.pageNumber + 1].startPos;
        }
    }

    protected onPageChanged(newStartPos: number) {
        const currentPageByStartPos = this.pages$.getValue().find(page => newStartPos >= page.startPos && newStartPos < page.startPos + page.size);

        if (currentPageByStartPos && this.currentPage !== currentPageByStartPos) {
            this.currentPage.selected = false;
            currentPageByStartPos.selected = true;
            this.currentPage = currentPageByStartPos;
        }
    }

    protected _onSizeChanged(newSize: number): any {
        if (newSize < 1) {
            return;
        }

        const pages = Math.ceil(this.filterService.total / newSize) || 1;
        const startPos = (this.filterService.total < this.searchService.startPos
            ? this.filterService.total
            : this.searchService.startPos
        ) || 0;

        const pageObjects = Array.from(Array(pages).keys()).map<GwPaginatorPage>(pageNumber => {
            const minPos = pageNumber * newSize;
            const maxPos = minPos + newSize;
            const paginatorPage = {
                isFirst: pageNumber === 0,
                isLast: pageNumber === pages - 1,
                selected: startPos >= minPos && startPos < maxPos,
                selectable: true,
                size: newSize,
                startPos: pageNumber * newSize,
                pageNumber: pageNumber,
                label: '' + (pageNumber + 1)
            };

            if (paginatorPage.selected) {
                this.currentPage = paginatorPage;
            }
            return paginatorPage;
        });

        this.pages$.next(pageObjects);
    }

    private _initByResultState(resultState: ResultState) {
        this._onSizeChanged(resultState.limit || this.searchService.size$.getValue() || 21);
    }
}
